// @flow

import Dialog from '@mui/material/Dialog';
import { grey } from '@mui/material/colors';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import type { TbAssetTelemetriesItem, TelemetryAttribute } from 'types/Thingsboard.types';
import { v4 as uuid } from 'uuid';
import { addAlert } from 'store/slice/ApplicationSlice';
import { useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import { isDefined, isNotEmpty } from 'util/ObjectUtils';
import { MANAGER_TENANT_ATTRIBUTE_ENUM } from 'constants/internal/TenantConstants';
import { updateTelemetryAttribute } from 'api/service/internal/ThingsboardManagerTelemetryService';

type Props = {
  open: boolean,
  handleClose: Function,
  tenantInfo: TbAssetTelemetriesItem,
  fetchTenantsWithTelemetries: Function
};

const CommentDialog = ({ open, handleClose, tenantInfo, fetchTenantsWithTelemetries }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [text, setText] = useState(null);

  useEffect(() => {
    if (isDefined(tenantInfo)) {
      const attributes = tenantInfo?.attributes;

      let tenantComment = attributes.find(
        (attribute: TelemetryAttribute) => attribute.key === MANAGER_TENANT_ATTRIBUTE_ENUM.COMMENT
      )?.value;
      if (isNotEmpty(tenantComment)) {
        setText(tenantComment);
      }
    }
  }, [tenantInfo]);

  const handleTextChange = (event: Event) => {
    setText(event.target.value);
  };

  const handleCloseDialog = () => {
    setText(null);
    handleClose();
  };

  const handleSave = () => {
    let body = JSON.stringify({
      [MANAGER_TENANT_ATTRIBUTE_ENUM.COMMENT]: text ?? ''
    });

    const newUpdateObject = {
      entityId: tenantInfo?.assetDto?.id?.id,
      entityType: tenantInfo?.assetDto?.id?.entityType,
      body: body
    };
    updateTelemetryAttribute(dispatch, newUpdateObject)
      .then(() => {
        let alert = {
          id: uuid(),
          severity: 'success',
          title: intl.formatMessage({ id: 'app.common.success' }),
          message: intl.formatMessage({ id: 'app.datagrid.subscription.comment.update.success' })
        };
        dispatch(addAlert(alert));
      })
      .then(fetchTenantsWithTelemetries)
      .then(handleCloseDialog);
  };

  let titleLabel = intl.formatMessage({ id: 'app.datagrid.subscription.comment.title' });

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        sx: {
          width: '500px',
          p: 1
        }
      }}
    >
      <DialogTitle variant="h6" sx={{ fontWeight: 400, borderBottom: `1px solid ${grey[300]}`, p: 1 }}>
        {titleLabel}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', pt: 2 }}>
          <TextField
            id="comment"
            variant="standard"
            size="small"
            fullWidth
            multiline
            rows={4}
            inputProps={{ maxLength: 255 }}
            label={intl.formatMessage({ id: 'app.datagrid.subscription.comment' })}
            type={'text'}
            value={text ?? ''}
            onChange={handleTextChange}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ pr: 1, pb: 0.5, pt: 1, borderTop: `1px solid ${grey[300]}` }}>
        <Button variant="contained" size="small" onClick={handleSave}>
          {intl.formatMessage({ id: 'app.common.save' })}
        </Button>
        <Button
          onClick={handleCloseDialog}
          variant="contained"
          size="small"
          sx={{
            backgroundColor: grey[500],
            color: '#fff',
            ':hover': {
              backgroundColor: grey[700]
            }
          }}
        >
          {intl.formatMessage({ id: 'app.common.close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommentDialog;

// @flow
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { EXTERNAL_GENERAL_OVERVIEW, INTERNAL_GENERAL_OVERVIEW, LOGIN } from 'routing/RouteConstants';
import { isInternalUser, isUserAuthenticated } from 'store/selector/UserSelector';

const PageNotFound = (): React$Node => {
  const authenticated = useSelector(isUserAuthenticated);
  const isInternal = isInternalUser;

  let navigateTo = LOGIN;
  if (authenticated) {
    navigateTo = isInternal ? INTERNAL_GENERAL_OVERVIEW : EXTERNAL_GENERAL_OVERVIEW;
  }

  console.debug('Route not found! Redirecting to fallback');

  return <Navigate to={navigateTo} replace />;
};

export default PageNotFound;

// @flow

import { GROUP_TREE_ITEM_ENUM } from 'constants/GroupConstants';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { HolidayVillageOutlined } from '@mui/icons-material';
import PhonelinkRingOutlinedIcon from '@mui/icons-material/PhonelinkRingOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';

export const TreeItemIconMapper = {
  [GROUP_TREE_ITEM_ENUM.GROUP]: CategoryOutlinedIcon,
  [GROUP_TREE_ITEM_ENUM.TENANT]: HolidayVillageOutlined,
  [GROUP_TREE_ITEM_ENUM.DEVICE]: PhonelinkRingOutlinedIcon,
  [GROUP_TREE_ITEM_ENUM.USER]: PersonOutlineOutlinedIcon,
  [GROUP_TREE_ITEM_ENUM.SITE]: HouseOutlinedIcon
};

// @flow

import PageTitle from 'components/PageTitle';
import PageContainer from 'pages/common/PageContainer';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import ManagerThingsboard from 'components/internal/serviceOverview/ManagerThingsboard';
import ManagerUI from 'components/internal/serviceOverview/ManagerUI';

const ServiceStatusManager = (): React$Node => {
  const intl = useIntl();
  const [tabIndex, setTabindex] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabindex(newValue);
  };

  return (
    <PageContainer>
      <PageTitle titleKey={'app.page.service.manager.status'} />
      <Box component="main" sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label={intl.formatMessage({ id: 'app.service.overview.thingsboard' })} />
          <Tab label={intl.formatMessage({ id: 'app.service.overview.ui' })} />
        </Tabs>
        {tabIndex === 0 && <ManagerThingsboard />}
        {tabIndex === 1 && <ManagerUI />}
      </Box>
    </PageContainer>
  );
};

export default ServiceStatusManager;

// @flow
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import TopNavigationMenu from 'components/navigation/TopNavigationMenu';
import ThemeMode from 'components/ThemeMode';
import { CardMedia, Collapse, useMediaQuery, useTheme } from '@mui/material';
import { isFunctionDefined, isNotEmptyList } from 'util/ObjectUtils';
import AppLogoIcon from 'assets/app_logo.svg';

const drawerWidth = 240;
export const topBarHeight = 8; // MUI unit

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  border: 'none'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  },
  border: 'none'
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  height: theme.spacing(topBarHeight),
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

type Props = {
  children: React$Node,
  SideMenuItems: Array
};

export default function NavigationBar({ children, SideMenuItems }: Props): React$Node {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ display: 'flex', width: '100vw', height: '100vh' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ bgcolor: 'white' }} elevation={0}>
        <Toolbar>
          <CardMedia
            component="img"
            image={AppLogoIcon}
            alt="Agrolog Logo"
            sx={{ pt: 1, pb: 1, ml: '22px', width: '148px', height: 'auto' }}
          />
          <TopNavigationMenu />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={!isSmallDevice}>
        <List sx={{ mt: 7 }}>
          {SideMenuItems.map((menuItem) => (
            <React.Fragment key={`menu-item-fragment-${menuItem.path}`}>
              <ListItem key={`side-menu-${menuItem.path}`} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5
                  }}
                  onClick={() => (isFunctionDefined(menuItem.onClick) ? menuItem.onClick() : navigate(menuItem.path))}
                  selected={location.pathname.includes(menuItem.path)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center'
                    }}
                  >
                    {menuItem.icon}
                  </ListItemIcon>
                  <ListItemText primary={intl.formatMessage({ id: menuItem.textKey })} sx={{ opacity: open ? 1 : 0 }} />
                  {isNotEmptyList(menuItem.submenu) &&
                    open &&
                    (menuItem.isSubmenuOpen ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
              </ListItem>
              {isNotEmptyList(menuItem.submenu) && (
                <Collapse
                  key={`side-submenu-collapse-${menuItem.path}`}
                  in={menuItem.isSubmenuOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List key={`submenu-list-${menuItem.path}`} component="div" disablePadding>
                    {menuItem.submenu.map((submenuItem) => (
                      <ListItemButton
                        key={`side-submenu-item-${submenuItem.path}`}
                        sx={{ pl: 4 }}
                        selected={location.pathname.includes(submenuItem.path)}
                        onClick={() =>
                          isFunctionDefined(submenuItem.onClick) ? submenuItem.onClick : navigate(submenuItem.path)
                        }
                      >
                        <ListItemIcon>{submenuItem.icon}</ListItemIcon>
                        <ListItemText primary={intl.formatMessage({ id: submenuItem.textKey })} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
        <Divider />
        <ThemeMode sx={{ m: 0.5 }} />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 2, width: `calc(100vw - ${drawerWidth}px)`, height: '100%' }}>
        {children}
      </Box>
    </Box>
  );
}

// @flow

import { useSelector } from 'react-redux';
import { isAdministrator } from 'store/selector/UserSelector';
import React from 'react';
import NavigationBar from 'components/navigation/NavigationBar';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import {
  INTERNAL_ADMINISTRATION,
  INTERNAL_ADMINISTRATION_SERVICES,
  INTERNAL_ADMINISTRATION_USERS,
  INTERNAL_ANALYTICS,
  INTERNAL_FARMER_APP,
  INTERNAL_FARMER_APP_CUSTOMERS,
  INTERNAL_FARMER_APP_DEVICES,
  INTERNAL_GENERAL,
  INTERNAL_GENERAL_NODES_MAP_MANAGER,
  INTERNAL_GENERAL_OVERVIEW,
  INTERNAL_MANAGE_GROUPS,
  INTERNAL_MANAGER,
  INTERNAL_MANAGER_GATEWAYS,
  INTERNAL_MANAGER_SUBSCRIPTIONS,
  INTERNAL_MANAGER_TENANTS,
  INTERNAL_SERVICE_OVERVIEW,
  INTERNAL_SERVICE_STATUS_APP,
  INTERNAL_SERVICE_STATUS_MANAGER
} from 'routing/RouteConstants';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  AdminPanelSettingsOutlined,
  EngineeringOutlined,
  GroupsOutlined,
  HolidayVillageOutlined,
  PeopleOutline,
  PhonelinkRingOutlined,
  VillaOutlined
} from '@mui/icons-material';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import PhonelinkRingOutlinedIcon from '@mui/icons-material/PhonelinkRingOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';

const createInternalSideMenuItems = (
  administrator: boolean,
  adminOpen: boolean,
  generalOpen: boolean,
  serviceOverviewOpen: boolean,
  managerOpen: boolean,
  farmerAppOpen: boolean,
  toggleAdminOpen: Function,
  toggleGeneralOpen: Function,
  toggleManagerOpen: Function,
  toggleFarmerAppOpen: Function,
  toggleServiceOverviewOpen: Function
) => {
  const SideMenuItems = [
    {
      textKey: 'app.navigation.general',
      icon: <TravelExploreOutlinedIcon />,
      path: INTERNAL_GENERAL,
      onClick: toggleGeneralOpen,
      isSubmenuOpen: generalOpen,
      submenu: [
        {
          textKey: 'app.navigation.general.worldMap',
          icon: <MapOutlinedIcon />,
          path: INTERNAL_GENERAL_OVERVIEW
        },
        {
          textKey: 'app.navigation.general.nodesMap',
          icon: <HubOutlinedIcon />,
          path: INTERNAL_GENERAL_NODES_MAP_MANAGER
        }
      ]
    },
    {
      textKey: 'app.navigation.service.overview',
      icon: <InfoOutlinedIcon />,
      path: INTERNAL_SERVICE_OVERVIEW,
      onClick: toggleServiceOverviewOpen,
      isSubmenuOpen: serviceOverviewOpen,
      submenu: [
        {
          textKey: 'app.navigation.service.overview.statusManager',
          icon: <VillaOutlined />,
          path: INTERNAL_SERVICE_STATUS_MANAGER
        },
        {
          textKey: 'app.navigation.service.overview.statusApp',
          icon: <EngineeringOutlined />,
          path: INTERNAL_SERVICE_STATUS_APP
        }
      ]
    },
    {
      textKey: 'app.navigation.manager',
      icon: <VillaOutlined />,
      path: INTERNAL_MANAGER,
      onClick: toggleManagerOpen,
      isSubmenuOpen: managerOpen,
      submenu: [
        {
          textKey: 'app.navigation.manager.tenants',
          icon: <HolidayVillageOutlined />,
          path: INTERNAL_MANAGER_TENANTS
        },
        {
          textKey: 'app.navigation.manager.subscriptions',
          icon: <CardMembershipOutlinedIcon />,
          path: INTERNAL_MANAGER_SUBSCRIPTIONS
        },
        {
          textKey: 'app.navigation.manager.gateways',
          icon: <PhonelinkRingOutlinedIcon />,
          path: INTERNAL_MANAGER_GATEWAYS
        }
      ]
    },
    {
      textKey: 'app.navigation.farmerApp',
      icon: <EngineeringOutlined />,
      path: INTERNAL_FARMER_APP,
      onClick: toggleFarmerAppOpen,
      isSubmenuOpen: farmerAppOpen,
      submenu: [
        {
          textKey: 'app.navigation.farmerApp.customers',
          icon: <GroupsOutlined />,
          path: INTERNAL_FARMER_APP_CUSTOMERS
        },
        {
          textKey: 'app.navigation.farmerApp.devices',
          icon: <PhonelinkRingOutlined />,
          path: INTERNAL_FARMER_APP_DEVICES
        }
      ]
    },
    {
      textKey: 'app.navigation.analytics',
      icon: <QueryStatsOutlinedIcon />,
      path: INTERNAL_ANALYTICS
    },
    {
      textKey: 'app.navigation.groups',
      icon: <CategoryOutlinedIcon />,
      path: INTERNAL_MANAGE_GROUPS
    }
  ];

  if (administrator) {
    const adminNavigationItem = {
      textKey: 'app.navigation.administration',
      icon: <AdminPanelSettingsOutlined />,
      path: INTERNAL_ADMINISTRATION,
      onClick: toggleAdminOpen,
      isSubmenuOpen: adminOpen,
      submenu: [
        {
          textKey: 'app.navigation.administration.users',
          icon: <PeopleOutline />,
          path: INTERNAL_ADMINISTRATION_USERS
        },
        {
          textKey: 'app.navigation.administration.services',
          icon: <SettingsSuggestOutlinedIcon />,
          path: INTERNAL_ADMINISTRATION_SERVICES
        }
      ]
    };

    SideMenuItems.push(adminNavigationItem);
  }

  return SideMenuItems;
};

const InternalNavigationBar = ({ children }: any) => {
  const administrator = useSelector(isAdministrator);
  const [adminOpen, setAdminOpen] = React.useState(false);
  const [generalOpen, setGeneralOpen] = React.useState(false);
  const [managerOpen, setManagerOpen] = React.useState(false);
  const [farmerAppOpen, setFarmerAppOpen] = React.useState(false);
  const [serviceOverviewOpen, setServiceOverviewOpen] = React.useState(false);

  const toggleAdminOpen = () => {
    setAdminOpen(!adminOpen);
  };
  const toggleGeneralOpen = () => {
    setGeneralOpen(!generalOpen);
  };
  const toggleManagerOpen = () => {
    setManagerOpen(!managerOpen);
  };
  const toggleFarmerAppOpen = () => {
    setFarmerAppOpen(!farmerAppOpen);
  };
  const toggleServiceOverviewOpen = () => {
    setServiceOverviewOpen(!serviceOverviewOpen);
  };

  let SideMenuItems = createInternalSideMenuItems(
    administrator,
    adminOpen,
    generalOpen,
    serviceOverviewOpen,
    managerOpen,
    farmerAppOpen,
    toggleAdminOpen,
    toggleGeneralOpen,
    toggleManagerOpen,
    toggleFarmerAppOpen,
    toggleServiceOverviewOpen
  );

  return <NavigationBar SideMenuItems={SideMenuItems}>{children}</NavigationBar>;
};

export default InternalNavigationBar;

// @flow

import { useState } from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import TextField from '@mui/material/TextField';
import { getFieldErrorMessage, hasFieldError, resolveBackendValidationErrors } from 'util/ValidationUtils';
import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { createGroup } from 'api/service/internal/AdminGroupApiService';
import { addAlert } from 'store/slice/ApplicationSlice';
import { v4 as uuid } from 'uuid';
import Typography from '@mui/material/Typography';
import type { Group } from 'types/Groups.types';
import { isDefined } from 'util/ObjectUtils';
import { validateGroupForm } from 'validators/Group.validator';
import { ValidationError } from 'yup';
import { isAdministrator } from 'store/selector/UserSelector';
import { createGroupForExternal } from 'api/service/external/GroupApiService';

type Props = {
  open: boolean,
  handleClose: Function,
  group: Group,
  parent?: Group,
  onSuccessCallback: Function
};

const GroupDialog = ({ open, handleClose, group, parent, onSuccessCallback }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [fieldErrors, setFieldErrors] = useState([]);
  const [groupData, setGroupData] = useState(group ?? { parentId: parent?.id });
  const isAdmin = useSelector(isAdministrator);

  const handleNameChange = (event: Event) => {
    setGroupData((oldData) => ({ ...oldData, name: event.target.value }));
  };

  const handleDialogClose = () => {
    setFieldErrors([]);
    setGroupData({});

    handleClose();
  };

  const handleSubmit = () => {
    const createApiCall = isAdmin ? createGroup : createGroupForExternal;
    validateGroupForm(groupData)
      .then(() => {
        setFieldErrors([]);
        createApiCall(groupData, dispatch)
          .then(() => {
            const alert = {
              id: uuid(),
              severity: 'success',
              message: intl.formatMessage({ id: 'app.user.profile.save.success' })
            };
            dispatch(addAlert(alert));
          })
          .then(handleDialogClose)
          .then(onSuccessCallback)
          .catch((error: any) => {
            setFieldErrors(resolveBackendValidationErrors(error));
          });
      })
      .catch((validationResult: ValidationError) => {
        setFieldErrors(validationResult?.inner ?? []);
      });
  };

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <DialogTitle>
        {isDefined(group)
          ? intl.formatMessage({ id: 'app.manageGroups.editGroup' })
          : intl.formatMessage({ id: 'app.manageGroups.addGroup' })}
      </DialogTitle>
      <DialogContent>
        {isDefined(parent) && (
          <Typography variant="body2">
            {intl.formatMessage({ id: 'app.manageGroups.parent.label' }, { parent: parent?.name })}
          </Typography>
        )}
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={intl.formatMessage({ id: 'app.common.name' })}
          type="text"
          fullWidth
          variant="standard"
          defaultValue={groupData?.name}
          onChange={handleNameChange}
          error={hasFieldError('name', fieldErrors)}
          helperText={getFieldErrorMessage(intl, 'name', fieldErrors)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} variant="outlined">
          {intl.formatMessage({ id: 'app.common.cancel' })}
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          {intl.formatMessage({ id: 'app.common.save' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupDialog;

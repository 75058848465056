// @flow
import { Dispatch } from '@reduxjs/toolkit';

import { handleError } from 'api/RestApiHelper';
import { GROUPS, GROUPS_USERS } from 'constants/RestApiConstants';
import { deleteRequest, get, post } from 'api/RestApi';
import { startLoading, stopLoading } from 'store/slice/ApplicationSlice';
import type { CreateGroup, ManageGroupAccess } from 'types/Groups.types';

export async function createGroupForExternal(group: CreateGroup, dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return post(GROUPS, group)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function getAllGroupsForExternal(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(GROUPS)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function deleteGroupForExternal(groupId: number, dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return deleteRequest(`${GROUPS}/${groupId}`)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function manageGroupAccessForExternal(groupAccess: ManageGroupAccess, dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return post(GROUPS_USERS, groupAccess)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

const withBasePath = (path) => `/api/${path}`;
const withInternalBasePath = (path) => `/api/internal/${path}`;
const withExternalBasePath = (path) => `/api/external/${path}`;

// Authentication endpoint
export const LOGIN_API = withBasePath('authenticate');

// Public endpoints without authentication
export const VALIDATE_CREDENTIALS = withBasePath('public/validate-credentials');
export const REQUEST_PASSWORD_RESET_API = withBasePath('public/forgot-password');
export const PASSWORD_RESET_API = withBasePath('public/reset-password');
export const VERIFY_TOKEN_API = withBasePath('public/verify-token');
export const REGISTER_USER = withBasePath('public/register-user');
export const ACTIVATE_USER = withBasePath('public/activate-user');
export const INVITE_USERS = withBasePath('public/invite-users');
export const VALIDATE_EMAIL = withBasePath('public/validate-email');
export const APPLICATION_SETTINGS = withBasePath('public/application-settings');
export const TB_LOCATION_DATA = withBasePath('public/tb/location-data');
export const TB_LOCATION_SITES_DATA = withBasePath('public/tb/location-data/sites');

// User endpoints
export const USER_LOGOUT_API = withBasePath('user/logout');
export const USER_DETAILS_API = withBasePath('user/details');
export const USER_2FA_TOTP_URL_API = withBasePath('user/2fa-totp-url');

// Admin endpoints
export const ADMIN_USERS = withInternalBasePath('admin/users');
export const ADMIN_USERS_ACTIVATION = withInternalBasePath('admin/users/activation');
export const ADMIN_REFRESH_LONG_TERM_CACHE = withInternalBasePath('admin/refresh-long-term-cache');
export const ADMIN_REFRESH_SHORT_TERM_CACHE = withInternalBasePath('admin/refresh-short-term-cache');

// Admin Group endpoints
export const ADMIN_GROUPS = withInternalBasePath('admin/groups');
export const ADMIN_GROUPS_USERS = withInternalBasePath('admin/groups/users');
export const ADMIN_GROUPS_TENANTS = withInternalBasePath('admin/groups/tenants');
export const ADMIN_GROUPS_DEVICES = withInternalBasePath('admin/groups/devices');

// INTERNAL
//Application endpoints
export const AGROLOG_ANALYTICS_BASIC_AUTHORIZATION_CODE = withInternalBasePath('agrolog-analytics/authorization-code');
export const REFRESH_CACHE = withInternalBasePath('agrolog/refresh-cache');

// Thingsboard related endpoints
// Manager API's
export const TB_MANAGER_RPC = withInternalBasePath('tb/manager/rpc');
export const TB_MANAGER_SITES_DATA = withInternalBasePath('tb/manager/sites');
export const TB_MANAGER_TENANTS_ASSET_INFO = withInternalBasePath('tb/manager/tenants/asset-info');
export const TB_MANAGER_DEVICES_DATA = withInternalBasePath('tb/manager/tenants/devices');
export const TB_MANAGER_ASSETS_DATA = withInternalBasePath('tb/manager/tenants/assets');
export const TB_MANAGER_ASSETS_WITH_RELATIONS_DATA = withInternalBasePath('tb/manager/tenants/assets/relations');
export const TB_MANAGER_ASSETS_DEVICES_WITH_TELEMETRY = withInternalBasePath(
  'tb/manager/telemetries/assets-and-devices'
);
export const TB_MANAGER_DEVICES_WITH_TELEMETRY = withInternalBasePath('tb/manager/telemetries/devices');
export const TB_MANAGER_TENANTS_WITH_TELEMETRY = withInternalBasePath('tb/manager/telemetries/tenants');
export const TB_MANAGER_TENANTS_COUNTER = withInternalBasePath('tb/manager/tenants/counter');
export const TB_MANAGER_TENANTS_MASTER = withInternalBasePath('tb/manager/tenants/master');

// Farmer API's
export const TB_FARMER_CUSTOMERS = withInternalBasePath('tb/farmer/customers');
export const TB_FARMER_CUSTOMERS_RELATIONS_DATA = withInternalBasePath('tb/farmer/customers/relations');
export const TB_FARMER_CUSTOMERS_DATA = withInternalBasePath('tb/farmer/customers/data');
export const TB_FARMER_CUSTOMER_DEVICE_UNASSIGN = withInternalBasePath('tb/farmer/customer/device/unassign');
export const TB_FARMER_CUSTOMER_DEVICE_ASSIGN = withInternalBasePath('tb/farmer/customer/device/assign');
export const TB_FARMER_DEVICES_DATA = withInternalBasePath('tb/farmer/devices');
export const TB_FARMER_DEVICES_DATA_BY_TYPE = withInternalBasePath('tb/farmer/devices/type');
export const TB_FARMER_ASSETS_DATA = withInternalBasePath('tb/farmer/assets');
export const TB_FARMER_ASSETS_FILTERED = withInternalBasePath('tb/farmer/assets-filtered');
export const TB_FARMER_SPEARS_TELEMETRY_DATA = withInternalBasePath('tb/farmer/spears/telemetry');
export const TB_FARMER_DEVICES_TELEMETRY_DATA = withInternalBasePath('tb/farmer/devices/telemetry');
export const TB_FARMER_ALARMS = withInternalBasePath('tb/farmer/alarms-filtered');
export const TB_FARMER_RELATIONS_DELETE_CUSTOMER_TO_DEVICE = withInternalBasePath('tb/farmer/relation/customer-device');
export const TB_FARMER_USER_ACCESS_TOKEN = withInternalBasePath('tb/farmer/access-token');

// Farmer Telemetry API's
export const TB_FARMER_TELEMETRY_ATTRIBUTE_UPDATE = withInternalBasePath('tb/farmer/telemetry/attribute');

// Manager Telemetry API's
export const TB_MANAGER_TELEMETRY_ENTITY = withInternalBasePath('tb/manager/telemetry');
export const TB_MANAGER_TELEMETRY_ATTRIBUTE_UPDATE = withInternalBasePath('tb/manager/telemetry/attribute');

//Health API's
export const MANAGER_TELEMETRY_STATUS_CHECK = withInternalBasePath('manager/health/telemetries');
export const FARMER_TELEMETRY_STATUS_CHECK = withInternalBasePath('farmer/health/telemetries');

//Odoo API's
export const ODOO_TENANTS = withInternalBasePath('odoo/tenants');
export const ODOO_SALES_ORDERS = withInternalBasePath('odoo/sales-orders');
export const ODOO_NOTE = withInternalBasePath('odoo/note');

//ChargeBee API's
export const CHARGEBEE_CUSTOMERS = withInternalBasePath('chargebee/customers');
export const CHARGEBEE_SUBSCRIPTIONS = withInternalBasePath('chargebee/subscriptions');
export const CHARGEBEE_INVOICES = withInternalBasePath('chargebee/invoices');
export const CHARGEBEE_UNBILLED_CHARGES = withInternalBasePath('chargebee/unbilled-charges');
export const CHARGEBEE_SUBSCRIPTION = withInternalBasePath('chargebee/subscription');

// EXTERNAL
export const GROUPS = withExternalBasePath('groups');
export const GROUPS_USERS = withExternalBasePath('groups/users');

// @flow

import { useEffect, useState } from 'react';
import { Autocomplete, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import type { Group } from 'types/Groups.types';
import { isDefined, isNotEmptyList } from 'util/ObjectUtils';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { getTbManagerMasterTenantsData } from 'api/service/internal/ThingsboardApiService';
import type { TbEntityType, TbTenantMaster } from 'types/Thingsboard.types';
import { manageGroupTenants } from 'api/service/internal/AdminGroupApiService';
import { v4 as uuid } from 'uuid';
import { addAlert } from 'store/slice/ApplicationSlice';

type Props = {
  open: boolean,
  handleClose: Function,
  group: Group,
  parent?: Group,
  onSuccessCallback: Function
};

const GroupTenantsDialog = ({ open, handleClose, group, parent, onSuccessCallback }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [tenants, setTenants] = useState([]);
  const [assignedTenants, setAssignedTenants] = useState(group?.tenants ?? []);

  useEffect(() => {
    if (isDefined(parent)) {
      let groupTenants = parent.tenants ?? [];
      setTenants(groupTenants);
    } else {
      getTbManagerMasterTenantsData(dispatch).then((response) => {
        setTenants(response);
      });
    }
  }, []);

  const handleTenantAssign = (value: TbTenantMaster) => {
    setAssignedTenants((oldData) => [...oldData, value]);
  };

  const handleTenantDelete = (id: TbEntityType) => {
    let updatedTenants = assignedTenants?.filter((tenant) => tenant.id.id !== id?.id);
    setAssignedTenants(updatedTenants);
  };

  const handleDialogClose = () => {
    setTenants([]);
    setAssignedTenants([]);

    handleClose();
  };

  const handleSubmit = () => {
    const updatedGroupTenants = {
      groupId: group?.id,
      tenantIds: assignedTenants?.map((tenant) => tenant.id.id)
    };

    manageGroupTenants(updatedGroupTenants, dispatch)
      .then(() => {
        const alert = {
          id: uuid(),
          severity: 'success',
          message: intl.formatMessage({ id: 'app.user.profile.save.success' })
        };
        dispatch(addAlert(alert));
      })
      .then(handleDialogClose)
      .then(onSuccessCallback);
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperProps={{
        sx: {
          width: '100%'
        }
      }}
    >
      <DialogTitle>
        {intl.formatMessage({ id: 'app.manageGroups.linkTenants.title' }, { name: group?.name })}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Autocomplete
            id="tenants"
            options={tenants}
            getOptionLabel={(option) => `${option?.name ?? ''}`}
            getOptionDisabled={(option) => assignedTenants?.some((item) => item.id.id === option.id.id)}
            onChange={(event, value) => handleTenantAssign(value)}
            disableClearable
            sx={{ width: 300, mt: 2 }}
            size="small"
            renderOption={(props, option, index) => {
              const key = `tenant-${index}-${option.id.id}`;
              return (
                <li {...props} key={key}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body1">{option?.name}</Typography>
                    <Typography variant="caption">{option?.description}</Typography>
                  </Box>
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label={intl.formatMessage({ id: 'app.manageGroups.linkTenants.addTenant' })} />
            )}
          />
        </Box>
        {isNotEmptyList(assignedTenants) && (
          <Box sx={{ ml: 1, mt: 2 }}>
            {assignedTenants?.map((tenant: TbTenantMaster) => {
              return (
                <Box
                  key={`user-${tenant.name}`}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                    <Box sx={{ marginRight: 'auto', display: 'flex', flexDirection: 'column' }}>
                      <Typography>{`${tenant.name}`}</Typography>
                      <Typography variant="caption">{`${tenant.description}`}</Typography>
                    </Box>
                    <IconButton onClick={() => handleTenantDelete(tenant.id)}>
                      <DeleteOutlineOutlinedIcon sx={{ color: red[500] }} />
                    </IconButton>
                  </Box>
                  <Divider sx={{ width: '90%' }} />
                </Box>
              );
            })}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} variant="outlined">
          {intl.formatMessage({ id: 'app.common.cancel' })}
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          {intl.formatMessage({ id: 'app.common.save' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupTenantsDialog;

// @flow

import { useEffect, useState } from 'react';
import { Autocomplete, DialogActions, DialogContent, DialogTitle, Divider, Select } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { fetchAllUsers } from 'api/service/internal/AdminApiService';
import { manageGroupAccess } from 'api/service/internal/AdminGroupApiService';
import { addAlert } from 'store/slice/ApplicationSlice';
import { v4 as uuid } from 'uuid';
import Typography from '@mui/material/Typography';
import type { Group, GroupUser } from 'types/Groups.types';
import { GroupAccessRoleType } from 'types/Groups.types';
import { isDefined, isEmpty, isNotEmptyList } from 'util/ObjectUtils';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { grey, red } from '@mui/material/colors';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { GROUP_ACCESS_ROLE } from 'constants/GroupConstants';
import MenuItem from '@mui/material/MenuItem';
import { GroupRoleTranslationId } from 'mappers/translationMappers';
import { isAdministrator } from 'store/selector/UserSelector';
import { manageGroupAccessForExternal } from 'api/service/external/GroupApiService';

type Props = {
  open: boolean,
  handleClose: Function,
  group: Group,
  parent?: Group,
  onSuccessCallback: Function
};

const GroupUsersDialog = ({ open, handleClose, group, parent, onSuccessCallback }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isAdmin = useSelector(isAdministrator);
  const [users, setUsers] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState(group?.users ?? []);

  useEffect(() => {
    if (!isDefined(parent)) {
      if (isAdmin) {
        fetchAllUsers(dispatch).then((response) => {
          setUsers(response);
        });
      } else {
        let groupUsers = group.users ?? [];
        setUsers(groupUsers);
      }
    } else {
      let groupUsers = parent.users ?? [];
      setUsers(groupUsers);
    }
  }, []);

  const handleRoleChange = (event: Event, id: number) => {
    const newRole = event.target.value;
    let updatedUsers = assignedUsers?.map((user) => {
      let updatedUser = user;
      if (user.id === id) {
        updatedUser = {
          ...user,
          groupAccessRole: newRole
        };
      }

      return updatedUser;
    });

    setAssignedUsers(updatedUsers);
  };

  const handleUserAssign = (value: GroupUser) => {
    let newUser = value;
    if (isEmpty(value.groupAccessRole)) {
      newUser = { ...value, groupAccessRole: GROUP_ACCESS_ROLE.GROUP_USER };
    }
    setAssignedUsers((oldData) => [...oldData, newUser]);
  };

  const handleUserDelete = (id: number) => {
    let updatedUsers = assignedUsers?.filter((user) => user.id !== id);
    setAssignedUsers(updatedUsers);
  };

  const handleDialogClose = () => {
    setUsers([]);
    setAssignedUsers([]);

    handleClose();
  };

  const handleSubmit = () => {
    const updatedGroupAccess = {
      groupId: group?.id,
      usersWithAccess: assignedUsers
    };

    const manageGroupAccessApiCall = isAdmin ? manageGroupAccess : manageGroupAccessForExternal;
    manageGroupAccessApiCall(updatedGroupAccess, dispatch)
      .then(() => {
        const alert = {
          id: uuid(),
          severity: 'success',
          message: intl.formatMessage({ id: 'app.user.profile.save.success' })
        };
        dispatch(addAlert(alert));
      })
      .then(handleDialogClose)
      .then(onSuccessCallback);
  };

  const userRoleOptions = Object.values(GROUP_ACCESS_ROLE);
  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperProps={{
        sx: {
          width: '100%'
        }
      }}
    >
      <DialogTitle>{intl.formatMessage({ id: 'app.manageGroups.manageAccess' }, { name: group?.name })}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Autocomplete
            id="users"
            options={users}
            getOptionLabel={(option) => `${option?.firstName ?? ''} ${option?.lastName ?? ''}`}
            getOptionDisabled={(option) => assignedUsers?.some((item) => item.id === option.id)}
            onChange={(event, value) => handleUserAssign(value)}
            disableClearable
            sx={{ width: 300, mt: 2 }}
            size="small"
            renderInput={(params) => (
              <TextField {...params} label={intl.formatMessage({ id: 'app.manageGroups.assignToUser' })} />
            )}
          />
        </Box>
        {isNotEmptyList(assignedUsers) && (
          <Box sx={{ ml: 1, mt: 2 }}>
            {assignedUsers?.map((user: any) => {
              let isOwner = user.groupAccessRole === GROUP_ACCESS_ROLE.GROUP_OWNER;
              return (
                <Box
                  key={`user-${user.email}`}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                    <Typography sx={{ marginRight: 'auto' }}>{`${user.firstName} ${user.lastName}`}</Typography>
                    <Select
                      disabled={isOwner}
                      variant="standard"
                      size="small"
                      labelId="select-role-label"
                      id="select-role-dropdown"
                      label={intl.formatMessage({ id: 'app.manageGroups.role' })}
                      value={user.groupAccessRole}
                      onChange={(event: Event) => {
                        handleRoleChange(event, user.id);
                      }}
                      sx={{ minWidth: '100px' }}
                    >
                      {userRoleOptions?.map((role: GroupAccessRoleType): React$Node => {
                        return (
                          <MenuItem
                            key={`role-item-${role}`}
                            value={role}
                            disabled={role === GROUP_ACCESS_ROLE.GROUP_OWNER}
                          >
                            {intl.formatMessage({ id: GroupRoleTranslationId[role] })}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <IconButton onClick={() => handleUserDelete(user.id)} disabled={isOwner}>
                      <DeleteOutlineOutlinedIcon sx={{ color: isOwner ? grey[500] : red[500] }} />
                    </IconButton>
                  </Box>
                  <Divider sx={{ width: '90%' }} />
                </Box>
              );
            })}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} variant="outlined">
          {intl.formatMessage({ id: 'app.common.cancel' })}
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          {intl.formatMessage({ id: 'app.common.save' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupUsersDialog;

// @flow

import { Paper, Switch, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';
import { TenantStatusTranslationId } from 'mappers/translationMappers';
import Box from '@mui/material/Box';
import { TENANT_STATUS_ENUM } from 'constants/internal/TenantConstants';

type Props = {
  tenantStatusSettings: Object,
  setTenantStatusVisibility: Function
};
const TenantStatusToggle = ({ tenantStatusSettings, setTenantStatusVisibility }: Props): React$Node => {
  const theme = useTheme();
  const intl = useIntl();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, element: string) => {
    const updatedSettings = { ...tenantStatusSettings, [element]: event.target.checked };
    setTenantStatusVisibility(updatedSettings);
  };

  return (
    <Paper
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: theme.spacing(9)
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%', pt: 1, pb: 1 }}>
        {Object.values(TENANT_STATUS_ENUM)?.map((element) => (
          <Box sx={{ display: 'flex', mr: 1, alignItems: 'center' }} key={element}>
            <Switch
              size="small"
              checked={tenantStatusSettings[element]}
              onChange={(event) => handleChange(event, element)}
            />
            <Typography variant="body2" sx={{ ml: 0.5 }}>
              {intl.formatMessage({ id: TenantStatusTranslationId[element] })}
            </Typography>
          </Box>
        ))}
        <Box sx={{ display: 'flex', mr: 1, alignItems: 'center' }}>
          <Switch
            size="small"
            checked={tenantStatusSettings[undefined]}
            onChange={(event) => handleChange(event, undefined)}
          />
          <Typography variant="body2" sx={{ ml: 0.5 }}>
            {intl.formatMessage({ id: TenantStatusTranslationId[undefined] })}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default TenantStatusToggle;

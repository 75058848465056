// @flow
import { useSelector } from 'react-redux';
import { EXTERNAL_GENERAL_OVERVIEW, INTERNAL_GENERAL_OVERVIEW, LOGIN } from 'routing/RouteConstants';
import { Navigate } from 'react-router';
import { isDefined } from 'util/ObjectUtils';
import { isAdministrator, isInternalUser, isUserAuthenticated } from 'store/selector/UserSelector';
import InternalNavigationBar from 'components/navigation/InternalNavigationBar';
import ExternalNavigationBar from 'components/navigation/ExternalNavigationBar';

type Props = {
  restricted?: boolean, // if set to true than only Administrators can access this route
  internalRestricted?: boolean, // if set to true only Internal roles can access this route
  children: React$Node
};

const PrivateRoute = (props: Props): React$Node => {
  const authenticated = useSelector(isUserAuthenticated);
  const administrator = useSelector(isAdministrator);
  const isInternal = useSelector(isInternalUser);
  const { restricted = false, internalRestricted = false, children } = props;

  let enableRoute = restricted ? administrator : authenticated;
  if (enableRoute) {
    enableRoute = internalRestricted ? isInternal : true;
  }

  let navigateTo = null;
  if (authenticated) {
    if (!enableRoute) {
      navigateTo = isInternal ? INTERNAL_GENERAL_OVERVIEW : EXTERNAL_GENERAL_OVERVIEW;
    }
  } else {
    navigateTo = LOGIN;
  }

  const ResolvedNavigationBar = isInternal ? InternalNavigationBar : ExternalNavigationBar;

  return isDefined(navigateTo) ? (
    <Navigate to={navigateTo} replace />
  ) : (
    <ResolvedNavigationBar>{children}</ResolvedNavigationBar>
  );
};

export default PrivateRoute;

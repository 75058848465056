// @flow

import React, { useEffect, useState } from 'react';
import Slide from '@mui/material/Slide';
import type { TbCustomerDataItem } from 'types/Farmer.types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getTbFarmerAlarmsFiltered } from 'api/service/internal/ThingsboardApiService';
import { DialogContent, Paper } from '@mui/material';
import { isDefined, isNotEmptyList } from 'util/ObjectUtils';
import { DateTime } from 'luxon';
import Box from '@mui/material/Box';
import { grey, red } from '@mui/material/colors';
import type { TbAlarm } from 'types/Thingsboard.types';
import Grid from '@mui/material/Grid';
import { TB_ALARMS_STATUS_ENUM } from 'constants/internal/ThingsboardConstants';

const Transition = React.forwardRef(function Transition(props: any, ref: any): React$Node {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ACTIVE_ALARMS_STATUSES = [TB_ALARMS_STATUS_ENUM.ACTIVE_UNACK, TB_ALARMS_STATUS_ENUM.ACTIVE_ACK];

type Props = {
  open: boolean,
  handleClose: Function,
  customer: TbCustomerDataItem
};

const AlarmsDialog = ({ open, handleClose, customer }: Props): React$Node => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  let customerId = customer?.customerDetails?.id?.id;
  const customerName = customer?.customerDetails?.name;
  const title = intl.formatMessage({ id: 'app.datagrid.alarms.dialog.title' }, { name: customerName });

  useEffect(() => {
    getTbFarmerAlarmsFiltered(dispatch, customerId, ACTIVE_ALARMS_STATUSES).then((response: Array<TbAlarm>) =>
      setItems(response)
    );
  }, []);

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {isNotEmptyList(items) ? (
          <Grid container spacing={2}>
            {items?.map((item: TbAlarm) => {
              let alarmCreated = isDefined(item.createdTime)
                ? DateTime.fromMillis(item.createdTime).toLocaleString(DateTime.DATETIME_MED)
                : intl.formatMessage({ id: 'app.common.not.available' });
              let isAlarmUnacknowledged =
                item.status === TB_ALARMS_STATUS_ENUM.ACTIVE_UNACK ||
                item.status === TB_ALARMS_STATUS_ENUM.CLEARED_UNACK;
              return (
                <Grid xs={12} sm={6} md={4} lg={3} item key={`customer-${customerName}-user-${item.id?.id}`}>
                  <Paper
                    elevation={2}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      p: 1,
                      border: isAlarmUnacknowledged ? `1px solid ${red[500]}` : ''
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        paddingRight: 1,
                        borderRight: `1px solid ${grey[300]}`
                      }}
                    >
                      <Typography variant="body2" sx={{ textAlign: 'right', fontWeight: 500 }}>
                        {intl.formatMessage({ id: 'app.datagrid.name' })}
                      </Typography>
                      <Typography variant="body2" sx={{ textAlign: 'right', fontWeight: 500 }}>
                        {intl.formatMessage({ id: 'app.datagrid.alarms.type' })}
                      </Typography>
                      <Typography variant="body2" sx={{ textAlign: 'right', fontWeight: 500 }}>
                        {intl.formatMessage({ id: 'app.datagrid.alarms.severity' })}
                      </Typography>
                      <Typography variant="body2" sx={{ textAlign: 'right', fontWeight: 500 }}>
                        {intl.formatMessage({ id: 'app.datagrid.alarms.status' })}
                      </Typography>
                      <Typography variant="body2" sx={{ textAlign: 'right', fontWeight: 500 }}>
                        {intl.formatMessage({ id: 'app.datagrid.alarms.details' })}
                      </Typography>
                      <Typography variant="body2" sx={{ textAlign: 'right', fontWeight: 500 }}>
                        {intl.formatMessage({ id: 'app.datagrid.alarms.createdTime' })}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', ml: 1 }}>
                      <Typography variant="body2" sx={{ textAlign: 'left', fontWeight: 300 }}>
                        {item.name}
                      </Typography>
                      <Typography variant="body2" sx={{ textAlign: 'left', fontWeight: 300 }}>
                        {item.type}
                      </Typography>
                      <Typography variant="body2" sx={{ textAlign: 'left', fontWeight: 300 }}>
                        {item.severity}
                      </Typography>
                      <Typography variant="body2" sx={{ textAlign: 'left', fontWeight: 300 }}>
                        {item.status}
                      </Typography>
                      <Typography variant="body2" sx={{ textAlign: 'left', fontWeight: 300 }}>
                        {item.details?.message}
                      </Typography>
                      <Typography variant="body2" sx={{ textAlign: 'left', fontWeight: 300 }}>
                        {alarmCreated}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Typography>{intl.formatMessage({ id: 'app.common.noData' })}</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AlarmsDialog;

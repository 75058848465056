import {
  TB_ALARM_COLUMNS,
  TB_DEVICE_ASSET_TYPE_ENUM,
  TB_DEVICE_SENSOR_TYPE_ENUM,
  TB_DEVICE_TYPE_ENUM
} from 'constants/internal/ThingsboardConstants';

export const GATEWAY_DATAGRID_GROUP_COLUMNS = {
  GATEWAY_INFO: 'GATEWAY_INFO'
};

export const GATEWAY_DATAGRID_COLUMNS = {
  STATUS: 'STATUS',
  SSH: 'SSH',
  VERSION: 'VERSION',
  IP: 'IP',
  SUBSYS_CONFIG: 'SUBSYS_CONFIG'
};

export const defaultGatewayDataGridColumnSettings = {
  [GATEWAY_DATAGRID_GROUP_COLUMNS.GATEWAY_INFO]: {
    [GATEWAY_DATAGRID_COLUMNS.STATUS]: true,
    [GATEWAY_DATAGRID_COLUMNS.SSH]: true,
    [GATEWAY_DATAGRID_COLUMNS.VERSION]: true,
    [GATEWAY_DATAGRID_COLUMNS.IP]: true,
    [GATEWAY_DATAGRID_COLUMNS.SUBSYS_CONFIG]: true
  }
};

export const CUSTOMERS_DATAGRID_GROUP_COLUMNS = {
  DEVICES: 'DEVICES',
  ASSETS: 'BUILDINGS',
  USERS: 'USERS',
  ALARMS: 'ALARMS',
  DETAILS: 'DETAILS'
};

export const CUSTOMERS_DATAGRID_COLUMNS = {
  SPEARS: 'SPEARS',
  GRAIN_ANALYZERS: 'GRAIN_ANALYZERS',
  FREE_DEVICES: 'FREE_DEVICES',
  USERS: 'USERS',
  ALARMS: 'ALARMS',
  SUBSCRIPTIONS: 'SUBSCRIPTIONS',
  COUNTRY: 'COUNTRY',
  PHONE: 'PHONE',
  BUILDINGS: 'BUILDINGS'
};

export const defaultCustomersDataGridColumnSettings = {
  [CUSTOMERS_DATAGRID_GROUP_COLUMNS.DEVICES]: {
    [CUSTOMERS_DATAGRID_COLUMNS.SPEARS]: true,
    [CUSTOMERS_DATAGRID_COLUMNS.GRAIN_ANALYZERS]: true,
    [CUSTOMERS_DATAGRID_COLUMNS.FREE_DEVICES]: true
  },
  [CUSTOMERS_DATAGRID_GROUP_COLUMNS.ASSETS]: {
    [CUSTOMERS_DATAGRID_COLUMNS.BUILDINGS]: true
  },
  [CUSTOMERS_DATAGRID_GROUP_COLUMNS.USERS]: {
    [CUSTOMERS_DATAGRID_COLUMNS.USERS]: true
  },
  [CUSTOMERS_DATAGRID_GROUP_COLUMNS.ALARMS]: {
    [CUSTOMERS_DATAGRID_COLUMNS.ALARMS]: true
  },
  [CUSTOMERS_DATAGRID_GROUP_COLUMNS.DETAILS]: {
    [CUSTOMERS_DATAGRID_COLUMNS.SUBSCRIPTIONS]: true,
    [CUSTOMERS_DATAGRID_COLUMNS.COUNTRY]: true,
    [CUSTOMERS_DATAGRID_COLUMNS.PHONE]: true
  }
};

export const DEVICES_DATAGRID_GROUP_COLUMNS = {
  INFO: 'INFO',
  SENSOR: 'SENSOR',
  LOCATION: 'LOCATION'
};

export const DEVICES_DATAGRID_COLUMNS = {
  CLAIMED: 'CLAIMED',
  IS_FREE: 'IS_FREE',
  PREPAID_YEARS: 'PREPAID_YEARS',
  SUBSCRIPTION_END: 'SUBSCRIPTION_END',
  ORDER_NUMBER: 'ORDER_NUMBER',
  DISTRIBUTOR: 'DISTRIBUTOR',
  LATEST_SAMPLE: 'LATEST_SAMPLE',
  TEMPERATURE_1: 'TEMPERATURE_1',
  TEMPERATURE_2: 'TEMPERATURE_2',
  TEMPERATURE_AMBIENT: 'TEMPERATURE_AMBIENT',
  HUMIDITY_1: 'HUMIDITY_1',
  HUMIDITY_AMBIENT: 'HUMIDITY_AMBIENT',
  CROP_TYPE: 'CROP_TYPE',
  CROP_SENSOR_1: 'CROP_SENSOR_1',
  CROP_AMBIENT: 'CROP_AMBIENT',
  LONGITUDE: 'LONGITUDE',
  LATITUDE: 'LATITUDE',
  SIM_LONGITUDE: 'SIM_LONGITUDE',
  SIM_LATITUDE: 'SIM_LATITUDE'
};

export const defaultDevicesDataGridColumnSettings = {
  [DEVICES_DATAGRID_GROUP_COLUMNS.INFO]: {
    [DEVICES_DATAGRID_COLUMNS.CLAIMED]: true,
    [DEVICES_DATAGRID_COLUMNS.IS_FREE]: true,
    [DEVICES_DATAGRID_COLUMNS.PREPAID_YEARS]: true,
    [DEVICES_DATAGRID_COLUMNS.SUBSCRIPTION_END]: true,
    [DEVICES_DATAGRID_COLUMNS.ORDER_NUMBER]: true,
    [DEVICES_DATAGRID_COLUMNS.DISTRIBUTOR]: true
  },
  [DEVICES_DATAGRID_GROUP_COLUMNS.SENSOR]: {
    [DEVICES_DATAGRID_COLUMNS.LATEST_SAMPLE]: true,
    [DEVICES_DATAGRID_COLUMNS.TEMPERATURE_1]: true,
    [DEVICES_DATAGRID_COLUMNS.TEMPERATURE_2]: true,
    [DEVICES_DATAGRID_COLUMNS.TEMPERATURE_AMBIENT]: true,
    [DEVICES_DATAGRID_COLUMNS.HUMIDITY_1]: true,
    [DEVICES_DATAGRID_COLUMNS.HUMIDITY_AMBIENT]: true,
    [DEVICES_DATAGRID_COLUMNS.CROP_TYPE]: true,
    [DEVICES_DATAGRID_COLUMNS.CROP_SENSOR_1]: true,
    [DEVICES_DATAGRID_COLUMNS.CROP_AMBIENT]: true
  },
  [DEVICES_DATAGRID_GROUP_COLUMNS.LOCATION]: {
    [DEVICES_DATAGRID_COLUMNS.LONGITUDE]: true,
    [DEVICES_DATAGRID_COLUMNS.LATITUDE]: true,
    [DEVICES_DATAGRID_COLUMNS.SIM_LONGITUDE]: true,
    [DEVICES_DATAGRID_COLUMNS.SIM_LATITUDE]: true
  }
};

export const MANAGER_DATAGRID_GROUP_COLUMNS = {
  TENANT_DETAILS: 'TENANT_DETAILS',
  SUBSCRIPTIONS: 'SUBSCRIPTIONS',
  SENSORS: 'SENSORS',
  ALARMS: 'ALARMS',
  DEVICES: 'DEVICES',
  ASSETS: 'ASSETS'
};

export const MANAGER_DATAGRID_COLUMNS = {
  SITES_DETAILS: 'SITES_DETAILS',
  GATEWAY_DETAILS: 'GATEWAY_DETAILS',
  LAST_GATEWAY_ACTIVITY: 'LAST_GATEWAY_ACTIVITY',
  SYSTEM_TYPE: 'SYSTEM_TYPE',
  COMMENT: 'COMMENT',
  ODOO_CUSTOMER: 'ODOO_CUSTOMER',
  ODOO_TENANT: 'ODOO_TENANT',
  ODOO_DESCRIPTION: 'ODOO_DESCRIPTION',
  DEVELOPMENT: 'DEVELOPMENT',
  ARCHIVED: 'ARCHIVED',
  CANCELED: 'CANCELED',
  CHARGEBEE_CUSTOMER: 'CHARGEBEE_CUSTOMER',
  CHARGEBEE_SUBSCRIPTION: 'CHARGEBEE_SUBSCRIPTION',
  SUBSCRIPTION_STATUS: 'SUBSCRIPTION_STATUS',
  BILLING_PERIOD: 'BILLING_PERIOD',
  UNBILLED_CHARGES: 'UNBILLED_CHARGES',
  INVOICE_STATUS: 'INVOICE_STATUS',
  SHUTOUT_DATE: 'SHUTOUT_DATE',
  TEMP_SENSORS_CB: 'TEMP_SENSORS_CB',
  TEMP_SENSORS_TB: 'TEMP_SENSORS_TB',
  TEMP_SENSORS_ODOO: 'TEMP_SENSORS_ODOO',
  MOISTURE_SENSORS_CB: 'MOISTURE_SENSORS_CB',
  MOISTURE_SENSORS_TB: 'MOISTURE_SENSORS_TB',
  MOISTURE_SENSORS_ODOO: 'MOISTURE_SENSORS_ODOO'
};

export const defaultManagerDataGridColumnSettings = {
  [MANAGER_DATAGRID_GROUP_COLUMNS.TENANT_DETAILS]: {
    [MANAGER_DATAGRID_COLUMNS.SITES_DETAILS]: false,
    [MANAGER_DATAGRID_COLUMNS.GATEWAY_DETAILS]: false,
    [MANAGER_DATAGRID_COLUMNS.LAST_GATEWAY_ACTIVITY]: false,
    [MANAGER_DATAGRID_COLUMNS.SYSTEM_TYPE]: false,
    [MANAGER_DATAGRID_COLUMNS.COMMENT]: false,
    [MANAGER_DATAGRID_COLUMNS.ODOO_CUSTOMER]: false,
    [MANAGER_DATAGRID_COLUMNS.ODOO_TENANT]: false,
    [MANAGER_DATAGRID_COLUMNS.ODOO_DESCRIPTION]: false,
    [MANAGER_DATAGRID_COLUMNS.DEVELOPMENT]: false,
    [MANAGER_DATAGRID_COLUMNS.ARCHIVED]: false,
    [MANAGER_DATAGRID_COLUMNS.CANCELED]: false
  },
  [MANAGER_DATAGRID_GROUP_COLUMNS.SUBSCRIPTIONS]: {
    [MANAGER_DATAGRID_COLUMNS.CHARGEBEE_CUSTOMER]: false,
    [MANAGER_DATAGRID_COLUMNS.CHARGEBEE_SUBSCRIPTION]: false,
    [MANAGER_DATAGRID_COLUMNS.SUBSCRIPTION_STATUS]: false,
    [MANAGER_DATAGRID_COLUMNS.BILLING_PERIOD]: false,
    [MANAGER_DATAGRID_COLUMNS.UNBILLED_CHARGES]: false,
    [MANAGER_DATAGRID_COLUMNS.INVOICE_STATUS]: false,
    [MANAGER_DATAGRID_COLUMNS.SHUTOUT_DATE]: false
  },
  [MANAGER_DATAGRID_GROUP_COLUMNS.SENSORS]: {
    [MANAGER_DATAGRID_COLUMNS.TEMP_SENSORS_CB]: false,
    [MANAGER_DATAGRID_COLUMNS.TEMP_SENSORS_TB]: false,
    [MANAGER_DATAGRID_COLUMNS.TEMP_SENSORS_ODOO]: false,
    [MANAGER_DATAGRID_COLUMNS.MOISTURE_SENSORS_CB]: false,
    [MANAGER_DATAGRID_COLUMNS.MOISTURE_SENSORS_TB]: false,
    [MANAGER_DATAGRID_COLUMNS.MOISTURE_SENSORS_ODOO]: false,
    [TB_DEVICE_SENSOR_TYPE_ENUM.TEMPERATURE_SENSOR]: false,
    [TB_DEVICE_SENSOR_TYPE_ENUM.MOISTURE_SENSOR]: false,
    [TB_DEVICE_SENSOR_TYPE_ENUM.HEAD_SPACE_SENSOR]: false,
    [TB_DEVICE_SENSOR_TYPE_ENUM.CO2_SENSOR]: false,
    [TB_DEVICE_SENSOR_TYPE_ENUM.LEVEL_INDICATOR]: false
  },
  [MANAGER_DATAGRID_GROUP_COLUMNS.ALARMS]: {
    [TB_ALARM_COLUMNS.ACTIVE_ALARMS]: false,
    [TB_ALARM_COLUMNS.ACTIVE_ALARM_TYPES]: false,
    [TB_ALARM_COLUMNS.STALE_ALARMS]: false,
    [TB_ALARM_COLUMNS.STALE_ALARM_TYPES]: false
  },
  [MANAGER_DATAGRID_GROUP_COLUMNS.DEVICES]: {
    [TB_DEVICE_TYPE_ENUM.TEMPERATURE_SENSOR_LINES]: false,
    [TB_DEVICE_TYPE_ENUM.MOISTURE_SENSOR_LINES]: false,
    [TB_DEVICE_TYPE_ENUM.HEAD_SPACE_SENSOR]: false,
    [TB_DEVICE_TYPE_ENUM.LEVEL_INDICATOR]: false,
    [TB_DEVICE_TYPE_ENUM.WEATHER_STATION]: false,
    [TB_DEVICE_TYPE_ENUM.SMS]: false,
    [TB_DEVICE_TYPE_ENUM.MODBUS]: false,
    [TB_DEVICE_TYPE_ENUM.GATEWAY]: false,
    [TB_DEVICE_ASSET_TYPE_ENUM.AERATION]: false
  },
  [MANAGER_DATAGRID_GROUP_COLUMNS.ASSETS]: {
    [TB_DEVICE_ASSET_TYPE_ENUM.SITE]: false,
    [TB_DEVICE_ASSET_TYPE_ENUM.SILO_GROUP]: false,
    [TB_DEVICE_ASSET_TYPE_ENUM.SILO]: false
  }
};

export const defaultSubscriptionsDataGridColumnSettings = {
  ...defaultManagerDataGridColumnSettings,
  [MANAGER_DATAGRID_GROUP_COLUMNS.TENANT_DETAILS]: {
    [MANAGER_DATAGRID_COLUMNS.SITES_DETAILS]: false,
    [MANAGER_DATAGRID_COLUMNS.GATEWAY_DETAILS]: false,
    [MANAGER_DATAGRID_COLUMNS.LAST_GATEWAY_ACTIVITY]: false,
    [MANAGER_DATAGRID_COLUMNS.SYSTEM_TYPE]: true,
    [MANAGER_DATAGRID_COLUMNS.COMMENT]: true,
    [MANAGER_DATAGRID_COLUMNS.ODOO_CUSTOMER]: true,
    [MANAGER_DATAGRID_COLUMNS.ODOO_TENANT]: true,
    [MANAGER_DATAGRID_COLUMNS.ODOO_DESCRIPTION]: true,
    [MANAGER_DATAGRID_COLUMNS.DEVELOPMENT]: true,
    [MANAGER_DATAGRID_COLUMNS.ARCHIVED]: true,
    [MANAGER_DATAGRID_COLUMNS.CANCELED]: true
  },
  [MANAGER_DATAGRID_GROUP_COLUMNS.SENSORS]: {
    [MANAGER_DATAGRID_COLUMNS.TEMP_SENSORS_CB]: true,
    [MANAGER_DATAGRID_COLUMNS.TEMP_SENSORS_TB]: true,
    [MANAGER_DATAGRID_COLUMNS.TEMP_SENSORS_ODOO]: true,
    [MANAGER_DATAGRID_COLUMNS.MOISTURE_SENSORS_CB]: true,
    [MANAGER_DATAGRID_COLUMNS.MOISTURE_SENSORS_TB]: true,
    [MANAGER_DATAGRID_COLUMNS.MOISTURE_SENSORS_ODOO]: true,
    [TB_DEVICE_SENSOR_TYPE_ENUM.TEMPERATURE_SENSOR]: false,
    [TB_DEVICE_SENSOR_TYPE_ENUM.MOISTURE_SENSOR]: false,
    [TB_DEVICE_SENSOR_TYPE_ENUM.HEAD_SPACE_SENSOR]: false,
    [TB_DEVICE_SENSOR_TYPE_ENUM.CO2_SENSOR]: false,
    [TB_DEVICE_SENSOR_TYPE_ENUM.LEVEL_INDICATOR]: false
  },
  [MANAGER_DATAGRID_GROUP_COLUMNS.SUBSCRIPTIONS]: {
    [MANAGER_DATAGRID_COLUMNS.CHARGEBEE_CUSTOMER]: true,
    [MANAGER_DATAGRID_COLUMNS.CHARGEBEE_SUBSCRIPTION]: true,
    [MANAGER_DATAGRID_COLUMNS.SUBSCRIPTION_STATUS]: true,
    [MANAGER_DATAGRID_COLUMNS.BILLING_PERIOD]: true,
    [MANAGER_DATAGRID_COLUMNS.UNBILLED_CHARGES]: true,
    [MANAGER_DATAGRID_COLUMNS.INVOICE_STATUS]: true,
    [MANAGER_DATAGRID_COLUMNS.SHUTOUT_DATE]: true
  }
};

export const defaultTenantsDataGridColumnSettings = {
  ...defaultManagerDataGridColumnSettings,
  [MANAGER_DATAGRID_GROUP_COLUMNS.TENANT_DETAILS]: {
    [MANAGER_DATAGRID_COLUMNS.SITES_DETAILS]: true,
    [MANAGER_DATAGRID_COLUMNS.GATEWAY_DETAILS]: true,
    [MANAGER_DATAGRID_COLUMNS.LAST_GATEWAY_ACTIVITY]: true,
    [MANAGER_DATAGRID_COLUMNS.SYSTEM_TYPE]: false,
    [MANAGER_DATAGRID_COLUMNS.COMMENT]: false,
    [MANAGER_DATAGRID_COLUMNS.ODOO_CUSTOMER]: false,
    [MANAGER_DATAGRID_COLUMNS.ODOO_TENANT]: true,
    [MANAGER_DATAGRID_COLUMNS.ODOO_DESCRIPTION]: false,
    [MANAGER_DATAGRID_COLUMNS.DEVELOPMENT]: true,
    [MANAGER_DATAGRID_COLUMNS.ARCHIVED]: true,
    [MANAGER_DATAGRID_COLUMNS.CANCELED]: true
  },
  [MANAGER_DATAGRID_GROUP_COLUMNS.SENSORS]: {
    [MANAGER_DATAGRID_COLUMNS.TEMP_SENSORS_CB]: false,
    [MANAGER_DATAGRID_COLUMNS.TEMP_SENSORS_TB]: false,
    [MANAGER_DATAGRID_COLUMNS.TEMP_SENSORS_ODOO]: false,
    [MANAGER_DATAGRID_COLUMNS.MOISTURE_SENSORS_CB]: false,
    [MANAGER_DATAGRID_COLUMNS.MOISTURE_SENSORS_TB]: false,
    [MANAGER_DATAGRID_COLUMNS.MOISTURE_SENSORS_ODOO]: false,
    [TB_DEVICE_SENSOR_TYPE_ENUM.TEMPERATURE_SENSOR]: true,
    [TB_DEVICE_SENSOR_TYPE_ENUM.MOISTURE_SENSOR]: true,
    [TB_DEVICE_SENSOR_TYPE_ENUM.HEAD_SPACE_SENSOR]: true,
    [TB_DEVICE_SENSOR_TYPE_ENUM.CO2_SENSOR]: true,
    [TB_DEVICE_SENSOR_TYPE_ENUM.LEVEL_INDICATOR]: true
  },
  [MANAGER_DATAGRID_GROUP_COLUMNS.ALARMS]: {
    [TB_ALARM_COLUMNS.ACTIVE_ALARMS]: true,
    [TB_ALARM_COLUMNS.ACTIVE_ALARM_TYPES]: true,
    [TB_ALARM_COLUMNS.STALE_ALARMS]: true,
    [TB_ALARM_COLUMNS.STALE_ALARM_TYPES]: true
  },
  [MANAGER_DATAGRID_GROUP_COLUMNS.DEVICES]: {
    [TB_DEVICE_TYPE_ENUM.TEMPERATURE_SENSOR_LINES]: true,
    [TB_DEVICE_TYPE_ENUM.MOISTURE_SENSOR_LINES]: true,
    [TB_DEVICE_TYPE_ENUM.HEAD_SPACE_SENSOR]: true,
    [TB_DEVICE_TYPE_ENUM.LEVEL_INDICATOR]: true,
    [TB_DEVICE_TYPE_ENUM.WEATHER_STATION]: true,
    [TB_DEVICE_TYPE_ENUM.SMS]: true,
    [TB_DEVICE_TYPE_ENUM.MODBUS]: true,
    [TB_DEVICE_TYPE_ENUM.GATEWAY]: true,
    [TB_DEVICE_ASSET_TYPE_ENUM.AERATION]: true
  },
  [MANAGER_DATAGRID_GROUP_COLUMNS.ASSETS]: {
    [TB_DEVICE_ASSET_TYPE_ENUM.SITE]: true,
    [TB_DEVICE_ASSET_TYPE_ENUM.SILO_GROUP]: true,
    [TB_DEVICE_ASSET_TYPE_ENUM.SILO]: true
  }
};

// @flow
import { DataGrid, GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IntlShape, useIntl } from 'react-intl';
import type { TbAssetTelemetriesItem, TbTenantMaster, TelemetryAttribute } from 'types/Thingsboard.types';
import { TbAssetItem, TelemetryData } from 'types/Thingsboard.types';
import { useEffect, useState } from 'react';
import { InputAdornment, useTheme } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { isDefined, isEmpty, isNotEmpty } from 'util/ObjectUtils';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TextField from '@mui/material/TextField';
import { MANAGER_DEVICE_ATTRIBUTE_ENUM } from 'constants/internal/DeviceConstants';
import { getDateTimeString, getDetailedRelativeTime } from 'util/DateUtils';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { sendTbManagerRpc } from 'api/service/internal/ThingsboardApiService';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { addAlert } from 'store/slice/ApplicationSlice';
import { copyToClipboard } from 'util/CommonUtils';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Dispatch } from '@reduxjs/toolkit';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  GATEWAY_DATAGRID_COLUMN_VISIBILITY,
  getDataGridColumnVisibility,
  storeDataGridColumnVisibility
} from 'api/service/LocalStorageService';
import DataGridSettingsDrawer from 'components/internal/drawer/DataGridSettingsDrawer';
import { GATEWAY_DATAGRID_COLUMNS, GATEWAY_DATAGRID_GROUP_COLUMNS } from 'constants/internal/DataGridConstants';
import { DATA_GRID_TYPE } from 'constants/GlobalConstants';
import { isAdministratorOrSupport } from 'store/selector/UserSelector';
import { DateTime } from 'luxon';

const customNumberComparator = (v1: number, v2: number): number => {
  if (isDefined(v1) && isDefined(v2)) {
    return v1 - v2;
  }
  return 0;
};

const createColumns = (
  intl: IntlShape,
  dispatch: Dispatch,
  fetchGatewayStatus: Function,
  fetchGatewaySession: Function,
  handleCopy: Function,
  isAdminOrSupport: boolean
): GridColDef[] => {
  const columns = [
    { field: 'itemNo', headerName: '#', width: 60 },
    {
      field: 'tenantMaster',
      headerName: intl.formatMessage({ id: 'app.datagrid.tenant' }),
      width: 200,
      valueFormatter: (v) => {
        return v?.value?.name;
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const tenantMaster = cellValues.row.tenantMaster;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
            {isDefined(tenantMaster) ? (
              <>
                <Typography>{tenantMaster.name}</Typography>
                <Typography fontSize="small">{tenantMaster.description}</Typography>
              </>
            ) : (
              <Typography>N/A</Typography>
            )}
          </Box>
        );
      }
    },
    {
      field: 'name',
      headerName: intl.formatMessage({ id: 'app.datagrid.name' }),
      width: 250
    },
    {
      field: 'lastActivity',
      headerName: intl.formatMessage({ id: 'app.datagrid.gateways.lastActivity' }),
      width: 130,
      valueFormatter: (v) => {
        let millis = v?.value;

        let dateTime = '-';
        if (millis > 0) {
          dateTime = DateTime.fromMillis(millis).toLocaleString(DateTime.DATETIME_MED);
        }

        return dateTime;
      },
      sortComparator: customNumberComparator,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let ts = cellValues.row.lastActivity;
        return (
          <Tooltip title={getDateTimeString(ts)}>
            <Typography>{isDefined(ts) && ts > 0 ? `${getDetailedRelativeTime(ts)} ago` : ''}</Typography>
          </Tooltip>
        );
      }
    },
    {
      field: 'lastTelemetry',
      headerName: intl.formatMessage({ id: 'app.datagrid.gateways.lastTelemetry' }),
      width: 130,
      valueFormatter: (v) => {
        let millis = v?.value;

        let dateTime = '-';
        if (millis > 0) {
          dateTime = DateTime.fromMillis(millis).toLocaleString(DateTime.DATETIME_MED);
        }

        return dateTime;
      },
      sortComparator: customNumberComparator,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let ts = cellValues.row.lastTelemetry;
        return (
          <Tooltip title={getDateTimeString(ts)}>
            <Typography>{isDefined(ts) && ts > 0 ? `${getDetailedRelativeTime(ts)} ago ` : ''}</Typography>
          </Tooltip>
        );
      }
    },
    {
      field: 'version',
      headerName: intl.formatMessage({ id: 'app.datagrid.gateways.version' }),
      width: 200
    },
    {
      field: 'ip',
      headerName: intl.formatMessage({ id: 'app.datagrid.gateways.ip' }),
      width: 200
    },
    {
      field: 'subsysConfig',
      headerName: intl.formatMessage({ id: 'app.datagrid.gateways.subsysConfig' }),
      width: 100,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const subsysConfig = cellValues.row.subsysConfig;
        return (
          <Tooltip title={subsysConfig}>
            <Button
              variant="outlined"
              onClick={() => copyToClipboard(subsysConfig)}
              startIcon={<ContentCopyOutlinedIcon />}
            >
              {intl.formatMessage({ id: 'app.common.copy' })}
            </Button>
          </Tooltip>
        );
      }
    }
  ];

  if (isAdminOrSupport) {
    columns.splice(
      5,
      0,
      {
        field: 'status',
        headerName: intl.formatMessage({ id: 'app.datagrid.gateways.status' }),
        width: 100,
        valueFormatter: (v) => {
          return v?.value?.result?.statename;
        },
        renderCell: (cellValues: GridRenderCellParams): React$Node => {
          const status = cellValues.row.status;
          const tenantMaster = cellValues.row.tenantMaster;
          const deviceId = cellValues.row.id;
          const subsysConfig = cellValues.row.subsysConfig;

          let subsysid = null;
          if (isNotEmpty(subsysConfig)) {
            subsysid = JSON.parse(subsysConfig)?.id;
          }

          const body = { method: 'subsys_status', params: { subsysid }, timeout: 3000 };
          const statusString = JSON.stringify(status);

          return isDefined(status) ? (
            <Tooltip title={statusString}>
              <Typography sx={{ '&:hover': { cursor: 'pointer' } }} onClick={() => handleCopy(statusString)}>
                {status?.result?.statename}
              </Typography>
            </Tooltip>
          ) : (
            <Button
              size="small"
              color="error"
              variant="outlined"
              onClick={() => fetchGatewayStatus(tenantMaster?.name, deviceId, JSON.stringify(body))}
              disabled={isEmpty(subsysid)}
            >
              {intl.formatMessage({ id: 'app.datagrid.gateways.fetch' })}
            </Button>
          );
        }
      },
      {
        field: 'ssh',
        headerName: intl.formatMessage({ id: 'app.datagrid.gateways.remoteSession' }),
        width: 200,
        valueFormatter: (v) => {
          return v?.value?.result;
        },
        renderCell: (cellValues: GridRenderCellParams): React$Node => {
          const remoteSession = cellValues.row.remoteSession;
          const tenantMaster = cellValues.row.tenantMaster;
          const deviceId = cellValues.row.id;

          const body = { method: 'system_remote_session', timeout: 10000 };

          return isDefined(remoteSession) ? (
            <Box sx={{ display: 'flex', overflowX: 'auto', alignItems: 'center' }}>
              <Tooltip title={JSON.stringify(remoteSession)}>
                <Typography sx={{ '&:hover': { cursor: 'pointer' } }} onClick={() => handleCopy(remoteSession?.result)}>
                  {remoteSession?.result}
                </Typography>
              </Tooltip>
            </Box>
          ) : (
            <Button
              size="small"
              color="error"
              variant="outlined"
              onClick={() => fetchGatewaySession(tenantMaster?.name, deviceId, JSON.stringify(body))}
            >
              {intl.formatMessage({ id: 'app.datagrid.gateways.fetch' })}
            </Button>
          );
        }
      }
    );
  }

  return columns;
};

type DataGridItem = TbAssetTelemetriesItem & {
  tenantInfo: TbAssetItem
};

type Props = {
  items: Array<TbAssetTelemetriesItem>,
  masterTenants: Array<TbTenantMaster>
};

const GatewayDataGrid = ({ items, masterTenants }: Props): React$Node => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useTheme();
  const isAdminOrSupport = useSelector(isAdministratorOrSupport);

  const [searchText, setSearchText] = useState('');
  const [dataGridItems, setDataGridItems] = useState([]);
  const [rpcStatusMap, setRpcStatusMap] = useState({});
  const [rpcSessionMap, setRpcSessionMap] = useState({});
  const [visibleColumns, setVisibleColumns] = useState(getDataGridColumnVisibility(GATEWAY_DATAGRID_COLUMN_VISIBILITY));
  const [visibleColumnsDrawerOpen, setVisibleColumnsDrawerOpen] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});

  const closeVisibleColumnsDrawer = () => {
    setVisibleColumnsDrawerOpen(false);
  };

  useEffect(() => {
    let tempItems = [];

    items.forEach((item: TbAssetTelemetriesItem) => {
      const matchedTenantMaster = masterTenants.find(
        (masterTenant) => masterTenant.id.id === item.assetDto.tenantId.id
      );

      tempItems.push({
        ...item,
        tenantMaster: matchedTenantMaster
      });
    });

    const filteredItems = tempItems?.filter(
      (item: DataGridItem) =>
        item.assetDto?.name?.toLowerCase()?.includes(searchText.toLowerCase()) ||
        item.tenantMaster?.name?.toLowerCase()?.includes(searchText.toLowerCase()) ||
        item.tenantMaster?.description?.toLowerCase()?.includes(searchText.toLowerCase())
    );

    setDataGridItems(filteredItems);
  }, [items, masterTenants, searchText]);

  const fetchGatewayStatus = (tenantName: string, deviceId: string, body: string) => {
    sendTbManagerRpc(dispatch, {
      tenantName,
      deviceId,
      body,
      oneWay: false
    }).then((response: any): any => {
      let jsonResponse = null;
      try {
        jsonResponse = JSON.parse(response?.response);
      } catch (e) {
        console.log(e);
      }

      if (!isDefined(jsonResponse)) {
        const alert = {
          id: uuid(),
          severity: 'warning',
          title: intl.formatMessage({ id: 'app.alert.warning.title' }),
          message: intl.formatMessage({ id: 'app.alert.warning.noRpcResponse' })
        };
        dispatch(addAlert(alert));
      }

      setRpcStatusMap((oldData) => ({
        ...oldData,
        [deviceId]: jsonResponse
      }));
    });
  };

  const fetchGatewaySession = (tenantName: string, deviceId: string, body: string) => {
    sendTbManagerRpc(dispatch, {
      tenantName,
      deviceId,
      body,
      oneWay: false
    }).then((response: any): any => {
      let jsonResponse = null;
      try {
        jsonResponse = JSON.parse(response?.response);
      } catch (e) {
        console.log(e);
      }

      if (!isDefined(jsonResponse)) {
        const alert = {
          id: uuid(),
          severity: 'warning',
          title: intl.formatMessage({ id: 'app.alert.warning.title' }),
          message: intl.formatMessage({ id: 'app.alert.warning.noRpcResponse' })
        };
        dispatch(addAlert(alert));
      }

      setRpcSessionMap((oldData) => ({
        ...oldData,
        [deviceId]: jsonResponse
      }));
    });
  };

  const rows = dataGridItems?.map((item: DataGridItem, index: number): any => {
    const attributes = item.attributes;
    const telemetries = item.telemetryData;

    const version = attributes.find(
      (attribute: TelemetryAttribute) => attribute.key === MANAGER_DEVICE_ATTRIBUTE_ENUM.AGW_VERSION
    )?.value;
    const ip = attributes.find(
      (attribute: TelemetryAttribute) => attribute.key === MANAGER_DEVICE_ATTRIBUTE_ENUM.IP
    )?.value;
    const subsysConfig = attributes.find(
      (attribute: TelemetryAttribute) => attribute.key === MANAGER_DEVICE_ATTRIBUTE_ENUM.SUBSYS_CONFIG
    )?.value;
    const lastActivity = attributes.find(
      (attribute: TelemetryAttribute) => attribute.key === MANAGER_DEVICE_ATTRIBUTE_ENUM.LAST_ACTIVITY
    )?.value;

    const sortedTelemetries = Object.values(telemetries).sort(
      (a: Array<TelemetryData>, b: Array<TelemetryData>): number => {
        return a?.[0]?.ts ?? 0 - b?.[0]?.ts ?? 0;
      }
    );

    return {
      itemNo: index + 1,
      id: item.assetDto.id.id,
      tenantMaster: item.tenantMaster,
      name: item.assetDto.name,
      status: rpcStatusMap[item.assetDto.id.id],
      remoteSession: rpcSessionMap[item.assetDto.id.id],
      version: version,
      ip: ip,
      subsysConfig: subsysConfig,
      lastActivity: lastActivity,
      lastTelemetry: sortedTelemetries?.[0]?.[0]?.ts
    };
  });

  const handleSearchChange = (event: Event) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText('');
  };

  const handleCopy = (text: string) => {
    copyToClipboard(text);
    const alert = {
      id: uuid(),
      severity: 'info',
      message: intl.formatMessage({ id: 'app.alert.info.copiedToClipboard' })
    };
    dispatch(addAlert(alert));
  };

  useEffect(() => {
    const updatedVisibilityModel = {
      status: visibleColumns[GATEWAY_DATAGRID_GROUP_COLUMNS.GATEWAY_INFO][GATEWAY_DATAGRID_COLUMNS.STATUS],
      ssh: visibleColumns[GATEWAY_DATAGRID_GROUP_COLUMNS.GATEWAY_INFO][GATEWAY_DATAGRID_COLUMNS.SSH],
      version: visibleColumns[GATEWAY_DATAGRID_GROUP_COLUMNS.GATEWAY_INFO][GATEWAY_DATAGRID_COLUMNS.VERSION],
      ip: visibleColumns[GATEWAY_DATAGRID_GROUP_COLUMNS.GATEWAY_INFO][GATEWAY_DATAGRID_COLUMNS.IP],
      subsysConfig: visibleColumns[GATEWAY_DATAGRID_GROUP_COLUMNS.GATEWAY_INFO][GATEWAY_DATAGRID_COLUMNS.SUBSYS_CONFIG]
    };

    setColumnVisibilityModel(updatedVisibilityModel);
    storeDataGridColumnVisibility(GATEWAY_DATAGRID_COLUMN_VISIBILITY, visibleColumns);
  }, [visibleColumns]);

  let columns = createColumns(intl, dispatch, fetchGatewayStatus, fetchGatewaySession, handleCopy, isAdminOrSupport);
  return (
    <Box sx={{ height: '75vh', width: '100%' }}>
      <DataGridSettingsDrawer
        dataGridType={DATA_GRID_TYPE.GATEWAY_DATA_GRID}
        open={visibleColumnsDrawerOpen}
        closeDrawer={closeVisibleColumnsDrawer}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <TextField
          variant="outlined"
          size="small"
          margin="normal"
          fullWidth
          label={intl.formatMessage({ id: 'app.common.search' })}
          type={'text'}
          sx={{
            width: theme.spacing(29)
          }}
          InputProps={{
            style: {
              borderRadius: '8px'
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon color="primary" />
              </InputAdornment>
            ),
            endAdornment: isNotEmpty(searchText) ? (
              <InputAdornment position="end">
                <IconButton aria-label="search bar" edge="end" onClick={handleClearSearch}>
                  <CloseOutlinedIcon color="primary" />
                </IconButton>
              </InputAdornment>
            ) : null
          }}
          value={searchText}
          onChange={handleSearchChange}
        />
        <Box>
          <Tooltip title={intl.formatMessage({ id: 'app.datagrid.drawer.visible.columns' })}>
            <IconButton onClick={() => setVisibleColumnsDrawerOpen(true)}>
              <SettingsOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100
            }
          }
        }}
        pageSizeOptions={[100]}
        slots={{
          toolbar: GridToolbar
        }}
        density={'standard'}
        disableColumnSelector
        disableColumnFilter
      />
    </Box>
  );
};

export default GatewayDataGrid;

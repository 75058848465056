// @flow

export const GROUP_TREE_ITEM_ENUM = {
  GROUP: 'GROUP',
  TENANT: 'TENANT',
  DEVICE: 'DEVICE',
  USER: 'USER',
  SITE: 'SITE'
};

export const GROUP_ACCESS_ROLE = {
  GROUP_ADMIN: 'GROUP_ADMIN',
  GROUP_USER: 'GROUP_USER',
  GROUP_OWNER: 'GROUP_OWNER'
};

import { INTERNAL_FARMER_APP_DEVICES, INTERNAL_MANAGER_TENANTS } from 'routing/RouteConstants';
import { isDefined } from 'util/ObjectUtils';

export const addTooltipHandling = (map, popup, redirectTo, redirectMessage) => {
  if (isDefined(redirectTo) && isDefined(redirectMessage)) {
    map.on('click', 'sites-layer', (e) => {
      const tenantId = e.features[0].properties.tenantId;

      // Redirect on click to the Tenants page.
      redirectTo(INTERNAL_MANAGER_TENANTS, tenantId);
    });

    map.on('click', 'sites-on-premise-layer', (e) => {
      const tenantId = e.features[0].properties.tenantId;

      // Redirect on click to the Tenants page.
      redirectTo(INTERNAL_MANAGER_TENANTS, tenantId);
    });

    map.on('click', 'devices-layer', (e) => {
      const deviceId = e.features[0].properties.id;

      // Redirect on click to the Tenants page.
      redirectTo(INTERNAL_FARMER_APP_DEVICES, deviceId);
    });
  }

  map.on('mouseenter', 'devices-layer', (e) => {
    // Change the cursor style as a UI indicator.
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = 'pointer';

    // Copy coordinates array.
    const coordinates = e.features[0].geometry.coordinates.slice();
    const description = e.features[0].properties.name;

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    // Populate the popup and set its coordinates
    // based on the feature found.
    const tooltipMessage = redirectMessage
      ? `<p style="margin: 0">${description}</p>
         <p style="margin: 0; font-style: italic; color: gray">${redirectMessage}</p>`
      : description;
    popup.setLngLat(coordinates).setHTML(tooltipMessage).addTo(map);
  });

  map.on('mouseleave', 'devices-layer', () => {
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = '';
    popup.remove();
  });

  map.on('mouseenter', 'sites-layer', (e) => {
    // Change the cursor style as a UI indicator.
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = 'pointer';

    // Copy coordinates array.
    const coordinates = e.features[0].geometry.coordinates.slice();
    const description = e.features[0].properties.name;

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    // Populate the popup and set its coordinates
    // based on the feature found.
    const tooltipMessage = redirectMessage
      ? `<p style="margin: 0">${description}</p>
         <p style="margin: 0; font-style: italic; color: gray">${redirectMessage}</p>`
      : description;
    popup.setLngLat(coordinates).setHTML(tooltipMessage).addTo(map);
  });

  map.on('mouseleave', 'sites-layer', () => {
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = '';
    popup.remove();
  });

  map.on('mouseenter', 'sites-on-premise-layer', (e) => {
    // Change the cursor style as a UI indicator.
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = 'pointer';

    // Copy coordinates array.
    const coordinates = e.features[0].geometry.coordinates.slice();
    const description = e.features[0].properties.name;

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    // Populate the popup and set its coordinates
    // based on the feature found.
    const tooltipMessage = redirectMessage
      ? `<p style="margin: 0">${description}</p>
         <p style="margin: 0; font-style: italic; color: gray">${redirectMessage}</p>`
      : description;
    popup.setLngLat(coordinates).setHTML(tooltipMessage).addTo(map);
  });

  map.on('mouseleave', 'sites-on-premise-layer', () => {
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = '';
    popup.remove();
  });

  map.on('mouseenter', 'buildings-layer', (e) => {
    // Change the cursor style as a UI indicator.
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = 'pointer';

    // Copy coordinates array.
    const coordinates = e.features[0].geometry.coordinates.slice();
    const description = e.features[0].properties.name;

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    // Populate the popup and set its coordinates
    // based on the feature found.
    popup.setLngLat(coordinates).setHTML(description).addTo(map);
  });

  map.on('mouseleave', 'buildings-layer', () => {
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = '';
    popup.remove();
  });

  map.on('mouseenter', 'users-layer', (e) => {
    // Change the cursor style as a UI indicator.
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = 'pointer';

    // Copy coordinates array.
    const coordinates = e.features[0].geometry.coordinates.slice();
    const description = e.features[0].properties.name;

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    // Populate the popup and set its coordinates
    // based on the feature found.
    popup.setLngLat(coordinates).setHTML(description).addTo(map);
  });

  map.on('mouseleave', 'users-layer', () => {
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = '';
    popup.remove();
  });
};

//@flow

import Button from '@mui/material/Button';
import PageContainer from 'pages/common/PageContainer';
import { useDispatch } from 'react-redux';
import { refreshLongTermCache, refreshShortTermCache } from 'api/service/internal/AdminApiService';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';

const AdminServices = (): React$Node => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleRefreshLongTermCache = () => {
    refreshLongTermCache(dispatch);
  };

  const handleRefreshShortTermCache = () => {
    refreshShortTermCache(dispatch);
  };

  return (
    <PageContainer>
      <Box sx={{ display: 'flex', m: 4 }}>
        <Button sx={{ width: 'fit-content' }} variant={'outlined'} color="error" onClick={handleRefreshLongTermCache}>
          {intl.formatMessage({ id: 'app.admin.services.cache.longTerm.refresh' })}
        </Button>
        <Button
          sx={{ width: 'fit-content', ml: 4 }}
          variant={'outlined'}
          color="error"
          onClick={handleRefreshShortTermCache}
        >
          {intl.formatMessage({ id: 'app.admin.services.cache.shortTerm.refresh' })}
        </Button>
      </Box>
    </PageContainer>
  );
};

export default AdminServices;

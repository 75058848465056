// @flow

import PageTitle from 'components/PageTitle';
import PageContainer from 'pages/common/PageContainer';
import ManagerDataGrid from 'components/internal/datagrid/manager/ManagerDataGrid';
import { APPLICATION_PAGE } from 'constants/GlobalConstants';
import { useLocation } from 'react-router';

const ManagerTenants = (): React$Node => {
  const location = useLocation();
  const preFilteredTenantId = location?.state?.id;

  return (
    <PageContainer>
      <PageTitle titleKey={'app.page.title.manager.tenants'} />
      <ManagerDataGrid dataGridPage={APPLICATION_PAGE.MANAGER_TENANTS} preFilteredTenantId={preFilteredTenantId} />
    </PageContainer>
  );
};

export default ManagerTenants;

// @flow

import { useEffect, useState } from 'react';
import { getTbFarmerCustomersData } from 'api/service/internal/ThingsboardApiService';
import { useDispatch } from 'react-redux';
import PageTitle from 'components/PageTitle';
import PageContainer from 'pages/common/PageContainer';
import CustomersDataGrid from 'components/internal/datagrid/farmer/CustomersDataGrid';
import { getChargeBeeCustomers, getChargeBeeSubscriptions } from 'api/service/internal/ChargeBeeApiService';

const FarmerAppCustomers = (): React$Node => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [chargeBeeCustomers, setChargeBeeCustomers] = useState([]);
  const [chargeBeeSubscriptions, setChargeBeeSubscriptions] = useState([]);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = () => {
    getTbFarmerCustomersData(dispatch).then(setItems);
    fetchChargeBeeCustomers();
    fetchSubscriptions();
  };

  const fetchSubscriptions = () => {
    getChargeBeeSubscriptions(dispatch).then((response) => setChargeBeeSubscriptions(response));
  };

  const fetchChargeBeeCustomers = () => {
    getChargeBeeCustomers(dispatch).then((response) => setChargeBeeCustomers(response));
  };

  return (
    <PageContainer>
      <PageTitle titleKey={'app.page.title.farmerApp.customers'} />
      <CustomersDataGrid
        items={items}
        fetchCustomers={fetchCustomers}
        chargeBeeCustomers={chargeBeeCustomers}
        chargeBeeSubscriptions={chargeBeeSubscriptions}
      />
    </PageContainer>
  );
};

export default FarmerAppCustomers;

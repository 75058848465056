// @flow

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import type { TbCustomerDataItem } from 'types/Farmer.types';
import { useIntl } from 'react-intl';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import Slide from '@mui/material/Slide';
import { useDispatch } from 'react-redux';
import { getTbFarmerAssetsFiltered } from 'api/service/internal/ThingsboardApiService';
import { TB_ASSETS_TYPE_VALUE_ENUM } from 'constants/internal/ThingsboardConstants';
import AssetsDataGrid from 'components/internal/datagrid/manager/AssetsDataGrid';

const Transition = React.forwardRef(function Transition(props: any, ref: any): React$Node {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: boolean,
  handleClose: Function,
  customer: TbCustomerDataItem
};

const BuildingsDialog = ({ open, handleClose, customer }: Props): React$Node => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  let customerId = customer?.customerDetails?.id?.id;
  const customerName = customer?.customerDetails?.name;
  const title = intl.formatMessage({ id: 'app.datagrid.buildings.dialog.title' }, { name: customerName });

  useEffect(() => {
    getTbFarmerAssetsFiltered(dispatch, TB_ASSETS_TYPE_VALUE_ENUM.BUILDING, customerId).then((response) =>
      setItems(response)
    );
  }, []);

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <AssetsDataGrid items={items} />
      </DialogContent>
    </Dialog>
  );
};

export default BuildingsDialog;

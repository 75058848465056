export const USER_ROLE_ENUM = {
  ADMIN: 'ROLE_ADMIN',
  INTERNAL_SUPPORT: 'ROLE_INT_SUPPORT',
  INTERNAL_USER: 'ROLE_INT_USER',
  EXTERNAL_USER: 'ROLE_EXT_USER'
};

export const VALIDATION_TOKEN_ENUM = {
  PASSWORD_RESET: 'PASSWORD_RESET',
  REGISTER_USER: 'REGISTER_USER',
  INVITE_USERS: 'INVITE_USERS'
};

export const USER_ATTRIBUTE_ENUM = {
  LONGITUDE: 'longitude',
  LATITUDE: 'latitude'
};

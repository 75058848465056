// @flow

import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';
import { DateTime } from 'luxon';
import { isDefined, isNotEmptyList } from 'util/ObjectUtils';
import Button from '@mui/material/Button';
import type { TbCustomerDataItem } from 'types/Farmer.types';
import { useDispatch, useSelector } from 'react-redux';
import { getFarmerAppUrlSetting } from 'store/selector/ApplicationSelector';
import type { TbUser, TbUserWithTelemetries } from 'types/Thingsboard.types';
import { TbAccessToken } from 'types/Thingsboard.types';
import { isAdministratorOrSupport } from 'store/selector/UserSelector';
import { getTbFarmerUserAccessToken } from 'api/service/internal/ThingsboardApiService';
import { copyToClipboard } from 'util/CommonUtils';
import { v4 as uuid } from 'uuid';
import { addAlert } from 'store/slice/ApplicationSlice';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

type Props = {
  open: boolean,
  handleClose: Function,
  customer: TbCustomerDataItem
};

const CustomerUsersDialog = ({ open, handleClose, customer }: Props): React$Node => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const farmerAppUrl = useSelector(getFarmerAppUrlSetting);
  const isAdminOrSupport = useSelector(isAdministratorOrSupport);

  let customerName = customer?.customerDetails?.name;
  const title = intl.formatMessage({ id: 'app.datagrid.users.dialog.title' }, { name: customerName });

  const usersWithTelemetries = customer?.usersWithTelemetries;

  const handleTbLogin = (user: TbUser) => {
    getTbFarmerUserAccessToken(dispatch, user.id.id).then((response: TbAccessToken) => {
      const encodedToken = encodeURIComponent(response?.token);
      const encodedRefreshToken = encodeURIComponent(response?.refreshToken);

      const authenticationUrl = `${farmerAppUrl}/?accessToken=${encodedToken}&refreshToken=${encodedRefreshToken}`;

      copyToClipboard(authenticationUrl);
      const alert = {
        id: uuid(),
        severity: 'info',
        message: intl.formatMessage({ id: 'app.alert.info.copiedToClipboard' })
      };
      dispatch(addAlert(alert));
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: '100%',
          p: 1
        }
      }}
    >
      <DialogTitle variant="h6" sx={{ fontWeight: 500, borderBottom: `1px solid ${grey[300]}` }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            pt: 2
          }}
        >
          {isNotEmptyList(usersWithTelemetries) ? (
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2, flexWrap: 'wrap' }}>
              {usersWithTelemetries?.map((userWithAttributes: TbUserWithTelemetries): React$Node => {
                let user = userWithAttributes.user;
                let userLastLogin = isDefined(user.lastLoginTs)
                  ? DateTime.fromMillis(user.lastLoginTs).toLocaleString(DateTime.DATETIME_MED)
                  : intl.formatMessage({ id: 'app.common.not.available' });
                return (
                  <Box
                    key={`customer-${customerName}-user-${user.id?.id}`}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      p: 1,
                      border: `1px solid ${grey[400]}`,
                      borderRadius: theme.spacing(1)
                    }}
                  >
                    <Box
                      key={`customer-${customerName}-user-${user.id?.id}`}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                          paddingRight: 1,
                          borderRight: `1px solid ${grey[300]}`
                        }}
                      >
                        <Typography variant="body2" sx={{ textAlign: 'right', fontWeight: 500 }}>
                          {intl.formatMessage({ id: 'app.datagrid.name' })}
                        </Typography>
                        <Typography variant="body2" sx={{ textAlign: 'right', fontWeight: 500 }}>
                          {intl.formatMessage({ id: 'app.datagrid.email' })}
                        </Typography>
                        <Typography variant="body2" sx={{ textAlign: 'right', fontWeight: 500 }}>
                          {intl.formatMessage({ id: 'app.datagrid.users.dialog.lastLogin' })}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', ml: 1 }}>
                        <Typography variant="body2" sx={{ textAlign: 'left', fontWeight: 300 }}>
                          {user.name}
                        </Typography>
                        <Typography variant="body2" sx={{ textAlign: 'left', fontWeight: 300 }}>
                          {user.email}
                        </Typography>
                        <Typography variant="body2" sx={{ textAlign: 'left', fontWeight: 300 }}>
                          {userLastLogin}
                        </Typography>
                      </Box>
                    </Box>
                    {isAdminOrSupport && (
                      <Button
                        variant="outlined"
                        onClick={() => handleTbLogin(user)}
                        endIcon={<ContentCopyOutlinedIcon />}
                        color="warning"
                        sx={{ mt: 1 }}
                      >
                        {intl.formatMessage({ id: 'app.customers.loginAPP' })}
                      </Button>
                    )}
                  </Box>
                );
              })}
            </Box>
          ) : (
            <Typography>{intl.formatMessage({ id: 'app.common.noData' })}</Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ pr: 1, pb: 0.5, pt: 1, borderTop: `1px solid ${grey[300]}` }}>
        <Button
          onClick={handleClose}
          variant="contained"
          sx={{
            backgroundColor: grey[500],
            color: '#fff',
            textTransform: 'none',
            ':hover': {
              backgroundColor: grey[700]
            }
          }}
        >
          {intl.formatMessage({ id: 'app.common.close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerUsersDialog;

// @flow
import { IntlShape } from 'react-intl';
import { GridRenderCellParams } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteOutline from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { blueGrey, green, red } from '@mui/material/colors';
import { Chip } from '@mui/material';
import { AuthorityRolesColorMapper } from 'mappers/colorMappers';
import { AuthoritiesTranslationId } from 'mappers/translationMappers';

export const createUsersColumns = (
  intl: IntlShape,
  handleActivation: Function,
  handleEdit: Function,
  handleDelete: Function
): Array<any> => {
  return [
    {
      field: 'orderNum',
      headerName: '#',
      width: 60,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const orderNumber = cellValues.row.orderNum;
        const id = cellValues.row.id;
        return (
          <Tooltip title={intl.formatMessage({ id: 'app.manageUsers.dataGrid.databaseId' }, { id: id })}>
            <Typography>{orderNumber}</Typography>
          </Tooltip>
        );
      }
    },
    {
      field: 'firstName',
      headerName: intl.formatMessage({ id: 'app.manageUsers.dataGrid.firstName' }),
      width: '300',
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        return (
          <Tooltip title={cellValues.row.firstName}>
            <Typography variant="body2">{cellValues.row.firstName}</Typography>
          </Tooltip>
        );
      }
    },
    {
      field: 'lastName',
      headerName: intl.formatMessage({ id: 'app.manageUsers.dataGrid.lastName' }),
      width: '300',
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        return (
          <Tooltip title={cellValues.row.lastName}>
            <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {cellValues.row.lastName}
            </Typography>
          </Tooltip>
        );
      }
    },
    {
      field: 'email',
      headerName: intl.formatMessage({ id: 'app.manageUsers.dataGrid.email' }),
      flex: 1,
      minWidth: 300,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        return (
          <Tooltip title={cellValues.row.email}>
            <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {cellValues.row.email}
            </Typography>
          </Tooltip>
        );
      }
    },
    {
      field: 'authorities',
      headerName: intl.formatMessage({ id: 'app.managesUsers.dataGrid.accessLevel' }),
      flex: 1,
      minWidth: 300,
      valueFormatter: (v: any): React$Node => {
        let authorities = v?.value;
        return authorities?.join(', ');
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let authorities = cellValues.row.authorities;

        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, p: 0.5 }}>
            {authorities?.map((authority) => {
              const color = AuthorityRolesColorMapper[authority];
              return (
                <Chip
                  key={authority}
                  size="small"
                  label={intl.formatMessage({ id: AuthoritiesTranslationId[authority] })}
                  variant="outlined"
                  sx={{ color: color, borderColor: color }}
                />
              );
            })}
          </Box>
        );
      }
    },
    {
      field: 'twoFactorEnabled',
      headerName: intl.formatMessage({ id: 'app.manageUsers.dataGrid.2faEnabled' }),
      flex: 1,
      minWidth: 100,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const isEnabled = cellValues.row.twoFactorEnabled;
        const color = isEnabled ? green[500] : red[500];
        return (
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ color }} variant="body2">{`${isEnabled}`}</Typography>
          </Box>
        );
      }
    },
    {
      field: 'actions',
      headerName: intl.formatMessage({ id: 'app.manageUsers.dataGrid.actions' }),
      width: 150,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const isActive = cellValues.row.activated;
        return (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Tooltip title={intl.formatMessage({ id: 'app.manageUsers.tooltip.edit' })}>
              <IconButton size="small" onClick={() => handleEdit(cellValues.row)}>
                <EditIcon size="small" sx={{ color: blueGrey[700] }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={intl.formatMessage({ id: 'app.manageUsers.tooltip.activation' })}>
              <IconButton size="small" onClick={() => handleActivation(cellValues.row)}>
                {isActive ? (
                  <LockOpenOutlinedIcon size="small" sx={{ color: green[500] }} />
                ) : (
                  <LockOutlinedIcon size="small" sx={{ color: red[500] }} />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title={intl.formatMessage({ id: 'app.manageUsers.tooltip.delete' })}>
              <IconButton size="small" onClick={(event) => handleDelete(event, cellValues.row.id)}>
                <DeleteOutline size="small" sx={{ color: red[700] }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      }
    }
  ];
};

// @flow

import { grey } from '@mui/material/colors';
import { Autocomplete, DialogActions, DialogContent, DialogTitle, Switch } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import type { TbAssetTelemetriesExtendedItem, TbCustomerItem } from 'types/Thingsboard.types';
import Box from '@mui/material/Box';
import { FARMER_DEVICE_TAB_NAVIGATION_ENUM } from 'constants/internal/DeviceConstants';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';
import { isDefined, isEmpty } from 'util/ObjectUtils';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { addAlert } from 'store/slice/ApplicationSlice';
import { DATA_GRID_DIALOG_TYPE } from 'constants/GlobalConstants';
import { GRAIN_ANALYZERS, TB_FARMER_DEVICE_TYPE_VALUE_ENUM } from 'constants/internal/ThingsboardConstants';
import TextField from '@mui/material/TextField';
import {
  assignDeviceToCustomer,
  getTbFarmerCustomers,
  unassignDeviceFromCustomer
} from 'api/service/internal/ThingsboardApiService';

type Props = {
  open: boolean,
  handleClose: Function,
  device: TbAssetTelemetriesExtendedItem,
  fetchDevices: Function,
  tabSelected?: String
};

const DeviceAssignmentDialog = ({ open, handleClose, device, fetchDevices, tabSelected }: Props): React$Node => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const deviceId = device?.assetDto?.id?.id;
  const customerId = device?.assetDto?.customerId?.id;

  const [customers, setCustomers] = useState([]);
  const [assigned, setAssigned] = useState(null);
  const [customerToAssign, setCustomerToAssign] = useState(null);

  useEffect(() => {
    if (isDefined(device?.customerName)) {
      setAssigned(true);
    } else {
      setAssigned(false);
    }
    getTbFarmerCustomers(dispatch).then((response) => {
      setCustomers(response);
      let assignedToCustomer = response?.find((customer) => customer?.id.id === customerId);
      setCustomerToAssign(assignedToCustomer ?? null);
    });
  }, []);

  const handleDialogClose = () => {
    setAssigned(null);
    setCustomerToAssign(null);
    handleClose();
  };

  const handleAssignToggle = () => {
    const assignedValue = assigned;
    setAssigned(!assignedValue);

    if (assignedValue) {
      setCustomerToAssign(null);
    }
  };

  const handleCustomerChange = (customer: TbCustomerItem) => {
    setCustomerToAssign(customer);
  };

  const assignmentOfDeviceApiCall = () => {
    if (assigned) {
      return assignDeviceToCustomer(dispatch, customerToAssign?.id?.id, deviceId);
    } else {
      return unassignDeviceFromCustomer(dispatch, deviceId);
    }
  };

  const handleSave = () => {
    assignmentOfDeviceApiCall()
      .then(() => {
        let alert = {
          id: uuid(),
          severity: 'success',
          title: intl.formatMessage({ id: 'app.common.success' }),
          message: intl.formatMessage({ id: 'app.alert.success.device.update' })
        };
        dispatch(addAlert(alert));
      })
      .then(() => {
        if (isDefined(tabSelected)) {
          let devicesToFetch = null;
          if (tabSelected === FARMER_DEVICE_TAB_NAVIGATION_ENUM.SENSOR_SPEAR) {
            devicesToFetch = [TB_FARMER_DEVICE_TYPE_VALUE_ENUM.SENSOR_SPEAR];
          } else if (tabSelected === DATA_GRID_DIALOG_TYPE.DEVICE_DIALOG_GRAIN_ANALYZERS) {
            devicesToFetch = GRAIN_ANALYZERS;
          }
          fetchDevices(devicesToFetch);
        } else {
          fetchDevices();
        }
      })
      .then(handleDialogClose);
  };

  const label = assigned
    ? intl.formatMessage({ id: 'app.devices.update.deviceAssigned' })
    : intl.formatMessage({ id: 'app.devices.update.deviceUnassigned' });

  const ResolvedContent = (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <FormControlLabel
        control={<Switch checked={assigned} onChange={handleAssignToggle} color="success" />}
        label={label}
      />
      {assigned && (
        <Autocomplete
          id="customers"
          options={customers}
          value={customerToAssign}
          getOptionLabel={(option) => option.name}
          onChange={(event, value) => handleCustomerChange(value)}
          sx={{ width: 300, mt: 1 }}
          size="small"
          renderInput={(params) => (
            <TextField {...params} label={intl.formatMessage({ id: 'app.devices.update.assignToCustomer' })} />
          )}
        />
      )}
    </Box>
  );

  const resolveIsSaveDisabled = () => {
    return assigned ? isEmpty(customerToAssign) : false;
  };

  const isSaveDisabled = resolveIsSaveDisabled();
  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperProps={{
        sx: {
          width: '500px',
          p: 1
        }
      }}
    >
      <DialogTitle variant="h6" sx={{ fontWeight: 400, borderBottom: `1px solid ${grey[300]}`, p: 1 }}>
        {intl.formatMessage({ id: 'app.devices.update.attributes' }, { name: device?.assetDto?.name })}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', pt: 2 }}>{ResolvedContent}</Box>
      </DialogContent>
      <DialogActions sx={{ pr: 1, pb: 0.5, pt: 1, borderTop: `1px solid ${grey[300]}` }}>
        <Button onClick={handleSave} variant="contained" size="small" disabled={isSaveDisabled}>
          {intl.formatMessage({ id: 'app.common.save' })}
        </Button>
        <Button
          onClick={handleDialogClose}
          variant="contained"
          size="small"
          sx={{
            backgroundColor: grey[500],
            color: '#fff',
            ':hover': {
              backgroundColor: grey[700]
            }
          }}
        >
          {intl.formatMessage({ id: 'app.common.close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeviceAssignmentDialog;

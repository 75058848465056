// @flow
import { isDefined } from 'util/ObjectUtils';
import { USER_ROLE_ENUM } from 'constants/UserConstants';
import type { RootStateType, UserProfile } from 'types/State.types';

export const getLoggedUser = (state: RootStateType): UserProfile => state.user?.profile;
export const isUserAuthenticated = (state: RootStateType): boolean =>
  state.user.authenticated && isDefined(getLoggedUser(state));

export const isAdministrator = (state: RootStateType): boolean => {
  let loggedUser = getLoggedUser(state);
  return isUserAuthenticated(state) && loggedUser.authorities?.includes(USER_ROLE_ENUM.ADMIN);
};

export const isAdministratorOrSupport = (state: RootStateType): boolean => {
  let loggedUser = getLoggedUser(state);
  return (
    isUserAuthenticated(state) &&
    (loggedUser.authorities?.includes(USER_ROLE_ENUM.ADMIN) ||
      loggedUser.authorities?.includes(USER_ROLE_ENUM.INTERNAL_SUPPORT))
  );
};

export const isSupport = (state: RootStateType): boolean => {
  let loggedUser = getLoggedUser(state);
  return isUserAuthenticated(state) && loggedUser.authorities?.includes(USER_ROLE_ENUM.INTERNAL_SUPPORT);
};

export const isInternalUser = (state: RootStateType): boolean => {
  let loggedUser = getLoggedUser(state);
  return (
    isUserAuthenticated(state) &&
    (loggedUser.authorities?.includes(USER_ROLE_ENUM.ADMIN) ||
      loggedUser.authorities?.includes(USER_ROLE_ENUM.INTERNAL_SUPPORT) ||
      loggedUser.authorities?.includes(USER_ROLE_ENUM.INTERNAL_USER))
  );
};

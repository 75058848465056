// @flow
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import WorldMapWrapper from 'components/internal/worldmap/WorldMapWrapper';
import type { TbLocationItem } from 'types/Thingsboard.types';
import { MAP_BOX_STYLE } from 'components/internal/worldmap/WorldMap';

type Props = {
  open: boolean,
  handleClose: Function,
  locationItems: Array<TbLocationItem>
};

const WorldMapDialog = ({ open, handleClose, locationItems }: Props): React$Node => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          minWidth: '70vw',
          height: '70vh'
        }
      }}
    >
      <DialogContent>
        <WorldMapWrapper items={locationItems} view={MAP_BOX_STYLE.SATELLITE} useStoredPosition={false} />
      </DialogContent>
    </Dialog>
  );
};

export default WorldMapDialog;

// @flow
import * as React from 'react';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useTreeItem2 } from '@mui/x-tree-view/useTreeItem2';
import { TreeItem2Checkbox, TreeItem2IconContainer } from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { TreeItem2DragAndDropOverlay } from '@mui/x-tree-view/TreeItem2DragAndDropOverlay';
import PageContainer from 'pages/common/PageContainer';
import {
  CustomLabel,
  CustomTreeItemContent,
  isExpandable,
  StyledTreeItemRoot,
  TransitionComponent
} from 'pages/internal/groups/GroupsHelper';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { Divider, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { IntlShape, useIntl } from 'react-intl';
import GroupDialog from 'components/internal/dialog/groups/GroupDialog';
import { GROUP_ACCESS_ROLE, GROUP_TREE_ITEM_ENUM } from 'constants/GroupConstants';
import Box from '@mui/material/Box';
import { deleteGroup, getAllGroups } from 'api/service/internal/AdminGroupApiService';
import { useDispatch, useSelector } from 'react-redux';
import type { Group, GroupUser } from 'types/Groups.types';
import { isDefined, isNotEmpty, isNotEmptyList } from 'util/ObjectUtils';
import MenuItem from '@mui/material/MenuItem';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Menu from '@mui/material/Menu';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AreYouSurePopover from 'components/common/AreYouSurePopover';
import { GroupRoleTranslationId } from 'mappers/translationMappers';
import { grey } from '@mui/material/colors';
import GroupUsersDialog from 'components/internal/dialog/groups/GroupUsersDialog';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import { getLoggedUser, isAdministrator } from 'store/selector/UserSelector';
import { deleteGroupForExternal, getAllGroupsForExternal } from 'api/service/external/GroupApiService';
import { getRandomInt } from 'util/NumberUtils';
import type { UserProfile } from 'types/State.types';
import { USER_ROLE_ENUM } from 'constants/UserConstants';
import { HolidayVillageOutlined, PhonelinkRingOutlined } from '@mui/icons-material';
import GroupTenantsDialog from 'components/internal/dialog/groups/GroupTenantsDialog';
import type { TbAssetItem, TbTenantMaster } from 'types/Thingsboard.types';
import GroupDevicesDialog from 'components/internal/dialog/groups/GroupDevicesDialog';
import { TreeItemIconMapper } from 'mappers/iconMappers';

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props: any, ref) {
  const { id, itemId, label, disabled, children, openActionMenu, ...other } = props;
  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getGroupTransitionProps,
    getDragAndDropOverlayProps,
    status,
    publicAPI
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  const item = publicAPI.getItem(itemId);
  const expandable = isExpandable(children);
  let icon;
  if (expandable) {
    icon = CategoryOutlinedIcon;
  } else if (item.type) {
    icon = TreeItemIconMapper[item.type];
  }

  const isGroup = item.type === GROUP_TREE_ITEM_ENUM.GROUP;

  const openActionMenuHandler = (event: Event) => {
    event.stopPropagation();
    openActionMenu(event, itemId);
  };

  return (
    <TreeItem2Provider itemId={itemId}>
      <StyledTreeItemRoot {...getRootProps(other)}>
        <CustomTreeItemContent
          {...getContentProps({
            className: clsx('content', {
              'Mui-expanded': status.expanded,
              'Mui-selected': status.selected,
              'Mui-focused': status.focused,
              'Mui-disabled': status.disabled
            })
          })}
        >
          <TreeItem2IconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <TreeItem2Checkbox {...getCheckboxProps()} />
          <CustomLabel
            {...getLabelProps({
              isGroup,
              openActionMenuHandler,
              icon,
              label2: item.label2,
              loggedUserRole: item.loggedUserRole,
              accessRole: item.accessRole,
              isManagingRole: item.isManagingRole,
              expandable: expandable && status.expanded
            })}
          />
          <TreeItem2DragAndDropOverlay {...getDragAndDropOverlayProps()} />
        </CustomTreeItemContent>
        {children && <TransitionComponent {...getGroupTransitionProps()} />}
      </StyledTreeItemRoot>
    </TreeItem2Provider>
  );
});

const resolveGroupItems = (groups: Array<Group>, intl: IntlShape, loggedUser: UserProfile) => {
  return groups?.map((group) => {
    let treeItem = {
      id: group.id.toString(),
      label: group.name,
      type: GROUP_TREE_ITEM_ENUM.GROUP
    };

    let loggedUserRole;
    let usersAccess = group.users;
    if (isNotEmptyList(usersAccess)) {
      const userItems = [];
      usersAccess.forEach((userWithAccess: GroupUser, index) => {
        if (userWithAccess.id === loggedUser?.id) {
          loggedUserRole = userWithAccess.groupAccessRole;
        }

        let randomInt = getRandomInt(0, 10000000);
        const id = `${userWithAccess.id}${randomInt}${index}`;
        userItems.push({
          id: id,
          label: `${userWithAccess?.firstName} ${userWithAccess.lastName}`,
          label2: `${intl.formatMessage({ id: 'app.manageGroups.role' })}: ${intl.formatMessage({
            id: GroupRoleTranslationId[userWithAccess.groupAccessRole]
          })}`,
          accessRole: userWithAccess.groupAccessRole,
          type: GROUP_TREE_ITEM_ENUM.USER
        });
      });

      treeItem = {
        ...treeItem,
        children: userItems
      };
    }

    let tenants = group.tenants;
    if (isNotEmptyList(tenants)) {
      const tenantItems = [];
      tenants.forEach((tenant: TbTenantMaster) => {
        let randomInt = getRandomInt(0, 10000000);
        const id = `${tenant.id.id}${randomInt}`;
        tenantItems.push({
          id: id,
          label: tenant.name,
          type: GROUP_TREE_ITEM_ENUM.TENANT
        });
      });

      treeItem = {
        ...treeItem,
        children: [...treeItem.children, ...tenantItems]
      };
    }

    let devices = group.devices;
    if (isNotEmptyList(devices)) {
      const deviceItems = [];
      devices.forEach((device: TbAssetItem) => {
        let randomInt = getRandomInt(0, 10000000);
        const id = `${device.id.id}${randomInt}`;
        deviceItems.push({
          id: id,
          label: device.name,
          type: GROUP_TREE_ITEM_ENUM.DEVICE
        });
      });

      treeItem = {
        ...treeItem,
        children: [...treeItem.children, ...deviceItems]
      };
    }

    let isManagingRole =
      loggedUser.authorities.includes(USER_ROLE_ENUM.ADMIN) ||
      loggedUserRole === GROUP_ACCESS_ROLE.GROUP_OWNER ||
      loggedUserRole === GROUP_ACCESS_ROLE.GROUP_ADMIN;
    treeItem = {
      ...treeItem,
      loggedUserRole: isDefined(loggedUserRole)
        ? intl.formatMessage({ id: GroupRoleTranslationId[loggedUserRole] })
        : null,
      isManagingRole: isManagingRole
    };

    let children = group.children;
    if (isNotEmptyList(children)) {
      let groupChildren = treeItem.children ?? [];
      treeItem = {
        ...treeItem,
        children: [...groupChildren, ...resolveGroupItems(children, intl, loggedUser)]
      };
    }

    return treeItem;
  });
};

const ManageGroups = (): React$Node => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const loggedUser = useSelector(getLoggedUser);

  const [groupDialogOpen, setGroupDialogOpen] = useState(false);
  const [groupUsersDialogOpen, setGroupUsersDialogOpen] = useState(false);
  const [groupTenantsDialogOpen, setGroupTenantsDialogOpen] = useState(false);
  const [groupDevicesDialogOpen, setGroupDevicesDialogOpen] = useState(false);

  const [treeItems, setTreeItems] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [parent, setParent] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [deleteAnchorEl, setDeleteAnchorEl] = useState(null);
  const deletePopoverOpen = Boolean(deleteAnchorEl);

  const isAdmin = useSelector(isAdministrator);
  const [isEditRole, setIsEditRole] = useState(isAdmin);
  const [isDeleteRole, setIsDeleteRole] = useState(false);

  useEffect(() => {
    fetchGroupsApiCall();
  }, []);

  useEffect(() => {
    const items = resolveGroupItems(groups, intl, loggedUser);
    setTreeItems(items);
  }, [groups, intl]);

  const fetchGroupsAsAdmin = () => {
    getAllGroups(dispatch).then((response) => {
      setGroups(response);
    });
  };

  const fetchGroupsAsExternal = () => {
    getAllGroupsForExternal(dispatch).then((response) => {
      setGroups(response);
    });
  };

  const openActionMenu = (event: Event, groupId: string) => {
    setSelectedGroupId(groupId);
    setAnchorEl(event.currentTarget);

    let groupToEdit = findGroup(groups, groupId);
    let groupUser = groupToEdit?.users?.find((user) => user.id === loggedUser?.id);
    if (isDefined(groupUser)) {
      let canEdit = isEditAllowed(groupUser);
      setIsEditRole(canEdit);

      let canDelete = isDeleteAllowed(groupUser);
      setIsDeleteRole(canDelete);
    }
  };

  const closeActionMenu = () => {
    setSelectedGroupId(null);
    setAnchorEl(null);
  };

  const handleDeletePopoverClose = () => {
    setDeleteAnchorEl(null);
  };

  const handleDeletePopoverOpen = (event: Event) => {
    setDeleteAnchorEl(event.currentTarget);
  };

  const handleDeletePopoverConfirm = () => {
    handleDeleteButtonClick();
    handleDeletePopoverClose();
    closeActionMenu();
  };

  const handleDeleteButtonClick = () => {
    deleteApiCall(selectedGroupId, dispatch).then(fetchGroupsApiCall);
  };

  const handleOpenGroupDialog = (isAdd: boolean) => {
    setGroupDialogOpen(true);
    if (isNotEmpty(selectedGroupId)) {
      let groupToEdit = findGroup(groups, selectedGroupId);
      if (isAdd) {
        setParent(groupToEdit);
      } else {
        setSelectedGroup(groupToEdit);
      }
      closeActionMenu();
    }
  };

  const findGroup = (groupList: Array<Group>, groupId: string) => {
    let groupToEdit;
    groupList?.forEach((group) => {
      if (group.id.toString() === groupId.toString()) {
        groupToEdit = group;
      } else {
        let children = group.children;
        let optionalGroup = findGroup(children, groupId);
        if (isDefined(optionalGroup)) {
          groupToEdit = optionalGroup;
        }
      }
    });

    return groupToEdit;
  };

  const handleCloseGroupDialog = () => {
    setGroupDialogOpen(false);
    setSelectedGroup(null);
    setParent(null);
  };

  const handleOpenGroupUsersDialog = () => {
    setGroupUsersDialogOpen(true);
    if (isNotEmpty(selectedGroupId)) {
      let groupToEdit = findGroup(groups, selectedGroupId);
      setSelectedGroup(groupToEdit);

      if (isNotEmpty(groupToEdit.parentId)) {
        let parentGroup = findGroup(groups, groupToEdit.parentId);
        setParent(parentGroup);
      }
    }
    closeActionMenu();
  };

  const handleCloseGroupUsersDialog = () => {
    setGroupUsersDialogOpen(false);
    setSelectedGroup(null);
    setParent(null);
  };

  const handleOpenGroupTenantsDialog = () => {
    setGroupTenantsDialogOpen(true);
    resolveGroupAndParent();
    closeActionMenu();
  };

  const handleCloseGroupTenantsDialog = () => {
    setGroupTenantsDialogOpen(false);
    setSelectedGroup(null);
  };

  const handleOpenGroupDevicesDialog = () => {
    setGroupDevicesDialogOpen(true);
    resolveGroupAndParent();
    closeActionMenu();
  };

  const handleCloseGroupDevicesDialog = () => {
    setGroupDevicesDialogOpen(false);
    setSelectedGroup(null);
  };

  const resolveGroupAndParent = () => {
    if (isNotEmpty(selectedGroupId)) {
      let groupToEdit = findGroup(groups, selectedGroupId);
      setSelectedGroup(groupToEdit);

      if (isNotEmpty(groupToEdit.parentId)) {
        let parentGroup = findGroup(groups, groupToEdit.parentId);
        setParent(parentGroup);
      }
    }
  };

  const isEditAllowed = (groupUser) => {
    return (
      groupUser.groupAccessRole === GROUP_ACCESS_ROLE.GROUP_ADMIN ||
      groupUser.groupAccessRole === GROUP_ACCESS_ROLE.GROUP_OWNER
    );
  };

  const isDeleteAllowed = (groupUser) => {
    return groupUser.groupAccessRole === GROUP_ACCESS_ROLE.GROUP_OWNER;
  };

  let deleteApiCall;
  let fetchGroupsApiCall;
  if (isAdmin) {
    deleteApiCall = deleteGroup;
    fetchGroupsApiCall = fetchGroupsAsAdmin;
  } else {
    deleteApiCall = deleteGroupForExternal;
    fetchGroupsApiCall = fetchGroupsAsExternal;
  }

  const isManageUserRole = isEditRole || isDeleteRole;
  return (
    <PageContainer>
      <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
        <Typography component="h1" variant="h5" sx={{ flexGrow: 1 }}>
          {intl.formatMessage({ id: 'app.page.title.groups' })}
        </Typography>
        {isAdmin && (
          <Button variant="contained" onClick={handleOpenGroupDialog}>
            {intl.formatMessage({ id: 'app.manageGroups.addGroup.root' })}
          </Button>
        )}
      </Stack>
      <Divider />
      <Box sx={{ mt: 2, display: 'flex', gap: 2, flexWrap: 'wrap', pb: 1 }}>
        {treeItems?.map((item) => (
          <Box
            key={`group-${Math.random}`}
            sx={{
              border: `1px solid ${grey[400]}`,
              borderRadius: 1,
              height: 'fit-content',
              width: 500,
              overflowY: 'auto',
              p: 1
            }}
          >
            <RichTreeView
              items={[item] ?? []}
              slots={{ item: CustomTreeItem }}
              slotProps={{
                item: {
                  openActionMenu: openActionMenu
                }
              }}
            />
          </Box>
        ))}
        <Menu
          open={open}
          onClose={closeActionMenu}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          {!isManageUserRole && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pl: 1, pr: 1 }}>
              <Typography fontSize="small" sx={{ color: grey[500] }}>
                {intl.formatMessage({ id: 'app.manageGroups.insufficientPermissions' })}
              </Typography>
            </Box>
          )}
          {isEditRole && (
            <Box>
              <MenuItem onClick={() => handleOpenGroupDialog(true)}>
                <AddOutlinedIcon fontSize="small" />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {intl.formatMessage({ id: 'app.manageGroups.addGroup' })}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleOpenGroupDialog(false)}>
                <EditOutlinedIcon fontSize="small" />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {intl.formatMessage({ id: 'app.manageGroups.editGroup' })}
                </Typography>
              </MenuItem>
            </Box>
          )}
          {isDeleteRole && (
            <MenuItem onClick={(event: Event) => handleDeletePopoverOpen(event)}>
              <DeleteOutlineOutlinedIcon fontSize="small" />
              <Typography variant="body2" sx={{ ml: 1 }}>
                {intl.formatMessage({ id: 'app.manageGroups.deleteGroup' })}
              </Typography>
            </MenuItem>
          )}
          {isEditRole && (
            <Box>
              <Divider />
              <MenuItem onClick={handleOpenGroupUsersDialog}>
                <PeopleOutlineOutlinedIcon fontSize="small" />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {intl.formatMessage({ id: 'app.common.manageAccess' })}
                </Typography>
              </MenuItem>
            </Box>
          )}
          {isAdmin && (
            <Box>
              <MenuItem onClick={handleOpenGroupTenantsDialog}>
                <HolidayVillageOutlined fontSize="small" />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {intl.formatMessage({ id: 'app.manageGroups.linkTenants' })}
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleOpenGroupDevicesDialog}>
                <PhonelinkRingOutlined fontSize="small" />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {intl.formatMessage({ id: 'app.manageGroups.linkDevices' })}
                </Typography>
              </MenuItem>
            </Box>
          )}
        </Menu>
        <AreYouSurePopover
          open={deletePopoverOpen}
          anchorEl={deleteAnchorEl}
          onClose={handleDeletePopoverClose}
          onConfirm={handleDeletePopoverConfirm}
        />
      </Box>
      {groupDialogOpen && (
        <GroupDialog
          open
          handleClose={handleCloseGroupDialog}
          group={selectedGroup}
          parent={parent}
          onSuccessCallback={fetchGroupsApiCall}
        />
      )}
      {groupUsersDialogOpen && (
        <GroupUsersDialog
          open
          handleClose={handleCloseGroupUsersDialog}
          group={selectedGroup}
          parent={parent}
          onSuccessCallback={fetchGroupsApiCall}
        />
      )}
      {groupTenantsDialogOpen && (
        <GroupTenantsDialog
          open
          handleClose={handleCloseGroupTenantsDialog}
          parent={parent}
          group={selectedGroup}
          onSuccessCallback={fetchGroupsApiCall}
        />
      )}
      {groupDevicesDialogOpen && (
        <GroupDevicesDialog
          open
          handleClose={handleCloseGroupDevicesDialog}
          parent={parent}
          group={selectedGroup}
          onSuccessCallback={fetchGroupsApiCall}
        />
      )}
    </PageContainer>
  );
};

export default ManageGroups;

// @flow
import { Route } from 'react-router-dom';
import { Routes } from 'react-router';

import GeneralOverview from 'pages/internal/general/GeneralOverview';
import PrivateRoute from 'routing/PrivateRoute';
import {
  INTERNAL_ADMINISTRATION_SERVICES,
  INTERNAL_ADMINISTRATION_USERS,
  INTERNAL_ANALYTICS,
  COMPLETE_REGISTRATION,
  INTERNAL_FARMER_APP,
  INTERNAL_FARMER_APP_CUSTOMERS,
  INTERNAL_FARMER_APP_DEVICES,
  INTERNAL_GENERAL,
  INTERNAL_GENERAL_NODES_MAP,
  INTERNAL_GENERAL_NODES_MAP_FARMER,
  INTERNAL_GENERAL_NODES_MAP_MANAGER,
  INTERNAL_GENERAL_OVERVIEW,
  LOGIN,
  INTERNAL_MANAGER,
  INTERNAL_MANAGER_GATEWAYS,
  INTERNAL_MANAGER_SUBSCRIPTIONS,
  INTERNAL_MANAGER_TENANTS,
  PUBLIC_WORLD_MAP,
  REGISTER_USER,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
  INTERNAL_SERVICE_OVERVIEW,
  INTERNAL_SERVICE_STATUS_APP,
  INTERNAL_SERVICE_STATUS_MANAGER,
  EXTERNAL_GENERAL,
  EXTERNAL_GENERAL_OVERVIEW,
  INTERNAL_MANAGE_GROUPS,
  EXTERNAL_MANAGE_GROUPS
} from 'routing/RouteConstants';
import PublicRoute from 'routing/PublicRoute';
import Login from 'pages/Login';
import ResetPassword from 'pages/ResetPassword';
import RequestResetPassword from 'pages/RequestResetPassword';
import PageNotFound from 'pages/PageNotFound';
import AdminUsers from 'pages/internal/admin/AdminUsers';
import RegisterUser from 'pages/RegisterUser';
import CompleteRegistration from 'pages/CompleteRegistration';
import { useSelector } from 'react-redux';
import { getInvitationEnabledSetting, getRegistrationEnabledSetting } from 'store/selector/ApplicationSelector';
import 'mapbox-gl/dist/mapbox-gl.css';
import General from 'pages/internal/general/General';
import ManagerTenants from 'pages/internal/manager/ManagerTenants';
import ManagerGateways from 'pages/internal/manager/ManagerGateways';
import Manager from 'pages/internal/manager/Manager';
import FarmerApp from 'pages/internal/farmerapp/FarmerApp';
import FarmerAppCustomers from 'pages/internal/farmerapp/FarmerAppCustomers';
import FarmerAppDevices from 'pages/internal/farmerapp/FarmerAppDevices';
import GeneralNodesGraph from 'pages/internal/general/GeneralNodesGraph';
import AdminServices from 'pages/internal/admin/AdminServices';
import GeneralManagerNodesGraph from 'pages/internal/general/GeneralManagerNodesGraph';
import GeneralFarmerNodesGraph from 'pages/internal/general/GeneralFarmerNodesGraph';
import ServiceOverview from 'pages/internal/serviceOverview/ServiceOverview';
import ServiceStatusManager from 'pages/internal/serviceOverview/ServiceStatusManager';
import ServiceStatusApp from 'pages/internal/serviceOverview/ServiceStatusApp';
import Analytics from 'pages/internal/analytics/Analytics';
import ManagerSubscriptions from 'pages/internal/manager/ManagerSubscriptions';
import PublicWorldMapWrapper from 'components/internal/worldmap/PublicWorldMapWrapper';
import ExternalGeneral from 'pages/external/ExternalGeneral';
import ExternalGeneralOverview from 'pages/external/ExternalGeneralOverview';
import ManageGroups from 'pages/internal/groups/ManageGroups';

const App = (): React$Node => {
  const registrationEnabled = useSelector(getRegistrationEnabledSetting);
  const invitationEnabled = useSelector(getInvitationEnabledSetting);
  return (
    <Routes>
      <Route
        path={INTERNAL_GENERAL}
        element={
          <PrivateRoute internalRestricted>
            <General />
          </PrivateRoute>
        }
      >
        <Route path={INTERNAL_GENERAL_OVERVIEW} element={<GeneralOverview />} />
        <Route path={INTERNAL_GENERAL_NODES_MAP} element={<GeneralNodesGraph />}>
          <Route path={INTERNAL_GENERAL_NODES_MAP_MANAGER} element={<GeneralManagerNodesGraph />} />
          <Route path={INTERNAL_GENERAL_NODES_MAP_FARMER} element={<GeneralFarmerNodesGraph />} />
        </Route>
      </Route>
      <Route
        path={INTERNAL_SERVICE_OVERVIEW}
        element={
          <PrivateRoute internalRestricted>
            <ServiceOverview />
          </PrivateRoute>
        }
      >
        <Route path={INTERNAL_SERVICE_STATUS_APP} element={<ServiceStatusApp />} />
        <Route path={INTERNAL_SERVICE_STATUS_MANAGER} element={<ServiceStatusManager />} />
      </Route>
      <Route
        path={INTERNAL_MANAGER}
        element={
          <PrivateRoute internalRestricted>
            <Manager />
          </PrivateRoute>
        }
      >
        <Route path={INTERNAL_MANAGER_TENANTS} element={<ManagerTenants />} />
        <Route path={INTERNAL_MANAGER_SUBSCRIPTIONS} element={<ManagerSubscriptions />} />
        <Route path={INTERNAL_MANAGER_GATEWAYS} element={<ManagerGateways />} />
      </Route>
      <Route
        path={INTERNAL_FARMER_APP}
        element={
          <PrivateRoute internalRestricted>
            <FarmerApp />
          </PrivateRoute>
        }
      >
        <Route path={INTERNAL_FARMER_APP_CUSTOMERS} element={<FarmerAppCustomers />} />
        <Route path={INTERNAL_FARMER_APP_DEVICES} element={<FarmerAppDevices />} />
      </Route>
      <Route
        path={INTERNAL_MANAGE_GROUPS}
        element={
          <PrivateRoute internalRestricted>
            <ManageGroups />
          </PrivateRoute>
        }
      />
      <Route
        path={INTERNAL_ANALYTICS}
        element={
          <PrivateRoute internalRestricted>
            <Analytics />
          </PrivateRoute>
        }
      />
      <Route
        path={INTERNAL_ADMINISTRATION_USERS}
        element={
          <PrivateRoute restricted>
            <AdminUsers />
          </PrivateRoute>
        }
      />
      <Route
        path={INTERNAL_ADMINISTRATION_SERVICES}
        element={
          <PrivateRoute restricted>
            <AdminServices />
          </PrivateRoute>
        }
      />
      <Route
        path={EXTERNAL_GENERAL}
        element={
          <PrivateRoute>
            <ExternalGeneral />
          </PrivateRoute>
        }
      >
        <Route path={EXTERNAL_GENERAL_OVERVIEW} element={<ExternalGeneralOverview />} />
      </Route>
      <Route
        path={EXTERNAL_MANAGE_GROUPS}
        element={
          <PrivateRoute>
            <ManageGroups />
          </PrivateRoute>
        }
      />
      <Route
        path={LOGIN}
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path={COMPLETE_REGISTRATION}
        element={
          <PublicRoute>
            <CompleteRegistration />
          </PublicRoute>
        }
      />
      <Route
        path={RESET_PASSWORD}
        element={
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        }
      />
      <Route
        path={REQUEST_RESET_PASSWORD}
        element={
          <PublicRoute>
            <RequestResetPassword />
          </PublicRoute>
        }
      />
      <Route
        path={PUBLIC_WORLD_MAP}
        element={
          <PublicRoute noRedirect withLogo={false}>
            <PublicWorldMapWrapper />
          </PublicRoute>
        }
      />
      {registrationEnabled || invitationEnabled ? (
        <Route
          path={REGISTER_USER}
          element={
            <PublicRoute>
              <RegisterUser />
            </PublicRoute>
          }
        />
      ) : null}
      <Route path={'*'} element={<PageNotFound />} />
    </Routes>
  );
};

export default App;

// @flow

import PageTitle from 'components/PageTitle';
import PageContainer from 'pages/common/PageContainer';
import Box from '@mui/material/Box';
import { Tab, Tabs } from '@mui/material';
import { useIntl } from 'react-intl';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { INTERNAL_GENERAL_NODES_MAP_FARMER, INTERNAL_GENERAL_NODES_MAP_MANAGER } from 'routing/RouteConstants';

const GeneralNodesGraph = (): React$Node => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(newValue);
  };

  return (
    <PageContainer>
      <PageTitle titleKey={'app.page.title.general.nodesMap'} />
      <Box component="main" sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Tabs value={location.pathname} onChange={handleTabChange}>
          <Tab
            value={INTERNAL_GENERAL_NODES_MAP_MANAGER}
            label={intl.formatMessage({ id: 'app.general.nodeMap.tab.manager' })}
            id="tab-item-manager-nodes"
          />
          <Tab
            value={INTERNAL_GENERAL_NODES_MAP_FARMER}
            label={intl.formatMessage({ id: 'app.general.nodeMap.tab.farmer' })}
            id="tab-item-farmer-nodes"
          />
        </Tabs>
        <Box sx={{ width: '100%', height: '75vh', display: 'flex', mt: 2, position: 'relative' }}>
          <Outlet />
        </Box>
      </Box>
    </PageContainer>
  );
};

export default GeneralNodesGraph;

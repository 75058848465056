// @flow

import { Autocomplete, DialogActions, DialogContent, DialogTitle, InputAdornment } from '@mui/material';
import { grey } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { DatePicker, DateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import Button from '@mui/material/Button';
import { createNewTenant } from 'api/service/internal/ThingsboardApiService';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { addAlert } from 'store/slice/ApplicationSlice';
import { isDefined, isNotEmpty } from 'util/ObjectUtils';
import { validateCreateTenantForm } from 'validators/Tenant.validator';
import { getFieldErrorMessage, hasFieldError, resolveBackendValidationErrors } from 'util/ValidationUtils';
import { ValidationError } from 'yup';
import { generatePassword } from 'util/ThingsboardUtils';
import { MANAGER_TENANT_ATTRIBUTE_ENUM } from 'constants/internal/TenantConstants';
import { updateTelemetryAttribute } from 'api/service/internal/ThingsboardManagerTelemetryService';
import type { OdooTenant } from 'types/Odoo.types';
import IconButton from '@mui/material/IconButton';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const TENANT_MASTER_DETAILS_FIELDS_ENUM = {
  DESCRIPTION: 'description',
  TENANT_ADMIN_USERNAME: 'tenantAdminUsername',
  TENANT_USER_PASSWORD: 'tenantUserPassword',
  CUSTOMER_USER_USERNAME: 'customerUserUsername',
  CUSTOMER_USER_PASSWORD: 'customerUserPassword',
  END_PAYMENT_DATE: 'endPaymentDate',
  DAYS_BEFORE_WARNING: 'daysBeforeWarning',
  TENANT_TITLE: 'tenantTitle'
};

const defaultTenantMasterObject = {
  description: '',
  tenantAdminUsername: '',
  tenantUserPassword: '',
  customerUserUsername: '',
  customerUserPassword: '',
  endPaymentDate: null,
  daysBeforeWarning: 30,
  tenantTitle: '',
  productionDate: null,
  batchNumber: ''
};

type Props = {
  open: boolean,
  handleClose: Function,
  fetchTenants: Function,
  fetchTenantsWithTelemetries: Function,
  odooTenants: Array<OdooTenant>
};

const CreateNewTenantDialog = ({
  open,
  handleClose,
  fetchTenants,
  fetchTenantsWithTelemetries,
  odooTenants
}: Props): React$Node => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [tenantMasterState, setTenantMasterState] = useState(defaultTenantMasterObject);
  const [productionDate, setProductionDate] = useState(null);
  const [batchNumber, setBatchNumber] = useState('');
  const [fieldErrors, setFieldErrors] = useState([]);

  const [selectedOdooTenant, setSelectedOdooTenant] = useState(null);
  const [isOnPremiseTenant, setIsOnPremiseTenant] = useState(false);

  useEffect(() => {
    generatePasswords();
  }, []);

  useEffect(() => {
    if (isOnPremiseTenant) {
      let shutoutDate = DateTime.fromObject({ year: 2100 });
      handleDateChange(shutoutDate, TENANT_MASTER_DETAILS_FIELDS_ENUM.END_PAYMENT_DATE);
    }
  }, [isOnPremiseTenant]);

  useEffect(() => {
    let tenantName = selectedOdooTenant?.name;
    if (isNotEmpty(tenantName)) {
      const parts = tenantName?.split('-');
      let dateString = parts[1];
      const year = parseInt(`20${dateString.substring(4)}`, 10);
      const month = parseInt(dateString.substring(2, 4), 10);
      const day = parseInt(dateString.substring(0, 2), 10);
      let dateTime = DateTime.utc(year, month, day);

      if (dateTime?.invalid === null) {
        setProductionDate(dateTime);
      }

      let batchNumberString = parts[2];
      let batchNumberInt = parseInt(batchNumberString, 10);
      setBatchNumber(batchNumberInt);
    }

    if (isNotEmpty(selectedOdooTenant?.proposedShutoutDate) && selectedOdooTenant?.proposedShutoutDate !== 'false') {
      let proposedShutoutDate = selectedOdooTenant.proposedShutoutDate;
      let dateTime = DateTime.fromISO(proposedShutoutDate);
      handleDateChange(dateTime, TENANT_MASTER_DETAILS_FIELDS_ENUM.END_PAYMENT_DATE);
    } else {
      handleDateChange(null, TENANT_MASTER_DETAILS_FIELDS_ENUM.END_PAYMENT_DATE);
    }
  }, [selectedOdooTenant]);

  const generatePasswords = (field: string) => {
    let tenantUserPassword = generatePassword();
    let customerUserPassword = generatePassword();
    if (field === TENANT_MASTER_DETAILS_FIELDS_ENUM.TENANT_USER_PASSWORD) {
      setTenantMasterState((oldData) => ({
        ...oldData,
        tenantUserPassword: tenantUserPassword
      }));
    } else if (field === TENANT_MASTER_DETAILS_FIELDS_ENUM.CUSTOMER_USER_PASSWORD) {
      setTenantMasterState((oldData) => ({
        ...oldData,
        customerUserPassword: customerUserPassword
      }));
    } else {
      setTenantMasterState((oldData) => ({
        ...oldData,
        tenantUserPassword: tenantUserPassword,
        customerUserPassword: customerUserPassword
      }));
    }
  };

  useEffect(() => {
    if (isNotEmpty(productionDate) && isNotEmpty(batchNumber) && !isDefined(productionDate?.invalid)) {
      const dayLabel = productionDate?.day < 10 ? `0${productionDate?.day}` : productionDate?.day;
      const monthLabel = productionDate?.month < 10 ? `0${productionDate?.month}` : productionDate?.month;
      const yearLabel = productionDate?.year?.toString().substring(2);
      const dateStringFormat = `${dayLabel}${monthLabel}${yearLabel}`;
      const batchNumberLabel = batchNumber < 10 ? `0${batchNumber}` : batchNumber;

      // construct user emails
      const tenantAdminEmail = `TN-${dateStringFormat}-${batchNumberLabel}_admin@supertech.dk`;
      const customerUserEmail = `TN-${dateStringFormat}-${batchNumberLabel}_user@supertech.dk`;

      setTenantMasterState((oldData) => ({
        ...oldData,
        tenantAdminUsername: tenantAdminEmail,
        customerUserUsername: customerUserEmail
      }));
    } else {
      setTenantMasterState((oldData) => ({
        ...oldData,
        tenantAdminUsername: '',
        customerUserUsername: ''
      }));
    }
  }, [productionDate, batchNumber]);

  useEffect(() => {
    if (isNotEmpty(tenantMasterState?.tenantAdminUsername)) {
      const tenantTitle = tenantMasterState?.tenantAdminUsername?.split('@')[0]?.replace('_admin', '');
      setTenantMasterState((oldData) => ({
        ...oldData,
        tenantTitle: tenantTitle
      }));
    } else {
      setTenantMasterState((oldData) => ({
        ...oldData,
        tenantTitle: ''
      }));
    }
  }, [tenantMasterState?.tenantAdminUsername]);

  const createTenant = () => {
    const formattedTenantData = {
      ...tenantMasterState,
      endPaymentDate: isNotEmpty(tenantMasterState?.endPaymentDate)
        ? tenantMasterState.endPaymentDate.toMillis()
        : undefined,
      daysBeforeWarning: isNotEmpty(tenantMasterState?.daysBeforeWarning)
        ? parseInt(tenantMasterState.daysBeforeWarning, 10)
        : undefined,
      productionDate: isNotEmpty(productionDate) ? productionDate.toMillis() : undefined,
      batchNumber: isNotEmpty(batchNumber) ? parseInt(batchNumber, 10) : undefined
    };

    const odooBody = JSON.stringify({
      [MANAGER_TENANT_ATTRIBUTE_ENUM.ODOO_TENANT]: selectedOdooTenant?.id ?? ''
    });

    const onPremiseBody = JSON.stringify({
      [MANAGER_TENANT_ATTRIBUTE_ENUM.IS_ON_PREMISE]: isOnPremiseTenant
    });

    validateCreateTenantForm(formattedTenantData)
      .then(() => {
        setFieldErrors([]);
        createNewTenant(dispatch, formattedTenantData)
          .then((response) => {
            if (isDefined(response)) {
              const updateOdooTenantObject = {
                entityId: response?.infoAssetId?.id,
                entityType: response?.infoAssetId?.entityType,
                body: odooBody
              };
              updateTelemetryAttribute(dispatch, updateOdooTenantObject);

              const updateOnPremiseObject = {
                entityId: response?.infoAssetId?.id,
                entityType: response?.infoAssetId?.entityType,
                body: onPremiseBody
              };
              updateTelemetryAttribute(dispatch, updateOnPremiseObject);
            }
          })
          .then(() => {
            let alert = {
              id: uuid(),
              severity: 'success',
              title: intl.formatMessage({ id: 'app.common.success' }),
              message: intl.formatMessage({ id: 'app.datagrid.tenant.createNewTenant.success' })
            };
            dispatch(addAlert(alert));
          })
          .then(handleCloseDialog)
          .then(fetchTenants)
          .then(fetchTenantsWithTelemetries)
          .catch((error: any) => {
            setFieldErrors(resolveBackendValidationErrors(error));
          });
      })
      .catch((validationResult: ValidationError) => {
        setFieldErrors(validationResult?.inner ?? []);
      });
  };

  const handleCloseDialog = () => {
    setTenantMasterState(defaultTenantMasterObject);
    setProductionDate(null);
    setBatchNumber('');
    setSelectedOdooTenant(null);
    handleClose();
  };

  const handleOdooTenantChange = (value: OdooTenant) => {
    let odooTenant = odooTenants?.find((odooTenant) => odooTenant.id === value?.id);
    setSelectedOdooTenant(odooTenant);

    setTenantMasterState((oldData) => ({
      ...oldData,
      [TENANT_MASTER_DETAILS_FIELDS_ENUM.DESCRIPTION]:
        isDefined(odooTenant?.description) && odooTenant?.description !== 'false' ? odooTenant?.description : ''
    }));
  };

  const handleTextFieldChanges = (event: Event, field: string) => {
    setTenantMasterState((oldData) => ({ ...oldData, [field]: event.target.value }));
  };

  const handleProductionDateChange = (newValue: DateTime) => {
    setProductionDate(newValue);
  };

  const handleBatchNumberChange = (event: Event) => {
    setBatchNumber(event.target.value);
  };

  const handleDateChange = (newValue: DateTime, field: string) => {
    setTenantMasterState((oldData) => ({
      ...oldData,
      [field]: newValue
    }));
  };

  const handleOnPremiseChange = (event: Event) => {
    const checked = event.target.checked;
    setIsOnPremiseTenant(checked);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        sx: {
          minWidth: '50vw',
          p: 1
        }
      }}
    >
      <DialogTitle variant="h6" sx={{ fontWeight: 400, borderBottom: `1px solid ${grey[300]}`, p: 1 }}>
        {intl.formatMessage({ id: 'app.datagrid.tenant.createNewTenant' })}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            pt: 2,
            gap: 2
          }}
        >
          <Autocomplete
            id="odooTenants"
            options={odooTenants ?? []}
            value={selectedOdooTenant ?? null}
            getOptionLabel={(option) => option?.name ?? ''}
            renderOption={(props, option, index) => {
              const key = `odooTenant-${index}-${option.id}`;
              return (
                <li {...props} key={key}>
                  {option?.name}
                </li>
              );
            }}
            onChange={(event, value) => handleOdooTenantChange(value)}
            sx={{ width: '45%' }}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={intl.formatMessage({ id: 'app.datagrid.subscriptions.odooLink.dialog.label' })}
              />
            )}
          />
          <TextField
            id="tenantTitle"
            variant="standard"
            size="small"
            margin="normal"
            fullWidth
            sx={{ mt: 0, mb: 0, width: '45%' }}
            label={intl.formatMessage({ id: 'app.datagrid.subscriptions.subscription.tenantNumber.dialog' })}
            value={tenantMasterState?.tenantTitle}
            onChange={(event) => handleTextFieldChanges(event, TENANT_MASTER_DETAILS_FIELDS_ENUM.TENANT_TITLE)}
            disabled
            error={hasFieldError('tenantTitle', fieldErrors)}
            helperText={getFieldErrorMessage(intl, 'tenantTitle', fieldErrors)}
          />
          <DatePicker
            id="productionDate"
            label={intl.formatMessage({ id: 'app.datagrid.tenant.dialog.productionDate' })}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock
            }}
            maxDate={DateTime.now()}
            format="dd/MM/yyyy"
            value={productionDate}
            onChange={handleProductionDateChange}
            sx={{ width: '45%' }}
            slotProps={{
              textField: {
                size: 'small',
                variant: 'standard',
                width: '45%',
                error: hasFieldError('productionDate', fieldErrors),
                helperText: getFieldErrorMessage(intl, 'productionDate', fieldErrors),
                required: true
              },
              field: {
                readOnly: true
              }
            }}
          />
          <TextField
            type="number"
            id="batchNumber"
            variant="standard"
            size="small"
            margin="normal"
            fullWidth
            sx={{ mt: 0, mb: 0, width: '45%' }}
            required
            label={intl.formatMessage({ id: 'app.datagrid.tenant.dialog.batchNumber' })}
            value={batchNumber}
            onChange={handleBatchNumberChange}
            error={hasFieldError('batchNumber', fieldErrors)}
            helperText={getFieldErrorMessage(intl, 'batchNumber', fieldErrors)}
          />
          <TextField
            id="description"
            variant="standard"
            size="small"
            margin="normal"
            fullWidth
            sx={{ mt: 0, mb: 0, width: '100%' }}
            label={intl.formatMessage({ id: 'app.datagrid.tenant.dialog.description' })}
            value={tenantMasterState?.description}
            onChange={(event: Event) => handleTextFieldChanges(event, TENANT_MASTER_DETAILS_FIELDS_ENUM.DESCRIPTION)}
            error={hasFieldError('description', fieldErrors)}
            helperText={getFieldErrorMessage(intl, 'description', fieldErrors)}
          />
          <TextField
            id="tenantAdminUsername"
            variant="standard"
            size="small"
            margin="normal"
            fullWidth
            sx={{ mt: 0, mb: 0, width: '45%' }}
            required
            label={intl.formatMessage({ id: 'app.datagrid.tenant.dialog.userAccess.tenant.username' })}
            value={tenantMasterState?.tenantAdminUsername}
            onChange={(event: Event) =>
              handleTextFieldChanges(event, TENANT_MASTER_DETAILS_FIELDS_ENUM.TENANT_ADMIN_USERNAME)
            }
            error={hasFieldError('tenantAdminUsername', fieldErrors)}
            helperText={getFieldErrorMessage(intl, 'tenantAdminUsername', fieldErrors)}
          />
          <TextField
            id="tenantUserPassword"
            variant="standard"
            size="small"
            margin="normal"
            fullWidth
            sx={{ mt: 0, mb: 0, width: '45%' }}
            required
            label={intl.formatMessage({ id: 'app.datagrid.tenant.dialog.userAccess.tenant.password' })}
            value={tenantMasterState?.tenantUserPassword}
            onChange={(event: Event) =>
              handleTextFieldChanges(event, TENANT_MASTER_DETAILS_FIELDS_ENUM.TENANT_USER_PASSWORD)
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={intl.formatMessage({ id: 'app.datagrid.tenant.createNewTenant.password.generate' })}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => generatePasswords(TENANT_MASTER_DETAILS_FIELDS_ENUM.TENANT_USER_PASSWORD)}
                      edge="end"
                    >
                      <CachedOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
            error={hasFieldError('tenantUserPassword', fieldErrors)}
            helperText={getFieldErrorMessage(intl, 'tenantUserPassword', fieldErrors)}
          />
          <TextField
            id="customerUserUsername"
            variant="standard"
            size="small"
            margin="normal"
            fullWidth
            sx={{ mt: 0, mb: 0, width: '45%' }}
            required
            label={intl.formatMessage({ id: 'app.datagrid.tenant.dialog.userAccess.customer.username' })}
            value={tenantMasterState?.customerUserUsername}
            onChange={(event: Event) =>
              handleTextFieldChanges(event, TENANT_MASTER_DETAILS_FIELDS_ENUM.CUSTOMER_USER_USERNAME)
            }
            error={hasFieldError('customerUserUsername', fieldErrors)}
            helperText={getFieldErrorMessage(intl, 'customerUserUsername', fieldErrors)}
          />
          <TextField
            id="customerUserPassword"
            variant="standard"
            size="small"
            margin="normal"
            fullWidth
            sx={{ mt: 0, mb: 0, width: '45%' }}
            required
            label={intl.formatMessage({ id: 'app.datagrid.tenant.dialog.userAccess.customer.password' })}
            value={tenantMasterState?.customerUserPassword}
            onChange={(event: Event) =>
              handleTextFieldChanges(event, TENANT_MASTER_DETAILS_FIELDS_ENUM.CUSTOMER_USER_PASSWORD)
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={intl.formatMessage({ id: 'app.datagrid.tenant.createNewTenant.password.generate' })}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => generatePasswords(TENANT_MASTER_DETAILS_FIELDS_ENUM.CUSTOMER_USER_PASSWORD)}
                      edge="end"
                    >
                      <CachedOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
            error={hasFieldError('customerUserPassword', fieldErrors)}
            helperText={getFieldErrorMessage(intl, 'customerUserPassword', fieldErrors)}
          />
          <DateTimePicker
            id="endPaymentDate"
            label={intl.formatMessage({ id: 'app.datagrid.tenant.dialog.subscription.end' })}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock
            }}
            value={tenantMasterState?.endPaymentDate}
            onChange={(value: DateTime) => handleDateChange(value, TENANT_MASTER_DETAILS_FIELDS_ENUM.END_PAYMENT_DATE)}
            sx={{ width: '45%' }}
            slotProps={{
              textField: {
                size: 'small',
                variant: 'standard',
                width: '45%',
                error: hasFieldError('endPaymentDate', fieldErrors),
                helperText: getFieldErrorMessage(intl, 'endPaymentDate', fieldErrors),
                required: true
              },
              field: {
                readOnly: true
              }
            }}
          />
          <TextField
            type="number"
            id="daysBeforeWarning"
            variant="standard"
            size="small"
            margin="normal"
            fullWidth
            sx={{ mt: 0, mb: 0, width: '45%' }}
            required
            label={intl.formatMessage({ id: 'app.datagrid.tenant.dialog.subscription.warningDays' })}
            value={tenantMasterState?.daysBeforeWarning}
            onChange={(event: Event) =>
              handleTextFieldChanges(event, TENANT_MASTER_DETAILS_FIELDS_ENUM.DAYS_BEFORE_WARNING)
            }
            error={hasFieldError('daysBeforeWarning', fieldErrors)}
            helperText={getFieldErrorMessage(intl, 'daysBeforeWarning', fieldErrors)}
          />
          <FormControlLabel
            label={intl.formatMessage({ id: 'app.datagrid.subscriptions.systemType.onPremise' })}
            sx={{ mt: 0, mb: 0, width: '45%' }}
            control={<Checkbox size="small" checked={isOnPremiseTenant} onChange={handleOnPremiseChange} />}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ pr: 1, pb: 1, pt: 1.5, borderTop: `1px solid ${grey[300]}` }}>
        <Button variant="contained" size="small" onClick={createTenant}>
          {intl.formatMessage({ id: 'app.common.create' })}
        </Button>
        <Button
          onClick={handleCloseDialog}
          variant="contained"
          size="small"
          sx={{
            backgroundColor: grey[500],
            color: '#fff',
            ':hover': {
              backgroundColor: grey[700]
            }
          }}
        >
          {intl.formatMessage({ id: 'app.common.close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewTenantDialog;

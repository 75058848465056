// @flow

import { object, string } from 'yup';

const CreateGroupSchema = object({
  name: string().required('app.validation.required').max(50, 'app.validation.value.max')
});

export const validateGroupForm = (groupData): Promise =>
  CreateGroupSchema.validate(groupData, { strict: true, abortEarly: false });

// @flow
import Box from '@mui/material/Box';
import { Stack, Tab, Tabs } from '@mui/material';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { getTbLocationData, getTbManagerDeviceAssets } from 'api/service/internal/ThingsboardApiService';
import { useDispatch } from 'react-redux';
import LocationItemsDataGrid from 'components/internal/datagrid/LocationItemsDataGrid';
import StatisticsItem from 'components/internal/dashboard/StatisticsItem';
import { TB_DEVICE_ASSET_TYPE_ENUM, TB_LOCATION_ITEM_TYPE_ENUM } from 'constants/internal/ThingsboardConstants';
import MapLegend from 'components/internal/dashboard/MapLegend';
import MapElementsToggle from 'components/internal/dashboard/MapElementsToggle';
import { getWorldMapElementsSettings } from 'api/service/LocalStorageService';
import WorldMapSearch from 'components/internal/worldmap/WorldMapSearch';
import WorldMapWrapper from 'components/internal/worldmap/WorldMapWrapper';
import { MAP_BOX_STYLE } from 'components/internal/worldmap/WorldMap';
import { TbLocationItem } from 'types/Thingsboard.types';
import { WORLD_MAP_ELEMENTS } from 'constants/GlobalConstants';

const resolveWorldElementType = (tbLocationType: string) => {
  if (tbLocationType === TB_LOCATION_ITEM_TYPE_ENUM.SITE) {
    return WORLD_MAP_ELEMENTS.SITE;
  } else if (tbLocationType === TB_LOCATION_ITEM_TYPE_ENUM.SENSOR_SPEAR) {
    return WORLD_MAP_ELEMENTS.SENSOR_SPEAR;
  } else if (tbLocationType === TB_LOCATION_ITEM_TYPE_ENUM.BUILDING) {
    return WORLD_MAP_ELEMENTS.BUILDING;
  }
};

const LocationData = (): React$Node => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [tabIndex, setTabindex] = useState(0);
  const [itemsFetched, setItemsFetched] = useState(false);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [silos, setSilos] = useState([]);
  const [elementsSettings, setElementsSettings] = useState(getWorldMapElementsSettings());

  const [searchedItem, setSearchedItem] = useState(null);

  useEffect(() => {
    getTbLocationData(dispatch)
      .then((response) => setItems(response ?? []))
      .then(() => setItemsFetched(true));

    getTbManagerDeviceAssets(dispatch, TB_DEVICE_ASSET_TYPE_ENUM.SILO).then((response) => setSilos(response ?? []));
  }, []);

  useEffect(() => {
    let filteredItemsList = items
      ?.filter(
        (item: TbLocationItem): boolean =>
          item.type !== TB_LOCATION_ITEM_TYPE_ENUM.SENSOR_SPEAR ||
          (item.type === TB_LOCATION_ITEM_TYPE_ENUM.SENSOR_SPEAR && item.claimed === true)
      )
      .map((item: TbLocationItem) => {
        return {
          ...item,
          type: resolveWorldElementType(item.type)
        };
      })
      .filter((item: TbLocationItem) => {
        if (item.type === TB_LOCATION_ITEM_TYPE_ENUM.SITE && !item.onPremise) {
          return elementsSettings[WORLD_MAP_ELEMENTS.SITE] === true;
        } else if (item.type === TB_LOCATION_ITEM_TYPE_ENUM.SITE && item.onPremise) {
          return elementsSettings[WORLD_MAP_ELEMENTS.SITE_ON_PREMISE] === true;
        } else if (item.type === TB_LOCATION_ITEM_TYPE_ENUM.SENSOR_SPEAR) {
          return elementsSettings[WORLD_MAP_ELEMENTS.SENSOR_SPEAR] === true;
        } else if (item.type === TB_LOCATION_ITEM_TYPE_ENUM.BUILDING) {
          return elementsSettings[WORLD_MAP_ELEMENTS.BUILDING] === true;
        }
        return true;
      });
    setFilteredItems(filteredItemsList);
  }, [items, elementsSettings]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabindex(newValue);
  };

  const totalSites = items?.filter((item) => item.type === TB_LOCATION_ITEM_TYPE_ENUM.SITE).length;
  const totalSpears = items?.filter((item) => item.type === TB_LOCATION_ITEM_TYPE_ENUM.SENSOR_SPEAR).length;
  const totalSilos = silos?.length ?? 0;
  const totalBuildings = items?.filter((item) => item.type === TB_LOCATION_ITEM_TYPE_ENUM.BUILDING).length;

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Stack direction="row" spacing={2}>
          <StatisticsItem titleKey="app.general.overview.stat.sites" value={totalSites} />
          <StatisticsItem titleKey="app.general.overview.stat.silos" value={totalSilos} />
          <StatisticsItem titleKey="app.general.overview.stat.spears" value={totalSpears} />
          <StatisticsItem titleKey="app.general.overview.stat.buildings" value={totalBuildings} />
        </Stack>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          {tabIndex === 0 && <MapLegend />}
          <MapElementsToggle mapElementsSettings={elementsSettings} setMapElementsSettings={setElementsSettings} />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label={intl.formatMessage({ id: 'app.general.overview.tab.mapView' })} id="tab-item-map-view" />
          <Tab label={intl.formatMessage({ id: 'app.general.overview.tab.gridView' })} id="tab-item-grid-view" />
        </Tabs>
        <WorldMapSearch items={items} setSearchedItem={setSearchedItem} mapElementsSettings={elementsSettings} />
      </Box>
      {tabIndex === 0 && (
        <Box sx={{ width: '100%', height: '65vh', display: 'flex', mt: 2, position: 'relative' }}>
          {itemsFetched && (
            <WorldMapWrapper
              items={filteredItems}
              searchedItem={searchedItem}
              view={MAP_BOX_STYLE.LIGHT}
              redirectionSupported
            />
          )}
        </Box>
      )}
      {tabIndex === 1 && (
        <Box sx={{ width: '100%', display: 'flex', mt: 2 }}>
          <LocationItemsDataGrid items={items} gridElementsSettings={elementsSettings} searchedItem={searchedItem} />
        </Box>
      )}
    </Box>
  );
};

export default LocationData;

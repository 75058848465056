// @flow

import Box from '@mui/material/Box';
import { alpha, styled } from '@mui/material/styles';
import { TreeItem2Content, TreeItem2Label, TreeItem2Root } from '@mui/x-tree-view/TreeItem2';
import { treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { animated, useSpring } from '@react-spring/web';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';
import { GroupAccessRoleColorMapper } from 'mappers/colorMappers';
import { isDefined } from 'util/ObjectUtils';
import Tooltip from '@mui/material/Tooltip';

export const StyledTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
  color: theme.palette.grey[400],
  position: 'relative',
  overflowY: 'hidden',
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: theme.spacing(3.5)
  },
  ...theme.applyStyles('light', {
    color: theme.palette.grey[800]
  })
}));

export const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  flexDirection: 'row-reverse',
  borderRadius: theme.spacing(0.7),
  marginBottom: theme.spacing(0.2),
  marginTop: theme.spacing(0.2),
  padding: theme.spacing(0.5),
  paddingRight: theme.spacing(1),
  fontWeight: 500,
  [`&.Mui-expanded `]: {
    '&:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon': {
      color: theme.palette.primary.dark,
      ...theme.applyStyles('light', {
        color: theme.palette.primary.main
      })
    },
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '16px',
      top: '44px',
      height: 'calc(100% - 48px)',
      width: '1.5px',
      backgroundColor: theme.palette.grey[700],
      ...theme.applyStyles('light', {
        backgroundColor: theme.palette.grey[300]
      })
    }
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    color: 'white',
    ...theme.applyStyles('light', {
      color: theme.palette.primary.main
    })
  }
}));

const AnimatedCollapse = animated(Collapse);

export const TransitionComponent = (props: any) => {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(0,${props.in ? 0 : 20}px,0)`
    }
  });

  return <AnimatedCollapse style={style} {...props} />;
};

export const CustomLabel = ({
  icon: Icon,
  isGroup,
  openActionMenuHandler,
  loggedUserRole,
  accessRole,
  label2,
  isManagingRole,
  children,
  ...other
}: any) => {
  return (
    <TreeItem2Label
      {...other}
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      {Icon && <Box component={Icon} className="labelIcon" color="inherit" sx={{ mr: 1, fontSize: '1.2rem' }} />}

      <Box sx={{ maxWidth: '70%' }}>
        <Tooltip title={children}>
          <Typography sx={{ lineHeight: 1.2, overflow: 'hidden', textOverflow: 'ellipsis' }} variant="body1">
            {children}
          </Typography>
        </Tooltip>
        {isDefined(label2) && (
          <Typography sx={{ lineHeight: 1.2, color: GroupAccessRoleColorMapper[accessRole] }} variant="body2">
            {label2}
          </Typography>
        )}
      </Box>
      {isGroup && (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 'auto' }}>
          <Typography variant="caption">{loggedUserRole}</Typography>
          {isManagingRole && (
            <IconButton onClick={openActionMenuHandler} size="small" sx={{ color: 'text.secondary', marginLeft: 0.5 }}>
              <MoreVertIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      )}
    </TreeItem2Label>
  );
};

export const isExpandable = (reactChildren) => {
  if (Array.isArray(reactChildren)) {
    return reactChildren.length > 0 && reactChildren.some(isExpandable);
  }
  return Boolean(reactChildren);
};

const withBasePath = (path) => `/client/${path}`;
const withInternalBasePath = (path) => `/client/internal/${path}`;
const withExternalBasePath = (path) => `/client/external/${path}`;

export const LOGIN = withBasePath('login');
export const RESET_PASSWORD = withBasePath('reset-password/:token');
export const REQUEST_RESET_PASSWORD = withBasePath('request-reset-password');
export const REGISTER_USER = withBasePath('register');
export const COMPLETE_REGISTRATION = withBasePath('complete-registration/:token');
export const PUBLIC_WORLD_MAP = withBasePath('2bea5076-ace2-499e-9b20-53a182ff4e60');

// Internal
export const INTERNAL_GENERAL = withInternalBasePath('general');
export const INTERNAL_GENERAL_OVERVIEW = withInternalBasePath('general/overview');
export const INTERNAL_GENERAL_NODES_MAP = withInternalBasePath('general/nodes-map');
export const INTERNAL_GENERAL_NODES_MAP_MANAGER = withInternalBasePath('general/nodes-map/manager');
export const INTERNAL_GENERAL_NODES_MAP_FARMER = withInternalBasePath('general/nodes-map/farmer');

export const INTERNAL_SERVICE_OVERVIEW = withInternalBasePath('service');
export const INTERNAL_SERVICE_STATUS_APP = withInternalBasePath('service/status-app');
export const INTERNAL_SERVICE_STATUS_MANAGER = withInternalBasePath('service/status-manager');

export const INTERNAL_MANAGER = withInternalBasePath('manager');
export const INTERNAL_MANAGER_TENANTS = withInternalBasePath('manager/tenants');
export const INTERNAL_MANAGER_GATEWAYS = withInternalBasePath('manager/gateways');
export const INTERNAL_MANAGER_SUBSCRIPTIONS = withInternalBasePath('manager/subscriptions');

export const INTERNAL_FARMER_APP = withInternalBasePath('farmer');
export const INTERNAL_FARMER_APP_CUSTOMERS = withInternalBasePath('farmer/customers');
export const INTERNAL_FARMER_APP_DEVICES = withInternalBasePath('farmer/devices');

export const INTERNAL_MANAGE_GROUPS = withInternalBasePath('groups');

export const INTERNAL_ANALYTICS = withInternalBasePath('analytics');

export const INTERNAL_ADMINISTRATION = withInternalBasePath('administration');
export const INTERNAL_ADMINISTRATION_USERS = withInternalBasePath('administration/users');
export const INTERNAL_ADMINISTRATION_SERVICES = withInternalBasePath('administration/services');

// External
export const EXTERNAL_GENERAL = withExternalBasePath('general');
export const EXTERNAL_GENERAL_OVERVIEW = withExternalBasePath('general/overview');
export const EXTERNAL_MANAGE_GROUPS = withExternalBasePath('groups');

// @flow

import type { OdooTenant } from 'types/Odoo.types';
import Dialog from '@mui/material/Dialog';
import { grey } from '@mui/material/colors';
import { Autocomplete, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import type { TbAssetTelemetriesItem, TelemetryAttribute } from 'types/Thingsboard.types';
import { v4 as uuid } from 'uuid';
import { addAlert } from 'store/slice/ApplicationSlice';
import { useDispatch } from 'react-redux';
import { updateTelemetryAttribute } from 'api/service/internal/ThingsboardManagerTelemetryService';
import TextField from '@mui/material/TextField';
import { isDefined } from 'util/ObjectUtils';
import { MANAGER_TENANT_ATTRIBUTE_ENUM } from 'constants/internal/TenantConstants';

type Props = {
  open: boolean,
  handleClose: Function,
  odooTenants: Array<OdooTenant>,
  tenantInfo: TbAssetTelemetriesItem,
  fetchTenantsWithTelemetries: Function
};

const OdooTenantLinkDialog = ({
  open,
  handleClose,
  odooTenants = [],
  tenantInfo,
  fetchTenantsWithTelemetries
}: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [selectedOdooTenant, setSelectedOdooTenant] = useState(null);

  useEffect(() => {
    const attributes = tenantInfo?.attributes;
    let odooTenantId = attributes.find(
      (attribute: TelemetryAttribute) => attribute.key === MANAGER_TENANT_ATTRIBUTE_ENUM.ODOO_TENANT
    )?.value;
    if (isDefined(odooTenantId)) {
      const tenant = odooTenants?.find((odooTenant) => odooTenant.id === odooTenantId);
      setSelectedOdooTenant(tenant);
    }
  }, []);

  const handleChange = (value: OdooTenant) => {
    let odooTenant = odooTenants?.find((odooTenant) => odooTenant.id === value?.id);
    setSelectedOdooTenant(odooTenant);
  };

  const handleCloseDialog = () => {
    setSelectedOdooTenant(null);
    handleClose();
  };

  const handleSave = () => {
    const body = JSON.stringify({
      [MANAGER_TENANT_ATTRIBUTE_ENUM.ODOO_TENANT]: selectedOdooTenant?.id ?? ''
    });
    const newUpdateObject = {
      entityId: tenantInfo?.assetDto?.id?.id,
      entityType: tenantInfo?.assetDto?.id?.entityType,
      body: body
    };
    updateTelemetryAttribute(dispatch, newUpdateObject)
      .then(() => {
        let alert = {
          id: uuid(),
          severity: 'success',
          title: intl.formatMessage({ id: 'app.common.success' }),
          message: intl.formatMessage({ id: 'app.datagrid.subscriptions.odooLink.dialog.success' })
        };
        dispatch(addAlert(alert));
      })
      .then(fetchTenantsWithTelemetries)
      .then(handleCloseDialog);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        sx: {
          width: '500px',
          p: 1
        }
      }}
    >
      <DialogTitle variant="h6" sx={{ fontWeight: 400, borderBottom: `1px solid ${grey[300]}`, p: 1 }}>
        {intl.formatMessage({ id: 'app.datagrid.subscriptions.odooLink.dialog.title' })}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', pt: 2 }}>
          <Autocomplete
            id="odooTenants"
            options={odooTenants}
            value={selectedOdooTenant ?? null}
            getOptionLabel={(option) => option?.name ?? ''}
            renderOption={(props, option, index) => {
              const key = `odooTenant-${index}-${option.id}`;
              return (
                <li {...props} key={key}>
                  {option?.name}
                </li>
              );
            }}
            onChange={(event, value) => handleChange(value)}
            sx={{ width: 300, mt: 1 }}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({ id: 'app.datagrid.subscriptions.odooLink.dialog.label' })}
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ pr: 1, pb: 0.5, pt: 1, borderTop: `1px solid ${grey[300]}` }}>
        <Button variant="contained" size="small" onClick={handleSave}>
          {intl.formatMessage({ id: 'app.common.save' })}
        </Button>
        <Button
          onClick={handleCloseDialog}
          variant="contained"
          size="small"
          sx={{
            backgroundColor: grey[500],
            color: '#fff',
            ':hover': {
              backgroundColor: grey[700]
            }
          }}
        >
          {intl.formatMessage({ id: 'app.common.close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OdooTenantLinkDialog;

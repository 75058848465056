// @flow
import { Dispatch } from '@reduxjs/toolkit';

import { handleError } from 'api/RestApiHelper';
import {
  ADMIN_GROUPS,
  ADMIN_GROUPS_DEVICES,
  ADMIN_GROUPS_TENANTS,
  ADMIN_GROUPS_USERS
} from 'constants/RestApiConstants';
import { deleteRequest, get, post } from 'api/RestApi';
import { startLoading, stopLoading } from 'store/slice/ApplicationSlice';
import type { CreateGroup, ManageGroupAccess } from 'types/Groups.types';
import { ManageGroupTenants } from 'types/Groups.types';

export async function createGroup(group: CreateGroup, dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return post(ADMIN_GROUPS, group)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function getAllGroups(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(ADMIN_GROUPS)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function deleteGroup(groupId: number, dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return deleteRequest(`${ADMIN_GROUPS}/${groupId}`)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function manageGroupAccess(groupAccess: ManageGroupAccess, dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return post(ADMIN_GROUPS_USERS, groupAccess)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function manageGroupTenants(groupTenants: ManageGroupTenants, dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return post(ADMIN_GROUPS_TENANTS, groupTenants)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function manageGroupDevices(groupTenants: ManageGroupTenants, dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return post(ADMIN_GROUPS_DEVICES, groupTenants)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

// @flow

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Popover } from '@mui/material';
import { useIntl } from 'react-intl';

type Props = {
  open: boolean,
  anchorEl: any,
  onClose: Function,
  onConfirm: Function,
  titleTextKey?: string
};

const AreYouSurePopover = ({
  open,
  anchorEl,
  onClose,
  onConfirm,
  titleTextKey = 'app.common.areYouSure.deletion'
}: Props): React$Node => {
  const intl = useIntl();

  return (
    <Popover
      id="confirm-popover"
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography sx={{ pb: 2 }}>{intl.formatMessage({ id: titleTextKey })}</Typography>
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
          <Button key="confirm-popover-btn" color="error" size="small" variant="contained" onClick={onConfirm}>
            {intl.formatMessage({ id: 'app.common.confirm' })}
          </Button>
          <Button key="cancel-popover-btn" sx={{ ml: 2 }} size="small" variant="outlined" onClick={onClose}>
            {intl.formatMessage({ id: 'app.common.cancel' })}
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

export default AreYouSurePopover;

// @flow

import { isDefined, isEmpty, isEmptyList, isNotEmpty, isNotEmptyList } from 'util/ObjectUtils';
import { IntlShape } from 'react-intl';
import type { OdooTenant } from 'types/Odoo.types';
import { GridColDef, GridRenderCellParams, GridSortCellParams } from '@mui/x-data-grid';
import { CURRENCY_UNIT, DATA_GRID_DIALOG_TYPE } from 'constants/GlobalConstants';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import { formatTimeSpentInDays, getDetailedRelativeTime } from 'util/DateUtils';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import { isNotEmptyOdooField } from 'util/OdooUtils';
import {
  MANAGER_TENANT_ATTRIBUTE_ENUM,
  MANAGER_TENANT_TELEMETRY_ENUM,
  TENANT_STATUS_ENUM
} from 'constants/internal/TenantConstants';
import { SystemTypeTranslationId, TenantStatusTranslationId } from 'mappers/translationMappers';
import {
  InvoiceStatusBGColorMap,
  InvoiceStatusBorderColorMap,
  InvoiceStatusColorMap,
  ServiceColorMapper,
  SubscriptionStatusBGColorMap,
  SubscriptionStatusBorderColorMap,
  SubscriptionStatusColorMap,
  TenantStatusColorMapper
} from 'mappers/colorMappers';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Chip, Stack } from '@mui/material';
import {
  INVOICE_STATUS_ENUM,
  SUBSCRIPTION_STATUS_ENUM,
  SUBSCRIPTION_TYPES_ENUM
} from 'constants/internal/ChargeBeeConstants';
import { formatNumber } from 'util/NumberUtils';
import { SERVICE_ENUM } from 'constants/CommonConstants';
import type {
  TbAssetTelemetriesExtendedItem,
  TbAssetTelemetriesItem,
  TelemetryAttribute
} from 'types/Thingsboard.types';
import { ChargeBeeModel } from 'types/ChargeBee.types';
import { MANAGER_DATAGRID_COLUMNS, MANAGER_DATAGRID_GROUP_COLUMNS } from 'constants/internal/DataGridConstants';
import StatisticsItem from 'components/internal/dashboard/StatisticsItem';
import { amber, grey, red } from '@mui/material/colors';
import {
  SYSTEM_TYPE,
  TB_ALARM_COLUMNS,
  TB_DEVICE_ASSET_TYPE_ENUM,
  TB_DEVICE_SENSOR_TYPE_ENUM,
  TB_DEVICE_TYPE_ENUM
} from 'constants/internal/ThingsboardConstants';
import { copyToClipboard } from 'util/CommonUtils';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import LocationOffOutlinedIcon from '@mui/icons-material/LocationOffOutlined';
import { sumBy } from 'lodash';
import { MANAGER_DEVICE_ATTRIBUTE_ENUM } from 'constants/internal/DeviceConstants';

const customNumberComparator = (v1: number, v2: number): number => {
  if (isDefined(v1) && isDefined(v2)) {
    return v1 - v2;
  } else if (isDefined(v1)) {
    return 1;
  } else if (isDefined(v2)) {
    return -1;
  }
  return 0;
};

const customLengthComparator = (v1: Array, v2: Array): number => {
  let v1Sum = v1?.length ?? 0;
  let v2Sum = v2?.length ?? 0;

  return v1Sum - v2Sum;
};

const customSumComparator = (v1: Object, v2: Object): number => {
  let v1Sum = 0;
  let v2Sum = 0;
  Object.keys(v1).forEach((k) => (v1Sum = v1Sum + (v1[k] ?? 0)));
  Object.keys(v2).forEach((k) => (v2Sum = v2Sum + (v2[k] ?? 0)));

  return v1Sum - v2Sum;
};

const customAlphabeticComparator = (v1: string, v2: string, cellParams: GridSortCellParams) => {
  const sortModel = cellParams.api.getSortModel();
  const sortColumn = sortModel.find((sm) => sm.field === cellParams.field);
  if (sortColumn && sortColumn.sort === 'desc') {
    if (isEmpty(v1) && isEmpty(v2)) {
      return -1;
    }
    if (isEmpty(v1)) {
      return -1;
    }
    if (isEmpty(v2)) {
      return 1;
    }
    return v1.localeCompare(v2);
  } else {
    if (isEmpty(v1) && isEmpty(v2)) {
      return 0;
    }
    if (isEmpty(v1)) {
      return 1;
    }
    if (isEmpty(v2)) {
      return -1;
    }
    return v1.localeCompare(v2);
  }
};

export const createColumns = (
  intl: IntlShape,
  handleOpenDialog: Function,
  isAdminOrSupport: boolean,
  odooTenants: Array<OdooTenant>,
  handleOpenTenantInfoDialog: Function,
  odooUrl: string,
  chargeBeeUrl: string,
  currentLocale: string
): GridColDef[] => {
  const columns = [{ field: 'id', headerName: '#', width: 60 }];

  columns.push(
    {
      field: 'tenantData',
      headerName: intl.formatMessage({ id: 'app.datagrid.name' }),
      width: 200,
      valueFormatter: (v) => {
        return v?.value?.name;
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const tenantData = cellValues.row.tenantData;
        const tenantInfoData = cellValues.row.tenantInfo;

        const handleClickTenant = () => {
          handleOpenDialog(tenantData, tenantInfoData, DATA_GRID_DIALOG_TYPE.TENANT_DETAILS);
        };

        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
            {isDefined(tenantData) ? (
              <Box
                onClick={handleClickTenant}
                sx={{
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
              >
                <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  {tenantData.name}
                </Typography>
                <Typography
                  fontSize="small"
                  sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                >
                  {tenantData.description}
                </Typography>
              </Box>
            ) : (
              <Typography>N/A</Typography>
            )}
          </Box>
        );
      }
    },
    {
      field: 'note',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscription.note' }),
      width: 300,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let note = cellValues.row.note;
        const tenantInfo = cellValues.row.tenantInfo;

        const handleClick = () => {
          handleOpenTenantInfoDialog(tenantInfo, DATA_GRID_DIALOG_TYPE.NOTE_DIALOG);
        };

        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, width: '100%' }}>
            <Typography
              variant="body2"
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                wordBreak: 'break-word'
              }}
            >
              {note}
            </Typography>
            {isAdminOrSupport && (
              <IconButton onClick={handleClick} size="small">
                <EditOutlinedIcon sx={{ width: '20px', height: '20px' }} />
              </IconButton>
            )}
          </Box>
        );
      }
    },
    {
      field: 'createdTime',
      headerName: intl.formatMessage({ id: 'app.datagrid.createdTime' }),
      width: 200,
      valueFormatter: (v) => {
        let createdTimeInMillis = v?.value;
        let formattedDateTime = intl.formatMessage({ id: 'app.common.not.available' });
        if (isDefined(createdTimeInMillis)) {
          formattedDateTime = DateTime.fromMillis(createdTimeInMillis).toLocaleString(DateTime.DATETIME_MED);
        }
        return formattedDateTime;
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const createdTimeInMillis = cellValues.row.createdTime;
        let formattedDateTime = intl.formatMessage({ id: 'app.common.not.available' });
        if (isDefined(createdTimeInMillis)) {
          formattedDateTime = DateTime.fromMillis(createdTimeInMillis).toLocaleString(DateTime.DATETIME_MED);
        }

        return (
          <Box sx={{ display: 'flex' }}>
            <Typography variant="body2">{formattedDateTime}</Typography>
          </Box>
        );
      }
    },
    {
      field: 'lastActivity',
      headerName: intl.formatMessage({ id: 'app.datagrid.lastActivity' }),
      width: 200,
      valueFormatter: (v) => {
        let millis = v?.value;

        let dateTime = '-';
        if (millis > 0) {
          dateTime = DateTime.fromMillis(millis).toLocaleString(DateTime.DATETIME_MED);
        }

        return dateTime;
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let lastActivityMillis = cellValues.row.lastActivity;
        let lastActivity = intl.formatMessage({ id: 'app.common.not.available' });
        if (lastActivityMillis > 0) {
          lastActivity = `${getDetailedRelativeTime(lastActivityMillis)} ago`;
        }

        return <Typography variant="body2">{lastActivity}</Typography>;
      }
    },
    {
      field: 'tenantSites',
      headerName: intl.formatMessage({ id: 'app.datagrid.sites.details' }),
      width: 300,
      sortComparator: customLengthComparator,
      valueFormatter: (v) => {
        let tenantSites = v?.value;

        let displayText = '';
        tenantSites?.map((site, index: number) => {
          displayText = displayText + site.name;

          if (index !== tenantSites.length - 1) {
            displayText = displayText + ', ';
          }
        });

        return displayText;
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let tenantSites = cellValues.row.tenantSites;

        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {tenantSites?.map((site: Object): React$Node => {
              const isLocationDataAvailable = isDefined(site.latitude) && isDefined(site.longitude);
              return (
                <Box sx={{ display: 'flex', alignItems: 'center' }} key={`tenant-site-${site.id.id}`}>
                  <Chip
                    sx={{
                      m: 0.5,
                      fontSize: '12px',
                      color: grey[200],
                      '& .MuiChip-label': {
                        maxWidth: '260px'
                      }
                    }}
                    color="success"
                    label={site.name}
                    variant="contained"
                    size="small"
                  />
                  {!isLocationDataAvailable && (
                    <Tooltip title={intl.formatMessage({ id: 'app.datagrid.location.tooltip.noLocation' })}>
                      <LocationOffOutlinedIcon fontSize="small" />
                    </Tooltip>
                  )}
                </Box>
              );
            })}
          </Box>
        );
      }
    },
    {
      field: 'tenantGateways',
      headerName: intl.formatMessage({ id: 'app.datagrid.gateways' }),
      width: 300,
      sortComparator: customLengthComparator,
      valueFormatter: (v) => {
        let tenantGateways = v?.value;

        let displayText = '';
        tenantGateways?.map((gateway, index: number) => {
          displayText = displayText + gateway.assetDto.name;

          if (index !== tenantGateways.length - 1) {
            displayText = displayText + ', ';
          }
        });

        return displayText;
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let tenantGateways = cellValues.row.tenantGateways;
        let tenantData = cellValues.row.tenantData;

        const handleClickGateway = () => {
          handleOpenDialog(tenantData, null, DATA_GRID_DIALOG_TYPE.GATEWAYS_DIALOG);
        };

        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {tenantGateways?.map((gateway: TbAssetTelemetriesItem): React$Node => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center' }} key={`tenant-gateway-${gateway.assetDto.id.id}`}>
                  <Tooltip title={intl.formatMessage({ id: 'app.datagrid.tenant.gateways.tooltip' })}>
                    <Chip
                      sx={{
                        m: 0.5,
                        fontSize: '12px',
                        color: grey[200],
                        '& .MuiChip-label': {
                          maxWidth: '260px'
                        }
                      }}
                      color="info"
                      label={gateway.assetDto.name}
                      variant="contained"
                      size="small"
                      onClick={handleClickGateway}
                    />
                  </Tooltip>
                </Box>
              );
            })}
          </Box>
        );
      }
    },
    {
      field: 'lastGatewayActivity',
      headerName: intl.formatMessage({ id: 'app.datagrid.lastGatewayActivity' }),
      width: 200,
      valueFormatter: (v) => {
        let millis = v?.value;

        let dateTime = '-';
        if (millis > 0) {
          dateTime = DateTime.fromMillis(millis).toLocaleString(DateTime.DATETIME_MED);
        }

        return dateTime;
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let lastActivityMillis = cellValues.row.lastGatewayActivity;
        let lastActivity = intl.formatMessage({ id: 'app.common.not.available' });
        if (lastActivityMillis > 0) {
          lastActivity = `${getDetailedRelativeTime(lastActivityMillis)} ago`;
        }

        return <Typography variant="body2">{lastActivity}</Typography>;
      }
    },
    {
      field: 'systemType',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscriptions.systemType' }),
      width: 150,
      valueGetter: (params) => {
        let systemType = params.row.systemType;
        return intl.formatMessage({ id: SystemTypeTranslationId[systemType] });
      },
      sortComparator: customAlphabeticComparator,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const systemType = cellValues.row.systemType;
        let onPremiseConfig = isNotEmpty(cellValues.row.onPremiseConfig)
          ? JSON.stringify(cellValues.row.onPremiseConfig)
          : null;

        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={isAdminOrSupport ? onPremiseConfig : null}>
              <Typography variant="body2">{intl.formatMessage({ id: SystemTypeTranslationId[systemType] })}</Typography>
            </Tooltip>
            {isAdminOrSupport && isNotEmpty(onPremiseConfig) && (
              <IconButton variant="outlined" onClick={() => copyToClipboard(onPremiseConfig)}>
                <ContentCopyOutlinedIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        );
      }
    },
    {
      field: 'comment',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscription.comment' }),
      width: 300,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let comment = cellValues.row.comment;
        const tenantInfo = cellValues.row.tenantInfo;

        const handleClick = () => {
          handleOpenTenantInfoDialog(tenantInfo, DATA_GRID_DIALOG_TYPE.COMMENT_DIALOG);
        };

        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, width: '100%' }}>
            <Typography
              variant="body2"
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                wordBreak: 'break-word'
              }}
            >
              {comment}
            </Typography>
            {isAdminOrSupport && (
              <IconButton onClick={handleClick} size="small">
                <EditOutlinedIcon sx={{ width: '20px', height: '20px' }} />
              </IconButton>
            )}
          </Box>
        );
      }
    },
    {
      field: 'customer',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscriptions.customer' }),
      width: 150,
      sortComparator: customAlphabeticComparator,
      valueFormatter: (v) => {
        return v?.value;
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const customer = cellValues.row.customer;

        return (
          <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
            {isDefined(customer) ? customer : intl.formatMessage({ id: 'app.common.not.available' })}
          </Typography>
        );
      }
    },
    {
      field: 'odooTenant',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscriptions.odooLink' }),
      width: 150,
      valueFormatter: (v) => {
        return v?.value?.name;
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const tenantInfo = cellValues.row.tenantInfo;
        const odooTenant = cellValues.row.odooTenant;

        const handleClick = () => {
          handleOpenTenantInfoDialog(tenantInfo, DATA_GRID_DIALOG_TYPE.ODOO_TENANT_LINK_DIALOG);
        };

        const odooTenantId = odooTenant?.id;
        let odooTenantLink = `${odooUrl}web#id=${odooTenantId}&model=supertech_agrolog.tenant&view_type=form`;

        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            {isDefined(odooTenant) && (
              <Tooltip title={intl.formatMessage({ id: 'app.datagrid.subscriptions.odooLink.tooltip' })}>
                <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  <Link href={odooTenantLink} target="_blank">
                    {odooTenant.name}
                  </Link>
                </Typography>
              </Tooltip>
            )}
            {isAdminOrSupport && (
              <Tooltip title={intl.formatMessage({ id: 'app.datagrid.subscriptions.odooLink.dialog.title' })}>
                <IconButton onClick={handleClick} size="small">
                  <LinkOutlinedIcon sx={{ width: '20px', height: '20px' }} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      }
    },
    {
      field: 'odooDescription',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscriptions.odooDescription' }),
      width: 200,
      valueFormatter: (v) => {
        return v?.value;
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let description = cellValues.row.odooDescription;
        return (
          <Typography variant="body2">
            {isNotEmptyOdooField(description) ? description : intl.formatMessage({ id: 'app.common.not.available' })}
          </Typography>
        );
      }
    },
    {
      field: 'development',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscriptions.tna.development' }),
      width: 150,
      valueGetter: (params) => {
        let tenantStatus = params.row.tenantStatus;
        return tenantStatus === TENANT_STATUS_ENUM.DEVELOPMENT
          ? intl.formatMessage({ id: TenantStatusTranslationId[tenantStatus] })
          : intl.formatMessage({ id: 'app.common.not.available' });
      },
      valueFormatter: (v) => {
        return v?.value;
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const tenantStatus = cellValues.row.tenantStatus;

        return (
          <Typography
            variant="body2"
            sx={{ color: tenantStatus === TENANT_STATUS_ENUM.DEVELOPMENT && TenantStatusColorMapper[tenantStatus] }}
          >
            {tenantStatus === TENANT_STATUS_ENUM.DEVELOPMENT
              ? intl.formatMessage({ id: TenantStatusTranslationId[tenantStatus] })
              : intl.formatMessage({ id: 'app.common.not.available' })}
          </Typography>
        );
      }
    },
    {
      field: 'archived',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscriptions.tna.archived' }),
      width: 150,
      valueGetter: (params) => {
        let tenantStatus = params.row.tenantStatus;
        return tenantStatus === TENANT_STATUS_ENUM.ARCHIVED
          ? intl.formatMessage({ id: TenantStatusTranslationId[tenantStatus] })
          : intl.formatMessage({ id: 'app.common.not.available' });
      },
      valueFormatter: (v) => {
        return v?.value;
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const tenantStatus = cellValues.row.tenantStatus;

        return (
          <Typography
            variant="body2"
            sx={{ color: tenantStatus === TENANT_STATUS_ENUM.ARCHIVED && TenantStatusColorMapper[tenantStatus] }}
          >
            {tenantStatus === TENANT_STATUS_ENUM.ARCHIVED
              ? intl.formatMessage({ id: TenantStatusTranslationId[tenantStatus] })
              : intl.formatMessage({ id: 'app.common.not.available' })}
          </Typography>
        );
      }
    },
    {
      field: 'canceled',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscriptions.tna.canceled' }),
      width: 200,
      valueGetter: (params) => {
        let tenantStatus = params.row.tenantStatus;
        return tenantStatus === TENANT_STATUS_ENUM.CANCELED
          ? intl.formatMessage({ id: TenantStatusTranslationId[tenantStatus] })
          : intl.formatMessage({ id: 'app.common.not.available' });
      },
      valueFormatter: (v) => {
        return v?.value;
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const tenantStatus = cellValues.row.tenantStatus;

        return (
          <Typography
            variant="body2"
            sx={{ color: tenantStatus === TENANT_STATUS_ENUM.CANCELED && TenantStatusColorMapper[tenantStatus] }}
          >
            {tenantStatus === TENANT_STATUS_ENUM.CANCELED
              ? intl.formatMessage({ id: TenantStatusTranslationId[tenantStatus] })
              : intl.formatMessage({ id: 'app.common.not.available' })}
          </Typography>
        );
      }
    },
    {
      field: 'subCustomer',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscriptions.subscription.customer' }),
      width: 250,
      valueFormatter: (v) => {
        return v?.value;
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let chargeBeeCustomers = cellValues.row.chargeBeeCustomer;

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              height: '100%',
              width: '100%',
              pt: 0.5,
              pb: 0.5,
              gap: 0.2
            }}
          >
            {chargeBeeCustomers?.map((customer) => {
              let customerId = customer.data.id;
              let customerLink = `${chargeBeeUrl}d/customers/${customerId}`;
              const customerEmail = customer.data?.email;
              let company = customer.data.company;

              return (
                <Box
                  sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%' }}
                  key={customerId}
                >
                  {isNotEmpty(company) && (
                    <Tooltip title={company} placement="top">
                      <Typography
                        sx={{ width: '95%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                        variant="body2"
                      >
                        {company}
                      </Typography>
                    </Tooltip>
                  )}
                  <Tooltip
                    title={intl.formatMessage({ id: 'app.datagrid.subscriptions.subscription.customer.tooltip' })}
                  >
                    <Link href={customerLink} target="_blank" sx={{ width: '95%' }}>
                      <Typography
                        variant="body2"
                        sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                      >
                        {customerEmail}
                      </Typography>
                    </Link>
                  </Tooltip>
                </Box>
              );
            })}
          </Box>
        );
      }
    },
    {
      field: 'subscription',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscriptions.subscription' }),
      width: 200,
      valueFormatter: (v) => {
        let subscriptions = v?.value;
        let label = '';
        subscriptions?.forEach((item, index: number) => {
          label = label + item?.data?.id;

          if (index !== subscriptions?.length - 1) {
            label = label + ', ';
          }
        });
        return label;
      },
      sortComparator: customLengthComparator,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let chargeBeeSubscriptions = cellValues.row.subscription;
        let tenantInfo = cellValues.row.tenantInfo;

        const handleClick = () => {
          handleOpenTenantInfoDialog(tenantInfo, DATA_GRID_DIALOG_TYPE.CREATE_SUBSCRIPTION_DIALOG);
        };

        const handleSubLinkClick = () => {
          handleOpenTenantInfoDialog(tenantInfo, DATA_GRID_DIALOG_TYPE.CHARGEBEE_SUBSCRIPTION_LINK_DIALOG);
        };

        const showAddSubscriptionButton = isEmptyList(chargeBeeSubscriptions) && isAdminOrSupport;
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              height: '100%',
              pt: 0.5,
              pb: 0.5,
              gap: 0.2
            }}
          >
            {showAddSubscriptionButton && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Tooltip title={intl.formatMessage({ id: 'app.datagrid.subscriptions.subscription.create.dialog' })}>
                  <IconButton onClick={handleClick} size="small">
                    <AddOutlinedIcon sx={{ width: '20px', height: '20px' }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={intl.formatMessage({ id: 'app.datagrid.subscriptions.cbSubscription.dialog.title' })}>
                  <IconButton onClick={handleSubLinkClick} size="small">
                    <LinkOutlinedIcon sx={{ width: '20px', height: '20px' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {chargeBeeSubscriptions?.map((subscription, index) => {
              const subscriptionId = subscription?.data?.id;
              let subscriptionLink = `${chargeBeeUrl}d/subscriptions/${subscriptionId}`;
              const subscriptionName = subscription?.data?.id;
              return (
                <Box sx={{ display: 'flex', alignItems: 'center' }} key={subscriptionId}>
                  <Tooltip title={intl.formatMessage({ id: 'app.datagrid.subscriptions.subscription.tooltip' })}>
                    <Link href={subscriptionLink} target="_blank" sx={{ width: '95%' }}>
                      <Typography
                        variant="body2"
                        sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                      >
                        {subscriptionName}
                      </Typography>
                    </Link>
                  </Tooltip>
                  {index === chargeBeeSubscriptions.length - 1 && isAdminOrSupport && (
                    <Tooltip
                      title={intl.formatMessage({ id: 'app.datagrid.subscriptions.cbSubscription.dialog.title' })}
                    >
                      <IconButton onClick={handleSubLinkClick} size="small">
                        <LinkOutlinedIcon sx={{ width: '20px', height: '20px' }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              );
            })}
          </Box>
        );
      }
    },
    {
      field: 'subStatus',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscriptions.subStatus' }),
      width: 150,
      valueGetter: (params) => {
        let subscription = params.row.subscription;

        let label = '';
        subscription?.forEach((item, index: number) => {
          let status = item?.data?.status;
          const formattedStatus = status?.replace('_', ' ');
          const capitalizedStatus = formattedStatus?.charAt(0).toUpperCase() + formattedStatus?.slice(1);
          label = label + capitalizedStatus;

          if (index !== subscription?.length - 1) {
            label = label + ', ';
          }
        });

        return label;
      },
      valueFormatter: (value) => {
        return value?.value;
      },
      sortComparator: customAlphabeticComparator,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let chargeBeeSubscriptions = cellValues.row.subscription;

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              height: '100%',
              pt: 0.5,
              pb: 0.5,
              gap: 0.2
            }}
          >
            {chargeBeeSubscriptions?.map((subscription) => {
              let status = subscription?.data?.status;
              const formattedStatus = status?.replace('_', ' ');
              const capitalizedStatus = formattedStatus?.charAt(0).toUpperCase() + formattedStatus?.slice(1);
              return (
                <Chip
                  key={subscription?.data?.id}
                  variant="outlined"
                  size="small"
                  label={capitalizedStatus}
                  sx={{
                    color: SubscriptionStatusColorMap[status],
                    borderColor: SubscriptionStatusBorderColorMap[status],
                    backgroundColor: SubscriptionStatusBGColorMap[status]
                  }}
                />
              );
            })}
          </Box>
        );
      }
    },
    {
      field: 'billingPeriod',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscriptions.billingPeriod' }),
      width: 230,
      valueGetter: (params) => {
        let subscriptions = params.row.subscription;

        let label = '';
        subscriptions?.forEach((subscription, index: number) => {
          let billingPeriodStartMillis = subscription?.data?.started_at ?? subscription?.data?.start_date;
          let billingPeriodEndMillis = subscription?.data?.next_billing_at ?? null;

          let billingStart = isNotEmpty(billingPeriodStartMillis)
            ? DateTime.fromSeconds(billingPeriodStartMillis).toLocaleString(DateTime.DATE_SHORT)
            : 'N/A';
          let billingEnd = isDefined(billingPeriodEndMillis)
            ? DateTime.fromSeconds(billingPeriodEndMillis).toLocaleString(DateTime.DATE_SHORT)
            : 'N/A';
          let billingPeriod;
          if (subscription.data.status === SUBSCRIPTION_STATUS_ENUM.FUTURE) {
            billingPeriod = billingStart;
          } else {
            billingPeriod = `${billingStart} - ${billingEnd}`;
          }
          label = label + billingPeriod;

          if (index !== subscriptions?.length - 1) {
            label = label + ', ';
          }
        });

        return label;
      },
      valueFormatter: (value) => {
        return value?.value;
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let chargeBeeSubscriptions = cellValues.row.subscription;

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              height: '100%',
              pt: 0.5,
              pb: 0.5,
              gap: 0.2
            }}
          >
            {chargeBeeSubscriptions?.map((subscription) => {
              // NOTE: started_at is for Active subscriptions, start_date is for Future subscriptions
              let billingPeriodStartMillis = subscription?.data?.started_at ?? subscription?.data?.start_date;
              let billingPeriodEndMillis = subscription?.data?.next_billing_at;

              let billingStart = isDefined(billingPeriodStartMillis)
                ? DateTime.fromSeconds(billingPeriodStartMillis).toLocaleString(DateTime.DATE_MED)
                : 'N/A';
              let billingEnd = isDefined(billingPeriodEndMillis)
                ? DateTime.fromSeconds(billingPeriodEndMillis).toLocaleString(DateTime.DATE_MED)
                : 'N/A';
              let billingPeriod;
              if (subscription.data.status === SUBSCRIPTION_STATUS_ENUM.FUTURE) {
                billingPeriod = billingStart;
              } else {
                billingPeriod = `${billingStart} - ${billingEnd}`;
              }

              return (
                <Typography key={subscription?.data?.id} variant="body2">
                  {billingPeriod}
                </Typography>
              );
            })}
          </Box>
        );
      }
    },
    {
      field: 'unbilledCharges',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscriptions.unbilledCharges' }),
      width: 150,
      valueFormatter: (value) => {
        let unbilledCharges = value?.value;

        let label = '';
        Object.values(unbilledCharges)?.forEach((charge, index: number) => {
          label = label + `${CURRENCY_UNIT.EUR}${formatNumber(charge / 100, currentLocale)}`;

          if (index !== Object.values(unbilledCharges)?.length - 1) {
            label = label + ', ';
          }
        });

        return label;
      },
      sortComparator: customSumComparator,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let unbilledCharges = cellValues.row.unbilledCharges;
        let chargeBeeSubscriptions = cellValues.row.subscription;

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              height: '100%',
              pt: 0.5,
              pb: 0.5,
              gap: 0.2
            }}
          >
            {chargeBeeSubscriptions?.map((subscription) => {
              let subscriptionId = subscription.data.id;
              let unbilledCharge = unbilledCharges[subscriptionId];

              const label = isDefined(unbilledCharge)
                ? `${CURRENCY_UNIT.EUR}${formatNumber(unbilledCharge / 100, currentLocale)}`
                : intl.formatMessage({ id: 'app.common.not.available' });

              return (
                <Typography variant="body2" key={`unbilled-charge-${subscriptionId}`}>
                  {label}
                </Typography>
              );
            })}
          </Box>
        );
      }
    },
    {
      field: 'invoiceStatus',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscriptions.invoiceStatus' }),
      width: 150,
      sortComparator: customAlphabeticComparator,
      valueGetter: (params) => {
        let invoices = params.row.invoiceStatus;

        let label = '';
        invoices?.forEach((item, index: number) => {
          const status = item.data.status;
          const capitalizedStatus = status.charAt(0).toUpperCase() + status.slice(1);
          const formattedStatus = capitalizedStatus.replaceAll('_', ' ');
          label = label + formattedStatus;

          if (index !== invoices?.length - 1) {
            label = label + ', ';
          }
        });

        return label;
      },
      valueFormatter: (v) => {
        return v?.value;
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let chargeBeeInvoices = cellValues.row.invoiceStatus;

        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', pt: 0.5, pb: 0.5, gap: 0.5 }}>
            {chargeBeeInvoices?.map((invoice) => {
              let invoiceId = invoice.data?.id;
              let status = invoice.data?.status;
              const totalAmount = invoice.data?.total;
              const currencyCode = invoice.data?.currency_code;

              const capitalizedStatus = status.charAt(0).toUpperCase() + status.slice(1);
              const formattedStatus = capitalizedStatus.replaceAll('_', ' ');
              let invoiceLink = `${chargeBeeUrl}d/invoices/${invoiceId}`;

              const dueDate = invoice.data.due_date;
              let daysOverdue = 0;
              if (isDefined(dueDate)) {
                let currentDateTime = DateTime.now();
                const dueDateTime = DateTime.fromSeconds(dueDate);

                let diffMilliseconds = currentDateTime?.diff(dueDateTime).toObject().milliseconds;
                daysOverdue = formatTimeSpentInDays(diffMilliseconds);
              }

              return (
                <Tooltip
                  key={invoiceId}
                  placement="top-end"
                  title={
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="caption">
                        {intl.formatMessage(
                          { id: 'app.datagrid.subscriptions.invoiceStatus.tooltip' },
                          { total: `${formatNumber(totalAmount / 100, currentLocale)} ${currencyCode}` }
                        )}
                      </Typography>
                      <Typography variant="caption">
                        {intl.formatMessage({ id: 'app.datagrid.subscriptions.invoiceStatus.tooltip.checkChargeBee' })}
                      </Typography>
                    </Box>
                  }
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Link href={invoiceLink} target="_blank">
                      <Chip
                        variant="outlined"
                        size="small"
                        label={formattedStatus}
                        sx={{
                          color: InvoiceStatusColorMap[status],
                          borderColor: InvoiceStatusBorderColorMap[status],
                          backgroundColor: InvoiceStatusBGColorMap[status],
                          '&:hover': {
                            cursor: 'pointer'
                          }
                        }}
                      />
                    </Link>
                    {(status === INVOICE_STATUS_ENUM.NOT_PAID || status === INVOICE_STATUS_ENUM.PAYMENT_DUE) && (
                      <Typography variant="caption">
                        {intl.formatMessage(
                          { id: 'app.datagrid.subscriptions.daysOverdue.tooltip' },
                          { days: daysOverdue }
                        )}
                      </Typography>
                    )}
                  </Box>
                </Tooltip>
              );
            })}
          </Box>
        );
      }
    },
    {
      field: 'shutOutDate',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscriptions.shutOutDate' }),
      width: 210,
      sortComparator: customNumberComparator,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const tenantData = cellValues.row.tenantData;
        let shutOutDate = cellValues.row.shutOutDate;
        let daysBeforeWarning = tenantData?.daysBeforeWarning;

        let shutOutDateFormatted;
        let daysAfterShutout;
        if (isNotEmpty(shutOutDate)) {
          let shutoutDateTime = DateTime.fromMillis(shutOutDate);
          shutOutDateFormatted = shutoutDateTime.toLocaleString(DateTime.DATETIME_MED);

          const currentDateTime = DateTime.now();
          let milliseconds = currentDateTime.diff(shutoutDateTime).toObject().milliseconds;
          daysAfterShutout = formatTimeSpentInDays(milliseconds);
        }

        const handleClick = () => {
          handleOpenDialog(tenantData, null, DATA_GRID_DIALOG_TYPE.SHUTOUT_DATE_DIALOG);
        };

        let chargeBeeSubscriptions = cellValues.row.subscription;
        let activeSubscription = chargeBeeSubscriptions?.find(
          (sub) => sub.data.status === SUBSCRIPTION_STATUS_ENUM.ACTIVE
        );
        const showShutoutDateEditButton = isAdminOrSupport && !isDefined(activeSubscription);

        let shutoutColor = 'default';
        if (isDefined(daysAfterShutout) && daysAfterShutout >= 0) {
          if (daysAfterShutout <= daysBeforeWarning) {
            shutoutColor = amber[700];
          } else {
            shutoutColor = red[700];
          }
        }

        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="body2" sx={{ color: shutoutColor }}>
              {shutOutDateFormatted}
            </Typography>
            {showShutoutDateEditButton && (
              <IconButton onClick={handleClick} size="small">
                <EditOutlinedIcon sx={{ width: '20px', height: '20px' }} />
              </IconButton>
            )}
          </Box>
        );
      }
    },
    {
      field: 'totalTempSensorsCB',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscriptions.tempSensorsCB' }),
      width: 150,
      sortComparator: customNumberComparator,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let totalTempSensorsCB = cellValues.row.totalTempSensorsCB;
        return (
          <Typography variant="body2" sx={{ color: ServiceColorMapper[SERVICE_ENUM.CHARGEBEE] }}>
            {totalTempSensorsCB}
          </Typography>
        );
      }
    },
    {
      field: 'tempSensorsTB',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscriptions.tempSensorsTB' }),
      width: 150,
      sortComparator: customNumberComparator,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let totalTemperatureSensorCount = cellValues.row.tempSensorsTB;

        return (
          <Typography variant="body2" sx={{ color: ServiceColorMapper[SERVICE_ENUM.THINGSBOARD] }}>
            {totalTemperatureSensorCount}
          </Typography>
        );
      }
    },
    {
      field: 'tempSensorsOdoo',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscriptions.tempSensorsOdoo' }),
      width: 150,
      sortComparator: customNumberComparator,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const totalTemperatureSensors = cellValues.row.tempSensorsOdoo;

        return (
          <Typography variant="body2" sx={{ color: ServiceColorMapper[SERVICE_ENUM.ODOO] }}>
            {totalTemperatureSensors}
          </Typography>
        );
      }
    },
    {
      field: 'totalMoistureSensorsCB',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscriptions.moistSensorsCB' }),
      width: 150,
      sortComparator: customNumberComparator,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let totalMoistureSensorsCB = cellValues.row.totalMoistureSensorsCB;

        return (
          <Typography variant="body2" sx={{ color: ServiceColorMapper[SERVICE_ENUM.CHARGEBEE] }}>
            {totalMoistureSensorsCB}
          </Typography>
        );
      }
    },
    {
      field: 'moistSensorsTB',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscriptions.moistSensorsTB' }),
      width: 150,
      sortComparator: customNumberComparator,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const totalMoistureSensorsCount = cellValues.row.moistSensorsTB;

        return (
          <Typography variant="body2" sx={{ color: ServiceColorMapper[SERVICE_ENUM.THINGSBOARD] }}>
            {totalMoistureSensorsCount}
          </Typography>
        );
      }
    },
    {
      field: 'moistSensorsOdoo',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscriptions.moistSensorsOdoo' }),
      width: 150,
      sortComparator: customNumberComparator,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const totalMoistureSensors = cellValues.row.moistSensorsOdoo;

        return (
          <Typography variant="body2" sx={{ color: ServiceColorMapper[SERVICE_ENUM.ODOO] }}>
            {totalMoistureSensors}
          </Typography>
        );
      }
    },
    {
      field: 'totalActiveAlarms',
      headerName: intl.formatMessage({ id: 'app.datagrid.alarms.activeCount.total' }),
      width: 150,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const tenantData = cellValues.row.tenantData;
        const totalActiveAlarms = cellValues.row.totalActiveAlarms;

        const handleClick = () => {
          handleOpenDialog(tenantData, null, DATA_GRID_DIALOG_TYPE.ACTIVE_ALARM_CHART_DIALOG);
        };

        return (
          <Tooltip title={intl.formatMessage({ id: 'app.datagrid.alarms.active.tooltip' })}>
            <Typography
              sx={{
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
              onClick={handleClick}
            >
              {totalActiveAlarms}
            </Typography>
          </Tooltip>
        );
      }
    },
    {
      field: 'activeAlarmsByType',
      headerName: intl.formatMessage({ id: 'app.datagrid.alarms.active' }),
      width: 300,
      valueFormatter: (v) => {
        let activeAlarms = v?.value;

        let displayText = '';
        let activeAlarmsByType = isDefined(activeAlarms) ? JSON.parse(activeAlarms) : [];
        activeAlarmsByType?.map((alarm, index: number) => {
          const alarmType = alarm['alarmType'];
          const numOfAlarms = alarm['amount'];

          let alarmLabel = alarmType.replaceAll('_', ' ');

          displayText = displayText + alarmLabel + ': ' + numOfAlarms;

          if (index !== activeAlarmsByType.length - 1) {
            displayText = displayText + ', ';
          }
        });

        return displayText;
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let activeAlarmsByType = isDefined(cellValues.row.activeAlarmsByType)
          ? JSON.parse(cellValues.row.activeAlarmsByType)
          : [];

        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {activeAlarmsByType?.map((alarm: Object): React$Node => {
              const alarmType = alarm['alarmType'];
              const numOfAlarms = alarm['amount'];

              let alarmLabel = alarmType.replaceAll('_', ' ');
              return (
                <Chip
                  key={`alarm-type-${alarmType}-${Math.random()}`}
                  sx={{ m: 0.5, fontSize: '12px' }}
                  color="warning"
                  label={`${alarmLabel} (${numOfAlarms})`}
                  variant="contained"
                  size="small"
                />
              );
            })}
          </Box>
        );
      }
    },
    {
      field: 'totalStaleAlarms',
      headerName: intl.formatMessage({ id: 'app.datagrid.alarms.staleCount.total' }),
      width: 150,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const tenantData = cellValues.row.tenantData;
        const totalStaleAlarms = cellValues.row.totalStaleAlarms;

        const handleClick = () => {
          handleOpenDialog(tenantData, null, DATA_GRID_DIALOG_TYPE.STALE_ALARM_CHART_DIALOG);
        };

        return (
          <Tooltip title={intl.formatMessage({ id: 'app.datagrid.alarms.stale.tooltip' })}>
            <Typography
              sx={{
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
              onClick={handleClick}
            >
              {totalStaleAlarms}
            </Typography>
          </Tooltip>
        );
      }
    },
    {
      field: 'staleAlarmsByType',
      headerName: intl.formatMessage({ id: 'app.datagrid.alarms.stale' }),
      width: 300,
      valueFormatter: (v) => {
        let staleAlarms = v?.value;

        let displayText = '';
        let staleAlarmsByType = isDefined(staleAlarms) ? JSON.parse(staleAlarms) : [];
        staleAlarmsByType?.map((alarm, index: number) => {
          const alarmType = alarm['alarmType'];
          const numOfAlarms = alarm['amount'];

          let alarmLabel = alarmType.replaceAll('_', ' ');

          displayText = displayText + alarmLabel + ': ' + numOfAlarms;

          if (index !== staleAlarmsByType.length - 1) {
            displayText = displayText + ', ';
          }
        });

        return displayText;
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let staleAlarmsByType = isDefined(cellValues.row.staleAlarmsByType)
          ? JSON.parse(cellValues.row.staleAlarmsByType)
          : [];

        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {staleAlarmsByType?.map((alarm: Object): React$Node => {
              const alarmType = alarm['alarmType'];
              const numOfAlarms = alarm['amount'];

              let alarmLabel = alarmType.replaceAll('_', ' ');
              return (
                <Chip
                  key={`alarm-type-${alarmType}-${Math.random()}`}
                  sx={{ m: 0.5, fontSize: '12px' }}
                  color="warning"
                  label={`${alarmLabel} (${numOfAlarms})`}
                  variant="contained"
                  size="small"
                />
              );
            })}
          </Box>
        );
      }
    },
    {
      field: 'sites',
      headerName: intl.formatMessage({ id: 'app.datagrid.assetsCount.sites' }),
      width: 150
    },
    {
      field: 'siloGroups',
      headerName: intl.formatMessage({ id: 'app.datagrid.assetsCount.siloGroups' }),
      width: 150
    },
    {
      field: 'silos',
      headerName: intl.formatMessage({ id: 'app.datagrid.assetsCount.silos' }),
      width: 150
    },
    {
      field: 'temperatureSensorLines',
      headerName: intl.formatMessage({ id: 'app.datagrid.devicesCount.temperatureLines' }),
      width: 150
    },
    {
      field: 'moistureSensorLines',
      headerName: intl.formatMessage({ id: 'app.datagrid.devicesCount.moistureLines' }),
      width: 150
    },
    {
      field: 'headspaceSensorDevice',
      headerName: intl.formatMessage({ id: 'app.datagrid.devicesCount.headspace' }),
      width: 150
    },
    {
      field: 'levelIndicatorDevice',
      headerName: intl.formatMessage({ id: 'app.datagrid.devicesCount.levelIndicator' }),
      width: 150
    },
    {
      field: 'weatherStation',
      headerName: intl.formatMessage({ id: 'app.datagrid.devicesCount.weatherStations' }),
      width: 150
    },
    {
      field: 'sms',
      headerName: intl.formatMessage({ id: 'app.datagrid.devicesCount.sms' }),
      width: 150
    },
    {
      field: 'modbus',
      headerName: intl.formatMessage({ id: 'app.datagrid.devicesCount.modbus' }),
      width: 150
    },
    {
      field: 'gateway',
      headerName: intl.formatMessage({ id: 'app.datagrid.devicesCount.gateways' }),
      width: 150
    },
    {
      field: 'aerations',
      headerName: intl.formatMessage({ id: 'app.datagrid.assetsCount.aerations' }),
      width: 150
    },
    {
      field: 'temperatureSensors',
      headerName: intl.formatMessage({ id: 'app.datagrid.sensorsCount.temperature' }),
      width: 150
    },
    {
      field: 'moistureSensors',
      headerName: intl.formatMessage({ id: 'app.datagrid.sensorsCount.moisture' }),
      width: 150
    },
    {
      field: 'headSpaceSensors',
      headerName: intl.formatMessage({ id: 'app.datagrid.sensorsCount.headspace' }),
      width: 150
    },
    {
      field: 'co2Sensors',
      headerName: intl.formatMessage({ id: 'app.datagrid.sensorsCount.co2' }),
      width: 150
    },
    {
      field: 'levelIndicatorSensors',
      headerName: intl.formatMessage({ id: 'app.datagrid.sensorsCount.levelIndicator' }),
      width: 150
    }
  );
  return columns;
};

export const resolveChargeBeeData = (
  attributes: Array<TelemetryAttribute>,
  chargeBeeCustomers: Array<ChargeBeeModel>,
  chargeBeeSubscriptions: Array<ChargeBeeModel>,
  chargeBeeInvoices: Array<ChargeBeeModel>
) => {
  let matchedChargeBeeCustomers;
  let matchedChargeBeeSubscriptions;
  let matchedChargeBeeInvoices;

  let cbSubscriptionIds = attributes.find(
    (attribute: TelemetryAttribute) => attribute.key === MANAGER_TENANT_ATTRIBUTE_ENUM.CHARGEBEE_SUBSCRIPTIONS
  )?.value;
  if (isNotEmptyList(cbSubscriptionIds)) {
    matchedChargeBeeSubscriptions = chargeBeeSubscriptions?.filter((chargeBeeSubscription) =>
      cbSubscriptionIds.includes(chargeBeeSubscription.data.id)
    );

    matchedChargeBeeInvoices = chargeBeeInvoices?.filter((chargeBeeInvoice) =>
      cbSubscriptionIds?.includes(chargeBeeInvoice.data.subscription_id)
    );

    const customerIds = matchedChargeBeeSubscriptions?.map((sub) => sub.data.customer_id);
    matchedChargeBeeCustomers = chargeBeeCustomers?.filter((chargeBeeCustomer) =>
      customerIds?.includes(chargeBeeCustomer.data.id)
    );
  }

  return {
    matchedChargeBeeCustomers: matchedChargeBeeCustomers,
    matchedChargeBeeSubscriptions: matchedChargeBeeSubscriptions,
    matchedChargeBeeInvoices: matchedChargeBeeInvoices
  };
};

export const resolveTenantGatewaysLastActivity = (gateways: Array<TbAssetTelemetriesExtendedItem>) => {
  let recentLastActivity = 0;
  gateways?.forEach((gateway: TbAssetTelemetriesExtendedItem) => {
    let attributes = gateway.attributes;
    const lastGatewayActivity = attributes.find(
      (attribute: TelemetryAttribute) => attribute.key === MANAGER_DEVICE_ATTRIBUTE_ENUM.LAST_ACTIVITY
    )?.value;
    if (lastGatewayActivity > recentLastActivity) {
      recentLastActivity = lastGatewayActivity;
    }
  });

  return recentLastActivity;
};

export const resolveSubscriptionsDataGrid = (
  items: Array<TbAssetTelemetriesItem>,
  chargeBeeUnbilledCharges: Array<ChargeBeeModel>
) => {
  let rows = [];

  items?.map((item, index) => {
    const tenantCounter = item.tenantCounter;
    let odooTenant = item.odooTenant;
    const tenantSites = item.tenantSites;
    let tenantGateways = item.tenantGateways;

    const masterTenantData = tenantCounter?.tenantMasterDto;
    const devicesCounters = tenantCounter?.devicesMap;
    const assetsCounters = tenantCounter?.assetsMap;
    const telemetryData = item.telemetryData;
    const attributes = item.attributes;

    const lastGatewayActivity = resolveTenantGatewaysLastActivity(tenantGateways);

    let tenantNote =
      isNotEmpty(odooTenant?.description) && odooTenant?.description !== 'false' ? odooTenant?.description : '';

    let tenantStatus = attributes.find(
      (attribute: TelemetryAttribute) => attribute.key === MANAGER_TENANT_ATTRIBUTE_ENUM.TENANT_STATUS
    )?.value;
    let tenantComment = attributes.find(
      (attribute: TelemetryAttribute) => attribute.key === MANAGER_TENANT_ATTRIBUTE_ENUM.COMMENT
    )?.value;

    let totalTemperatureSensorTB =
      telemetryData[MANAGER_TENANT_TELEMETRY_ENUM.TOTAL_TEMPERATURE_SENSOR_COUNT]?.[0].value;
    let totalMoistureSensorsTB = telemetryData[MANAGER_TENANT_TELEMETRY_ENUM.TOTAL_MOISTURE_SENSOR_COUNT]?.[0].value;

    let shutOutDate = masterTenantData?.endPaymentDate;

    let onPremiseConfig = attributes.find(
      (attribute: TelemetryAttribute) => attribute.key === MANAGER_TENANT_ATTRIBUTE_ENUM.ON_PREMISE_CONFIG
    )?.value;
    let isOnPremise = attributes.find(
      (attribute: TelemetryAttribute) => attribute.key === MANAGER_TENANT_ATTRIBUTE_ENUM.IS_ON_PREMISE
    )?.value;

    const matchedChargeBeeCustomers = item.matchedChargeBeeCustomers;
    const matchedChargeBeeSubscriptions = item.matchedChargeBeeSubscriptions;
    const matchedChargeBeeInvoices = item.matchedChargeBeeInvoices;

    let totalTempSensorsCB = 0;
    let totalMoistureSensorsCB = 0;
    let customerUnbilledCharges = {};
    matchedChargeBeeSubscriptions?.map((subscription) => {
      let subscriptionItems = subscription.data.subscription_items;
      if (isDefined(subscriptionItems)) {
        let tempSensorsNumber =
          subscriptionItems.find(
            (item) => item.item_price_id === SUBSCRIPTION_TYPES_ENUM.TEMPERATURE_SENSORS_EUR_YEARLY
          )?.quantity ?? 0;
        totalTempSensorsCB = totalTempSensorsCB + tempSensorsNumber;

        let moistureSensorsNumber =
          subscriptionItems.find((item) => item.item_price_id === SUBSCRIPTION_TYPES_ENUM.MOISTURE_SENSORS_EUR_YEARLY)
            ?.quantity ?? 0;
        totalMoistureSensorsCB = totalMoistureSensorsCB + moistureSensorsNumber;
      }

      let subUnbilledCharges = chargeBeeUnbilledCharges?.filter(
        (charge) => charge.data.subscription_id === subscription.data.id
      );

      let totalAmount = 0;
      subUnbilledCharges?.forEach((charge) => (totalAmount = totalAmount + charge.data.amount));
      customerUnbilledCharges = {
        ...customerUnbilledCharges,
        [subscription.data.id]: totalAmount
      };
    });

    const dataGridItem = {
      id: index + 1,
      tenantData: masterTenantData,
      createdTime: masterTenantData?.createdTime,
      lastActivity: telemetryData[MANAGER_TENANT_TELEMETRY_ENUM.LAST_ACTIVITY_TIME]?.[0]?.value,
      odooTenant: odooTenant,
      chargeBeeCustomer: matchedChargeBeeCustomers,
      subscription: matchedChargeBeeSubscriptions,
      invoiceStatus: matchedChargeBeeInvoices,
      totalTempSensorsCB: totalTempSensorsCB,
      totalMoistureSensorsCB: totalMoistureSensorsCB,
      tenantInfo: item,
      tenantStatus: tenantStatus,
      unbilledCharges: customerUnbilledCharges,
      tempSensorsTB: totalTemperatureSensorTB,
      moistSensorsTB: totalMoistureSensorsTB,
      tempSensorsOdoo: odooTenant?.totalTemperatureSensors,
      moistSensorsOdoo: odooTenant?.totalMoistureSensors,
      customer: odooTenant?.customer,
      odooDescription: odooTenant?.description,
      shutOutDate: shutOutDate,
      comment: tenantComment,
      note: tenantNote,
      systemType: isOnPremise ? SYSTEM_TYPE.ON_PREMISE : SYSTEM_TYPE.CLOUD,
      onPremiseConfig: onPremiseConfig,
      tenantSites: tenantSites ?? [],
      tenantGateways: tenantGateways,
      lastGatewayActivity: lastGatewayActivity,
      totalActiveAlarms: telemetryData[MANAGER_TENANT_TELEMETRY_ENUM.TOTAL_ACTIVE_ALARMS_COUNT]?.[0]?.value,
      totalStaleAlarms: telemetryData[MANAGER_TENANT_TELEMETRY_ENUM.TOTAL_STALE_ALARMS_COUNT]?.[0]?.value,
      activeAlarmsByType: telemetryData[MANAGER_TENANT_TELEMETRY_ENUM.TOTAL_ACTIVE_ALARMS_TYPES]?.[0]?.value,
      staleAlarmsByType: telemetryData[MANAGER_TENANT_TELEMETRY_ENUM.TOTAL_STALE_ALARMS_TYPES]?.[0]?.value,
      temperatureSensorLines: devicesCounters?.[TB_DEVICE_TYPE_ENUM.TEMPERATURE_SENSOR_LINES],
      moistureSensorLines: devicesCounters?.[TB_DEVICE_TYPE_ENUM.MOISTURE_SENSOR_LINES],
      headspaceSensorDevice: devicesCounters?.[TB_DEVICE_TYPE_ENUM.HEAD_SPACE_SENSOR],
      levelIndicatorDevice: devicesCounters?.[TB_DEVICE_TYPE_ENUM.LEVEL_INDICATOR],
      weatherStation: devicesCounters?.[TB_DEVICE_TYPE_ENUM.WEATHER_STATION],
      sms: devicesCounters?.[TB_DEVICE_TYPE_ENUM.SMS],
      modbus: devicesCounters?.[TB_DEVICE_TYPE_ENUM.MODBUS],
      gateway: devicesCounters?.[TB_DEVICE_TYPE_ENUM.GATEWAY],
      aerations: assetsCounters?.[TB_DEVICE_ASSET_TYPE_ENUM.AERATION],
      silos: assetsCounters?.[TB_DEVICE_ASSET_TYPE_ENUM.SILO],
      siloGroups: assetsCounters?.[TB_DEVICE_ASSET_TYPE_ENUM.SILO_GROUP],
      sites: assetsCounters?.[TB_DEVICE_ASSET_TYPE_ENUM.SITE],
      temperatureSensors: telemetryData[MANAGER_TENANT_TELEMETRY_ENUM.TOTAL_TEMPERATURE_SENSOR_COUNT]?.[0]?.value,
      moistureSensors: telemetryData[MANAGER_TENANT_TELEMETRY_ENUM.TOTAL_MOISTURE_SENSOR_COUNT]?.[0]?.value,
      headSpaceSensors: telemetryData[MANAGER_TENANT_TELEMETRY_ENUM.TOTAL_HEAD_SPACE_SENSOR_COUNT]?.[0]?.value,
      co2Sensors: telemetryData[MANAGER_TENANT_TELEMETRY_ENUM.TOTAL_CO2_SENSOR_COUNT]?.[0]?.value,
      levelIndicatorSensors: telemetryData[MANAGER_TENANT_TELEMETRY_ENUM.TOTAL_LEVEL_INDICATOR_SENSOR_COUNT]?.[0]?.value
    };

    rows.push(dataGridItem);
  });

  return rows;
};

export const resolveColumnVisibilityModel = (visibleColumns: Array, tenantStatusVisibility: Object) => {
  return {
    tenantSites: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.TENANT_DETAILS][MANAGER_DATAGRID_COLUMNS.SITES_DETAILS],
    tenantGateways:
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.TENANT_DETAILS][MANAGER_DATAGRID_COLUMNS.GATEWAY_DETAILS],
    lastGatewayActivity:
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.TENANT_DETAILS][MANAGER_DATAGRID_COLUMNS.LAST_GATEWAY_ACTIVITY],
    systemType: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.TENANT_DETAILS][MANAGER_DATAGRID_COLUMNS.SYSTEM_TYPE],
    comment: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.TENANT_DETAILS][MANAGER_DATAGRID_COLUMNS.COMMENT],
    customer: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.TENANT_DETAILS][MANAGER_DATAGRID_COLUMNS.ODOO_CUSTOMER],
    odooTenant: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.TENANT_DETAILS][MANAGER_DATAGRID_COLUMNS.ODOO_TENANT],
    odooDescription:
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.TENANT_DETAILS][MANAGER_DATAGRID_COLUMNS.ODOO_DESCRIPTION],
    development:
      tenantStatusVisibility[TENANT_STATUS_ENUM.DEVELOPMENT] &&
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.TENANT_DETAILS][MANAGER_DATAGRID_COLUMNS.DEVELOPMENT],
    canceled:
      tenantStatusVisibility[TENANT_STATUS_ENUM.CANCELED] &&
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.TENANT_DETAILS][MANAGER_DATAGRID_COLUMNS.CANCELED],
    archived:
      tenantStatusVisibility[TENANT_STATUS_ENUM.ARCHIVED] &&
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.TENANT_DETAILS][MANAGER_DATAGRID_COLUMNS.ARCHIVED],
    subCustomer:
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.SUBSCRIPTIONS][MANAGER_DATAGRID_COLUMNS.CHARGEBEE_CUSTOMER],
    subscription:
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.SUBSCRIPTIONS][MANAGER_DATAGRID_COLUMNS.CHARGEBEE_SUBSCRIPTION],
    subStatus:
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.SUBSCRIPTIONS][MANAGER_DATAGRID_COLUMNS.SUBSCRIPTION_STATUS],
    billingPeriod:
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.SUBSCRIPTIONS][MANAGER_DATAGRID_COLUMNS.BILLING_PERIOD],
    unbilledCharges:
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.SUBSCRIPTIONS][MANAGER_DATAGRID_COLUMNS.UNBILLED_CHARGES],
    invoiceStatus:
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.SUBSCRIPTIONS][MANAGER_DATAGRID_COLUMNS.INVOICE_STATUS],
    shutOutDate: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.SUBSCRIPTIONS][MANAGER_DATAGRID_COLUMNS.SHUTOUT_DATE],
    totalTempSensorsCB:
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.SENSORS][MANAGER_DATAGRID_COLUMNS.TEMP_SENSORS_CB],
    tempSensorsTB: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.SENSORS][MANAGER_DATAGRID_COLUMNS.TEMP_SENSORS_TB],
    tempSensorsOdoo: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.SENSORS][MANAGER_DATAGRID_COLUMNS.TEMP_SENSORS_ODOO],
    totalMoistureSensorsCB:
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.SENSORS][MANAGER_DATAGRID_COLUMNS.MOISTURE_SENSORS_CB],
    moistSensorsTB:
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.SENSORS][MANAGER_DATAGRID_COLUMNS.MOISTURE_SENSORS_TB],
    moistSensorsOdoo:
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.SENSORS][MANAGER_DATAGRID_COLUMNS.MOISTURE_SENSORS_ODOO],
    sites: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.ASSETS][TB_DEVICE_ASSET_TYPE_ENUM.SITE],
    siloGroups: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.ASSETS][TB_DEVICE_ASSET_TYPE_ENUM.SILO_GROUP],
    silos: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.ASSETS][TB_DEVICE_ASSET_TYPE_ENUM.SILO],
    temperatureSensorLines:
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.DEVICES][TB_DEVICE_TYPE_ENUM.TEMPERATURE_SENSOR_LINES],
    moistureSensorLines:
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.DEVICES][TB_DEVICE_TYPE_ENUM.MOISTURE_SENSOR_LINES],
    headspaceSensorDevice:
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.DEVICES][TB_DEVICE_TYPE_ENUM.HEAD_SPACE_SENSOR],
    levelIndicatorDevice: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.DEVICES][TB_DEVICE_TYPE_ENUM.LEVEL_INDICATOR],
    weatherStation: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.DEVICES][TB_DEVICE_TYPE_ENUM.WEATHER_STATION],
    sms: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.DEVICES][TB_DEVICE_TYPE_ENUM.SMS],
    modbus: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.DEVICES][TB_DEVICE_TYPE_ENUM.MODBUS],
    gateway: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.DEVICES][TB_DEVICE_TYPE_ENUM.GATEWAY],
    aerations: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.DEVICES][TB_DEVICE_ASSET_TYPE_ENUM.AERATION],
    temperatureSensors:
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.SENSORS][TB_DEVICE_SENSOR_TYPE_ENUM.TEMPERATURE_SENSOR],
    moistureSensors: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.SENSORS][TB_DEVICE_SENSOR_TYPE_ENUM.MOISTURE_SENSOR],
    headSpaceSensors:
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.SENSORS][TB_DEVICE_SENSOR_TYPE_ENUM.HEAD_SPACE_SENSOR],
    co2Sensors: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.SENSORS][TB_DEVICE_SENSOR_TYPE_ENUM.CO2_SENSOR],
    levelIndicatorSensors:
      visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.SENSORS][TB_DEVICE_SENSOR_TYPE_ENUM.LEVEL_INDICATOR],
    totalActiveAlarms: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.ALARMS][TB_ALARM_COLUMNS.ACTIVE_ALARMS],
    activeAlarmsByType: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.ALARMS][TB_ALARM_COLUMNS.ACTIVE_ALARM_TYPES],
    totalStaleAlarms: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.ALARMS][TB_ALARM_COLUMNS.STALE_ALARMS],
    staleAlarmsByType: visibleColumns[MANAGER_DATAGRID_GROUP_COLUMNS.ALARMS][TB_ALARM_COLUMNS.STALE_ALARM_TYPES]
  };
};

export const resolveSubscriptionsStatisticsComponent = (dataGridRows: Array) => {
  let subscriptionsNumber = 0;
  let totalAmountDue = 0;
  let totalAmountPaid = 0;

  dataGridRows.forEach((row) => {
    let tenantSubscriptions = row.subscription?.length ?? 0;
    subscriptionsNumber += tenantSubscriptions;

    const invoices = row.invoiceStatus;
    invoices?.forEach((invoice) => {
      totalAmountDue += invoice.data.amount_due ?? 0;
      totalAmountPaid += invoice.data.amount_paid ?? 0;
    });
  });

  const formattedTotalAmountDue = `${CURRENCY_UNIT.EUR}${totalAmountDue / 100}`;
  const formattedTotalAmountPaid = `${CURRENCY_UNIT.EUR}${totalAmountPaid / 100}`;

  return (
    <Stack direction="row" spacing={1}>
      <StatisticsItem titleKey="app.datagrid.subscription.totalSubscriptions" value={subscriptionsNumber} />
      <StatisticsItem titleKey="app.datagrid.subscription.totalAmountDue" value={formattedTotalAmountDue} />
      <StatisticsItem titleKey="app.datagrid.subscription.totalAmountPaid" value={formattedTotalAmountPaid} />
    </Stack>
  );
};

export const resolveTenantsStatisticsComponent = (dataGridRows: Array) => {
  const temperatureSensorCount = sumBy(dataGridRows, (item) => item.temperatureSensors);
  const moistureSensorCount = sumBy(dataGridRows, (item) => item.moistureSensors);
  const levelIndicatorSensorCount = sumBy(dataGridRows, (item) => item.levelIndicatorSensors);
  const headSpaceSensorCount = sumBy(dataGridRows, (item) => item.headSpaceSensors);
  const co2SensorCount = sumBy(dataGridRows, (item) => item.co2Sensors);

  return (
    <Stack direction="row" spacing={1}>
      {temperatureSensorCount > 0 && (
        <StatisticsItem titleKey="app.datagrid.sensorsCount.temperature" value={temperatureSensorCount} />
      )}
      {moistureSensorCount > 0 && (
        <StatisticsItem titleKey="app.datagrid.sensorsCount.moisture" value={moistureSensorCount} />
      )}
      {headSpaceSensorCount > 0 && (
        <StatisticsItem titleKey="app.datagrid.sensorsCount.headspace" value={headSpaceSensorCount} />
      )}
      {co2SensorCount > 0 && <StatisticsItem titleKey="app.datagrid.sensorsCount.co2" value={co2SensorCount} />}
      {levelIndicatorSensorCount > 0 && (
        <StatisticsItem titleKey="app.datagrid.sensorsCount.levelIndicator" value={levelIndicatorSensorCount} />
      )}
    </Stack>
  );
};

// @flow
import PageTitle from 'components/PageTitle';
import PageContainer from 'pages/common/PageContainer';

const ExternalGeneralOverview = (): React$Node => {
  return (
    <PageContainer>
      <PageTitle titleKey={'app.page.title.general.overview'} />
      External General Overview
    </PageContainer>
  );
};

export default ExternalGeneralOverview;

// @flow

import PageTitle from 'components/PageTitle';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  getTbManagerDevicesWithTelemetry,
  getTbManagerMasterTenantsData
} from 'api/service/internal/ThingsboardApiService';
import { TB_DEVICE_TYPE_ENUM } from 'constants/internal/ThingsboardConstants';
import PageContainer from 'pages/common/PageContainer';
import GatewayDataGrid from 'components/internal/datagrid/manager/GatewayDataGrid';
import { TbTenantMaster } from 'types/Thingsboard.types';

const ManagerGateways = (): React$Node => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [masterTenants, setMasterTenants] = useState([]);

  useEffect(() => {
    getTbManagerDevicesWithTelemetry(dispatch, TB_DEVICE_TYPE_ENUM.GATEWAY).then((response) =>
      setItems(response ?? [])
    );
    getTbManagerMasterTenantsData(dispatch).then((response: TbTenantMaster) => setMasterTenants(response));
  }, []);

  return (
    <PageContainer>
      <PageTitle titleKey={'app.page.title.manager.gateways'} />
      <GatewayDataGrid items={items} masterTenants={masterTenants} />
    </PageContainer>
  );
};

export default ManagerGateways;

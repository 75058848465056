// @flow

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import { InputAdornment, Paper, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { v4 as uuid } from 'uuid';
import { ValidationError } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Grid from '@mui/material/Grid';
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone';
import { useSearchParams } from 'react-router-dom';

import { getFieldErrorMessage, hasFieldError, resolveBackendValidationErrors } from 'util/ValidationUtils';
import { addAlert } from 'store/slice/ApplicationSlice';
import { validateCreateUserProfileForm } from 'validators/User.validator';
import { registerUser, verifyToken } from 'api/service/AuthenticationApiService';
import { LOGIN } from 'routing/RouteConstants';
import { USER_ROLE_ENUM, VALIDATION_TOKEN_ENUM } from 'constants/UserConstants';
import { isNotEmpty } from 'util/ObjectUtils';
import { getRegistrationEnabledSetting } from 'store/selector/ApplicationSelector';

const RegisterUser = (): React$Node => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [requestSuccess, setRequestSuccess] = useState(false);
  const [fieldErrors, setFieldErrors] = React.useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [verifySuccess, setVerifySuccess] = useState(true);
  const [params] = useSearchParams();
  const token = params.get('token');
  const registrationEnabled = useSelector(getRegistrationEnabledSetting);

  useEffect(() => {
    if (isNotEmpty(token)) {
      verifyToken(token, VALIDATION_TOKEN_ENUM.INVITE_USERS, dispatch)
        .then(() => setVerifySuccess(true))
        .catch(() => setVerifySuccess(false));
    } else if (!registrationEnabled) {
      navigate(LOGIN);
    }
  }, [token, dispatch, navigate, registrationEnabled]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const register = (event: Event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let userProfileData = {
      username: data.get('username'),
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      email: data.get('email'),
      password: data.get('password'),
      confirmPassword: data.get('confirmPassword'),
      authorities: [USER_ROLE_ENUM.EXTERNAL_USER],
      invitationToken: token
    };

    validateCreateUserProfileForm(userProfileData)
      .then(() => {
        setFieldErrors([]);
        registerUser(userProfileData, dispatch)
          .then(() => {
            const alert = {
              id: uuid(),
              severity: 'success',
              message: intl.formatMessage({ id: 'app.user.profile.save.success' })
            };
            dispatch(addAlert(alert));
          })
          .then(() => setRequestSuccess(true))
          .catch((error: any) => {
            setFieldErrors(resolveBackendValidationErrors(error));
          });
      })
      .catch((validationResult: ValidationError) => {
        setFieldErrors(validationResult?.inner ?? []);
      });
  };

  return (
    <>
      {isNotEmpty(token) && !verifySuccess ? (
        <Paper elevation={1} sx={{ m: 2, p: 5 }}>
          <Box
            sx={{
              color: 'error.light',
              fontSize: 'h3.fontSize',
              fontWeight: 'light',
              textTransform: 'uppercase',
              textAlign: 'center'
            }}
          >
            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
              <ErrorOutlineTwoToneIcon sx={{ fontSize: 50 }} />
              {intl.formatMessage({ id: 'app.common.error' })}
            </Stack>
          </Box>
          <Box sx={{ fontWeight: 'light', fontSize: 'h8.fontSize', textAlign: 'center' }}>
            {intl.formatMessage({ id: 'app.signUp.verify.error' })}
          </Box>
        </Paper>
      ) : (
        <>
          <Typography component="h1" variant="h5">
            {intl.formatMessage({ id: 'app.signUp.label' })}
          </Typography>
          {requestSuccess ? (
            <Paper elevation={1} sx={{ m: 2, p: 5 }}>
              <Box
                sx={{
                  color: 'success.light',
                  fontSize: 'h3.fontSize',
                  fontWeight: 'light',
                  textTransform: 'uppercase',
                  textAlign: 'center'
                }}
              >
                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                  <DoneSharpIcon sx={{ fontSize: 60 }} />
                  {intl.formatMessage({ id: 'app.common.success' })}
                </Stack>
              </Box>
              <Box sx={{ fontWeight: 'light', fontSize: 'h8.fontSize', textAlign: 'center' }}>
                {intl.formatMessage({ id: 'app.signUp.success.message' })}
              </Box>
            </Paper>
          ) : (
            <Box
              component="form"
              onSubmit={register}
              noValidate
              sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            >
              <TextField
                margin="normal"
                required
                id="firstName"
                label={intl.formatMessage({ id: 'app.user.profile.firstname' })}
                name="firstName"
                autoComplete="firstName"
                autoFocus
                error={hasFieldError('firstName', fieldErrors)}
                helperText={getFieldErrorMessage(intl, 'firstName', fieldErrors)}
                sx={{ pb: 1, width: '100%' }}
              />
              <TextField
                margin="normal"
                required
                id="lastName"
                label={intl.formatMessage({ id: 'app.user.profile.lastname' })}
                name="lastName"
                autoComplete="lastName"
                error={hasFieldError('lastName', fieldErrors)}
                helperText={getFieldErrorMessage(intl, 'lastName', fieldErrors)}
                sx={{ pb: 1, width: '100%' }}
              />
              <TextField
                margin="normal"
                required
                id="email"
                label={intl.formatMessage({ id: 'app.common.email' })}
                name="email"
                autoComplete="email"
                error={hasFieldError('email', fieldErrors)}
                helperText={getFieldErrorMessage(intl, 'email', fieldErrors)}
                sx={{ pb: 1, width: '100%' }}
              />
              <TextField
                margin="normal"
                id="username"
                label={intl.formatMessage({ id: 'app.common.username' })}
                name="username"
                autoComplete="username"
                error={hasFieldError('username', fieldErrors)}
                helperText={getFieldErrorMessage(intl, 'username', fieldErrors)}
                sx={{ pb: 1, width: '100%' }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={intl.formatMessage({ id: 'app.common.password' })}
                type={showPassword ? 'text' : 'password'}
                id="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={hasFieldError('password', fieldErrors)}
                helperText={getFieldErrorMessage(intl, 'password', fieldErrors)}
                sx={{ pb: 1, width: '100%' }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label={intl.formatMessage({ id: 'app.common.confirmPassword' })}
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={hasFieldError('confirmPassword', fieldErrors)}
                helperText={getFieldErrorMessage(intl, 'confirmPassword', fieldErrors)}
                sx={{ pb: 1, width: '100%' }}
              />
              <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Button
                  size={'large'}
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    width: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }
                  }}
                >
                  {intl.formatMessage({ id: 'app.signUp.register.label' })}
                </Button>
              </Box>
              <Grid container>
                <Grid item xs>
                  <Button variant="text" onClick={() => navigate(LOGIN)}>
                    {intl.formatMessage({ id: 'app.resetPassword.back' })}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default RegisterUser;

// @flow
import * as React from 'react';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import { getFieldErrorMessage, hasFieldError, resolveBackendValidationErrors } from 'util/ValidationUtils';
import { Chip, InputAdornment, Switch } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { getGoogleAuthTOTPUrl, saveUserDetails } from 'api/service/UserApiService';
import { v4 as uuid } from 'uuid';
import { addAlert } from 'store/slice/ApplicationSlice';
import { ValidationError } from 'yup';
import { validateCreateUserProfileForm, validateUpdateUserProfileForm } from 'validators/User.validator';
import { addUser, saveUserDetailsAsAdmin } from 'api/service/internal/AdminApiService';
import { isDefined, isFunctionDefined, isNotEmpty, isNotEmptyList } from 'util/ObjectUtils';
import type { UserProfile } from 'types/State.types';
import { USER_ROLE_ENUM } from 'constants/UserConstants';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FormControlLabel from '@mui/material/FormControlLabel';
import QRCode from 'react-qr-code';
import { grey } from '@mui/material/colors';

const Transition = React.forwardRef(function Transition(props: any, ref: any): React$Node {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: boolean,
  isUpdateFromAdmin?: boolean,
  isCreate?: boolean,
  successCallback?: Function,
  handleClose: Function,
  userProfile?: UserProfile
};

export default function UserProfileDialog({
  open,
  handleClose,
  userProfile,
  isCreate = false,
  isUpdateFromAdmin = false,
  successCallback
}: Props): React$Node {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [fieldErrors, setFieldErrors] = useState([]);
  const [authorities, setAuthorities] = useState(userProfile?.authorities);
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(userProfile?.twoFactorEnabled ?? false);
  const [totpURL, setTotpURL] = useState(null);

  useEffect(() => {
    getGoogleAuthTOTPUrl(dispatch).then((response: any) => {
      setTotpURL(response?.message ?? null);
    });
  }, []);

  useEffect(() => {
    setAuthorities(userProfile?.authorities);
  }, [userProfile]);

  const allowManageAuthorities = isCreate || isUpdateFromAdmin;

  const saveAndClose = (event: Event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let userProfileData = {
      id: userProfile?.id,
      username: isCreate ? data.get('username') : userProfile?.username,
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      email: data.get('email'),
      twoFactorEnabled: twoFactorEnabled,
      password: data.get('password'),
      confirmPassword: data.get('confirmPassword'),
      authorities: authorities
    };

    let saveApiCall;
    let validator;
    if (isCreate) {
      saveApiCall = addUser;
      validator = validateCreateUserProfileForm;
    } else {
      saveApiCall = isUpdateFromAdmin ? saveUserDetailsAsAdmin : saveUserDetails;
      validator = validateUpdateUserProfileForm;
    }

    validator(userProfileData)
      .then(() => {
        setFieldErrors([]);
        saveApiCall(userProfileData, dispatch)
          .then(() => isFunctionDefined(successCallback) && successCallback())
          .then(() => {
            const alert = {
              id: uuid(),
              severity: 'success',
              message: intl.formatMessage({ id: 'app.user.profile.save.success' })
            };
            dispatch(addAlert(alert));
          })
          .then(handleClose)
          .catch((error: any) => {
            setFieldErrors(resolveBackendValidationErrors(error));
          });
      })
      .catch((validationResult: ValidationError) => {
        setFieldErrors(validationResult?.inner ?? []);
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleAuthorityDeletion = (value: string) => {
    if (isNotEmptyList(authorities)) {
      let filteredAuthorities = authorities.filter((authority) => authority !== value);
      setAuthorities(filteredAuthorities);
    }
  };

  const handleAuthorityAddition = (value: string) => {
    let newAuthorities = [];
    if (isDefined(authorities)) {
      newAuthorities = authorities.filter((authority) => authority !== value);
    }

    newAuthorities.push(value);
    setAuthorities(newAuthorities);
  };

  const handle2FAChange = (event: Event) => {
    const isEnabled = event.target.checked;
    setTwoFactorEnabled(isEnabled);
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {intl.formatMessage({ id: 'app.user.profile.title' })}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="form"
        onSubmit={saveAndClose}
        noValidate
        sx={{ display: 'flex', flexWrap: 'wrap', m: 2, alignItems: 'flex-start' }}
      >
        <TextField
          defaultValue={userProfile?.firstName}
          margin="normal"
          required
          id="firstName"
          label={intl.formatMessage({ id: 'app.user.profile.firstname' })}
          name="firstName"
          autoComplete="firstName"
          autoFocus
          error={hasFieldError('firstName', fieldErrors)}
          helperText={getFieldErrorMessage(intl, 'firstName', fieldErrors)}
          sx={{ p: 1, width: { xs: 1, sm: 1 / 2, md: 1 / 3, lg: 1 / 4 } }}
        />
        <TextField
          defaultValue={userProfile?.lastName}
          margin="normal"
          required
          id="lastName"
          label={intl.formatMessage({ id: 'app.user.profile.lastname' })}
          name="lastName"
          autoComplete="lastName"
          error={hasFieldError('lastName', fieldErrors)}
          helperText={getFieldErrorMessage(intl, 'lastName', fieldErrors)}
          sx={{ p: 1, width: { xs: 1, sm: 1 / 2, md: 1 / 3, lg: 1 / 4 } }}
        />
        <TextField
          defaultValue={userProfile?.email}
          margin="normal"
          required
          id="email"
          label={intl.formatMessage({ id: 'app.common.email' })}
          name="email"
          autoComplete="email"
          error={hasFieldError('email', fieldErrors)}
          helperText={getFieldErrorMessage(intl, 'email', fieldErrors)}
          sx={{ p: 1, width: { xs: 1, sm: 1 / 2, md: 1 / 3, lg: 1 / 4 } }}
        />
        <TextField
          defaultValue={userProfile?.username}
          margin="normal"
          id="username"
          label={intl.formatMessage({ id: 'app.common.username' })}
          name="username"
          autoComplete="username"
          disabled={!isCreate}
          error={hasFieldError('username', fieldErrors)}
          helperText={getFieldErrorMessage(intl, 'username', fieldErrors)}
          sx={{ p: 1, width: { xs: 1, sm: 1 / 2, md: 1 / 3, lg: 1 / 4 } }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label={intl.formatMessage({ id: 'app.common.password' })}
          type={showPassword ? 'text' : 'password'}
          id="password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
                </IconButton>
              </InputAdornment>
            )
          }}
          error={hasFieldError('password', fieldErrors)}
          helperText={getFieldErrorMessage(intl, 'password', fieldErrors)}
          sx={{ p: 1, width: { xs: 1, sm: 1 / 2, md: 1 / 3, lg: 1 / 4 } }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="confirmPassword"
          label={intl.formatMessage({ id: 'app.common.confirmPassword' })}
          type={showConfirmPassword ? 'text' : 'password'}
          id="confirmPassword"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
                </IconButton>
              </InputAdornment>
            )
          }}
          error={hasFieldError('confirmPassword', fieldErrors)}
          helperText={getFieldErrorMessage(intl, 'confirmPassword', fieldErrors)}
          sx={{ p: 1, width: { xs: 1, sm: 1 / 2, md: 1 / 3, lg: 1 / 4 } }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <FormControlLabel
            control={<Switch name="twoFactorEnabled" checked={twoFactorEnabled} onChange={handle2FAChange} />}
            label={intl.formatMessage({ id: 'app.user.profile.enable2fa' })}
            sx={{ mt: 4 }}
          />
          <Box
            sx={{
              display: 'flex',
              width: '200px',
              height: '200px',
              border: `1px solid ${grey[100]}`,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {twoFactorEnabled && isNotEmpty(totpURL) ? (
              <QRCode style={{ height: 'auto', maxWidth: '150px', width: '150px' }} value={totpURL} />
            ) : (
              <Typography sx={{ m: 1, textAlign: 'center' }}>
                {intl.formatMessage({ id: 'app.user.profile.enable2fa.qrCode' })}
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Button
            size={'large'}
            type="submit"
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              width: { xs: 1, sm: 1 / 2, md: 1 / 3, lg: 1 / 2, xl: 1 / 2 }
            }}
          >
            {intl.formatMessage({ id: 'app.common.save' })}
          </Button>
        </Box>
      </Box>
      <Typography sx={{ ml: 4, mb: 4, color: 'primary.main' }} variant="h8" component="div">
        {intl.formatMessage({ id: 'app.user.profile.assignedAuthorities' })}
        {authorities?.map((authority) => (
          <Chip
            sx={{ ml: 2, mr: 2, color: 'primary.main' }}
            key={authority}
            label={authority}
            variant="contained"
            onDelete={allowManageAuthorities ? () => handleAuthorityDeletion(authority) : null}
          />
        ))}
      </Typography>
      {allowManageAuthorities && (
        <Typography sx={{ ml: 4, color: 'primary.main' }} variant="h8" component="div">
          {intl.formatMessage({ id: 'app.user.profile.allAuthorities' })}
          {Object.values(USER_ROLE_ENUM).map((authority) => (
            <Chip
              sx={{ ml: 2, mr: 2, color: 'primary.main' }}
              key={authority}
              label={authority}
              variant="outlined"
              onDelete={allowManageAuthorities ? () => handleAuthorityAddition(authority) : null}
              deleteIcon={<AddCircleOutlineIcon />}
            />
          ))}
        </Typography>
      )}
    </Dialog>
  );
}

// @flow

import Dialog from '@mui/material/Dialog';
import { grey } from '@mui/material/colors';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import type { TbAssetTelemetriesItem, TelemetryAttribute } from 'types/Thingsboard.types';
import { v4 as uuid } from 'uuid';
import { addAlert } from 'store/slice/ApplicationSlice';
import { useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import { MANAGER_TENANT_ATTRIBUTE_ENUM } from 'constants/internal/TenantConstants';
import { updateOdooTenantNote } from 'api/service/internal/OdooApiService';
import type { OdooTenant } from 'types/Odoo.types';
import Typography from '@mui/material/Typography';
import { isDefined, isNotEmpty } from 'util/ObjectUtils';

type Props = {
  open: boolean,
  handleClose: Function,
  tenantInfo: TbAssetTelemetriesItem,
  odooTenants: Array<OdooTenant>,
  fetchOdooTenants: Function
};

const OdooNoteDialog = ({ open, handleClose, tenantInfo, odooTenants, fetchOdooTenants }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [selectedOdooTenant, setSelectedOdooTenant] = useState(null);

  useEffect(() => {
    let attributes = tenantInfo?.attributes;
    const odooTenantId = attributes.find(
      (attribute: TelemetryAttribute) => attribute.key === MANAGER_TENANT_ATTRIBUTE_ENUM.ODOO_TENANT
    )?.value;

    let matchedOdooTenant = odooTenants?.find((odooTenant) => odooTenant.id === odooTenantId);
    setSelectedOdooTenant(matchedOdooTenant);
  }, [tenantInfo, odooTenants]);

  const handleTextChange = (event: Event) => {
    if (isDefined(selectedOdooTenant)) {
      setSelectedOdooTenant((oldData) => ({ ...oldData, description: event.target.value }));
    }
  };

  const handleCloseDialog = () => {
    setSelectedOdooTenant(null);
    handleClose();
  };

  const handleSave = () => {
    updateOdooTenantNote(selectedOdooTenant, dispatch)
      .then(() => {
        let alert = {
          id: uuid(),
          severity: 'success',
          title: intl.formatMessage({ id: 'app.common.success' }),
          message: intl.formatMessage({ id: 'app.datagrid.subscription.note.update.success' })
        };
        dispatch(addAlert(alert));
      })
      .then(fetchOdooTenants)
      .then(handleCloseDialog);
  };

  let titleLabel = intl.formatMessage({ id: 'app.datagrid.subscription.note.title' });

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        sx: {
          width: '500px',
          p: 1
        }
      }}
    >
      <DialogTitle variant="h6" sx={{ fontWeight: 400, borderBottom: `1px solid ${grey[300]}`, p: 1 }}>
        {titleLabel}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', pt: 2 }}
        >
          <TextField
            id="note"
            variant="standard"
            size="small"
            fullWidth
            multiline
            rows={4}
            inputProps={{ maxLength: 255 }}
            label={intl.formatMessage({ id: 'app.datagrid.subscription.note' })}
            type={'text'}
            value={
              isNotEmpty(selectedOdooTenant?.description) && selectedOdooTenant?.description !== 'false'
                ? selectedOdooTenant?.description
                : ''
            }
            onChange={handleTextChange}
            disabled={!isDefined(selectedOdooTenant)}
          />
          {!isDefined(selectedOdooTenant) && (
            <Typography fontSize="x-small" sx={{ mb: 1, textAlign: 'center', pt: 1, color: grey[500] }}>
              {intl.formatMessage({ id: 'app.datagrid.subscription.note.update.disabled' })}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ pr: 1, pb: 0.5, pt: 1, borderTop: `1px solid ${grey[300]}` }}>
        <Button variant="contained" size="small" onClick={handleSave} disabled={!isDefined(selectedOdooTenant)}>
          {intl.formatMessage({ id: 'app.common.save' })}
        </Button>
        <Button
          onClick={handleCloseDialog}
          variant="contained"
          size="small"
          sx={{
            backgroundColor: grey[500],
            color: '#fff',
            ':hover': {
              backgroundColor: grey[700]
            }
          }}
        >
          {intl.formatMessage({ id: 'app.common.close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OdooNoteDialog;

// @flow

import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import { EXTERNAL_GENERAL, EXTERNAL_GENERAL_OVERVIEW, EXTERNAL_MANAGE_GROUPS } from 'routing/RouteConstants';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import React from 'react';
import NavigationBar from 'components/navigation/NavigationBar';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';

const createExternalSideMenuItems = (generalOpen: boolean, toggleGeneralOpen: Function) => {
  return [
    {
      textKey: 'app.navigation.general',
      icon: <TravelExploreOutlinedIcon />,
      path: EXTERNAL_GENERAL,
      onClick: toggleGeneralOpen,
      isSubmenuOpen: generalOpen,
      submenu: [
        {
          textKey: 'app.navigation.general.overview',
          icon: <MapOutlinedIcon />,
          path: EXTERNAL_GENERAL_OVERVIEW
        }
      ]
    },
    {
      textKey: 'app.navigation.groups',
      icon: <CategoryOutlinedIcon />,
      path: EXTERNAL_MANAGE_GROUPS
    }
  ];
};

const ExternalNavigationBar = ({ children }: any) => {
  const [generalOpen, setGeneralOpen] = React.useState(false);

  const toggleGeneralOpen = () => {
    setGeneralOpen(!generalOpen);
  };
  const SideMenuItems = createExternalSideMenuItems(generalOpen, toggleGeneralOpen);

  return <NavigationBar SideMenuItems={SideMenuItems}>{children}</NavigationBar>;
};

export default ExternalNavigationBar;

// @flow
import { DataGrid, GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { IntlShape, useIntl } from 'react-intl';
import { TbCustomerDataItem } from 'types/Farmer.types';
import { Chip, InputAdornment, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomerUsersDialog from 'components/internal/dialog/farmer/CustomerUsersDialog';
import { isDefined, isNotEmpty, isNotEmptyList } from 'util/ObjectUtils';
import Tooltip from '@mui/material/Tooltip';
import { DATA_GRID_DIALOG_TYPE, DATA_GRID_TYPE, WORLD_MAP_ELEMENTS } from 'constants/GlobalConstants';
import DevicesDialog from 'components/internal/dialog/farmer/DevicesDialog';
import { EntityId } from '@reduxjs/toolkit';
import BuildingsDialog from 'components/internal/dialog/farmer/BuildingsDialog';
import type { TbUserWithTelemetries, TelemetryAttribute } from 'types/Thingsboard.types';
import { FARMER_CUSTOMER_ATTRIBUTE_ENUM } from 'constants/internal/CustomerConstants';
import { isArray } from 'lodash';
import { GRAIN_ANALYZERS, TB_FARMER_DEVICE_TYPE_VALUE_ENUM } from 'constants/internal/ThingsboardConstants';
import AlarmsDialog from 'components/internal/dialog/farmer/AlarmsDialog';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TextField from '@mui/material/TextField';
import {
  CUSTOMERS_DATAGRID_COLUMN_VISIBILITY,
  getDataGridColumnVisibility,
  storeDataGridColumnVisibility
} from 'api/service/LocalStorageService';
import DataGridSettingsDrawer from 'components/internal/drawer/DataGridSettingsDrawer';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { CUSTOMERS_DATAGRID_COLUMNS, CUSTOMERS_DATAGRID_GROUP_COLUMNS } from 'constants/internal/DataGridConstants';
import { USER_ATTRIBUTE_ENUM } from 'constants/UserConstants';
import WorldMapDialog from 'components/internal/dialog/WorldMapDialog';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { useSelector } from 'react-redux';
import { isAdministratorOrSupport } from 'store/selector/UserSelector';
import CustomerAttributesDialog from 'components/internal/dialog/farmer/CustomerAttributesDialog';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FARMER_DEVICE_TAB_NAVIGATION_ENUM } from 'constants/internal/DeviceConstants';
import { getChargeBeeUrlSetting, getSelectedLocale } from 'store/selector/ApplicationSelector';
import type { ChargeBeeModel } from 'types/ChargeBee.types';
import { resolveCountryFromCode } from 'util/CommonUtils';

const customNumberComparator = (v1, v2): number => {
  let v1Sum = 0;
  let v2Sum = 0;
  Object.keys(v1).forEach((k) => (v1Sum = v1Sum + (v1[k] ?? 0)));
  Object.keys(v2).forEach((k) => (v2Sum = v2Sum + (v2[k] ?? 0)));
  return v1Sum - v2Sum;
};

const createColumns = (
  intl: IntlShape,
  handleDialogOpen: Function,
  handleOpenCustomerAttributesDialog: Function,
  isAdminOrSupport: boolean,
  chargeBeeUrl: string
): GridColDef[] => {
  return [
    { field: 'id', headerName: '#', width: 60 },
    {
      field: 'name',
      headerName: intl.formatMessage({ id: 'app.datagrid.name' }),
      width: 300
    },
    {
      field: 'email',
      headerName: intl.formatMessage({ id: 'app.datagrid.email' }),
      width: 300
    },
    {
      field: 'users',
      headerName: intl.formatMessage({ id: 'app.datagrid.users' }),
      width: 120,
      valueFormatter: (v) => {
        return v?.value?.length;
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let users = cellValues.row.users;
        let numberOfUsers = users?.length;
        let customerId = cellValues.row.customerId;

        const tooltipLabel =
          numberOfUsers > 0
            ? intl.formatMessage({ id: 'app.datagrid.users.tooltip' })
            : intl.formatMessage({ id: 'app.datagrid.users.noData.tooltip' });
        const color = numberOfUsers > 0 ? 'info' : 'warning';

        const handleOnUserClick = () => {
          handleDialogOpen(customerId, DATA_GRID_DIALOG_TYPE.USERS_DIALOG);
        };

        return (
          <Tooltip title={tooltipLabel}>
            <IconButton onClick={handleOnUserClick} color={color}>
              <Typography variant="body2">{numberOfUsers}</Typography>
            </IconButton>
          </Tooltip>
        );
      }
    },
    {
      field: 'spears',
      headerName: intl.formatMessage({ id: 'app.datagrid.devices.spears' }),
      width: 120,
      valueFormatter: (v) => {
        return v?.value;
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let numberOfSpears = cellValues.row.spears;
        let customerId = cellValues.row.customerId;

        const tooltipLabel =
          numberOfSpears > 0
            ? intl.formatMessage({ id: 'app.datagrid.devices.tooltip' })
            : intl.formatMessage({ id: 'app.datagrid.devices.noData.tooltip' });
        const color = numberOfSpears > 0 ? 'info' : 'warning';

        const handleClickDevice = () => {
          handleDialogOpen(customerId, DATA_GRID_DIALOG_TYPE.DEVICE_DIALOG_SPEARS);
        };

        return (
          <Tooltip title={tooltipLabel}>
            <IconButton onClick={handleClickDevice} color={color}>
              <Typography variant="body2">{numberOfSpears}</Typography>
            </IconButton>
          </Tooltip>
        );
      }
    },
    {
      field: 'grainAnalyzers',
      headerName: intl.formatMessage({ id: 'app.datagrid.devices.grainAnalyzers' }),
      width: 200,
      valueFormatter: (v) => {
        let grainAnalyzersMap = v?.value;

        let displayText = '';
        const grainDevices = Object.keys(grainAnalyzersMap);
        grainDevices?.map((grainDeviceName: string, index: number) => {
          let numberOfDevices = grainAnalyzersMap[grainDeviceName];
          let deviceLabel = grainDeviceName.replaceAll('_', ' ');
          displayText = displayText + deviceLabel + ': ' + numberOfDevices;

          if (index !== grainDevices.length - 1) {
            displayText = displayText + ', ';
          }
        });

        return displayText;
      },
      sortComparator: customNumberComparator,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let numberOfGrainAnalyzers = cellValues.row.grainAnalyzers;
        let customerId = cellValues.row.customerId;

        const handleClickDevice = () => {
          handleDialogOpen(customerId, DATA_GRID_DIALOG_TYPE.DEVICE_DIALOG_GRAIN_ANALYZERS);
        };
        const grainDevices = Object.keys(numberOfGrainAnalyzers);

        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {grainDevices.map((grainDevice: string): React$Node => {
              const numDevice = numberOfGrainAnalyzers[grainDevice];
              let deviceLabel = grainDevice.replaceAll('_', ' ');
              return (
                <Tooltip
                  key={`grain-analyzer-${grainDevice}`}
                  title={intl.formatMessage({ id: 'app.datagrid.devices.tooltip' })}
                >
                  <Chip
                    sx={{ m: 0.5 }}
                    color="info"
                    label={`${deviceLabel} (${numDevice})`}
                    variant="contained"
                    size="small"
                    onClick={handleClickDevice}
                  />
                </Tooltip>
              );
            })}
          </Box>
        );
      }
    },
    {
      field: 'buildings',
      headerName: intl.formatMessage({ id: 'app.datagrid.buildings' }),
      width: 120,
      valueFormatter: (v) => {
        return v?.value;
      },
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let numberOfBuildings = cellValues.row.buildings;
        let customerId = cellValues.row.customerId;

        const tooltipLabel =
          numberOfBuildings > 0
            ? intl.formatMessage({ id: 'app.datagrid.buildings.tooltip' })
            : intl.formatMessage({ id: 'app.datagrid.buildings.noData.tooltip' });
        const color = numberOfBuildings > 0 ? 'info' : 'warning';

        const handleClickDevice = () => {
          handleDialogOpen(customerId, DATA_GRID_DIALOG_TYPE.BUILDINGS_DIALOG);
        };

        return (
          <Tooltip title={tooltipLabel}>
            <IconButton onClick={handleClickDevice} color={color}>
              <Typography variant="body2">{numberOfBuildings}</Typography>
            </IconButton>
          </Tooltip>
        );
      }
    },
    {
      field: 'alarms',
      headerName: intl.formatMessage({ id: 'app.datagrid.alarms' }),
      width: 250,
      valueFormatter: (v) => {
        let alarmsMap = v?.value;

        let displayText = '';
        const alarms = Object.keys(alarmsMap);
        alarms?.map((alarmType: string, index: number) => {
          let numberOfAlarms = alarmsMap[alarmType];
          let alarmLabel = alarmType.replaceAll('_', ' ');
          displayText = displayText + alarmLabel + ': ' + numberOfAlarms;

          if (index !== alarms.length - 1) {
            displayText = displayText + ', ';
          }
        });

        return displayText;
      },
      sortComparator: customNumberComparator,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let numberOfAlarms = cellValues.row.alarms;
        let customerId = cellValues.row.customerId;

        const handleClickAlarm = () => {
          handleDialogOpen(customerId, DATA_GRID_DIALOG_TYPE.ALARMS_DIALOG);
        };

        const alarmTypes = Object.keys(numberOfAlarms);

        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {alarmTypes.map((alarmType: string): React$Node => {
              const numAlarms = numberOfAlarms[alarmType];
              let alarmLabel = alarmType.replaceAll('_', ' ');
              return (
                <Tooltip
                  key={`alarm-type-${alarmType}`}
                  title={intl.formatMessage({ id: 'app.datagrid.alarms.tooltip' })}
                >
                  <Chip
                    sx={{ m: 0.5, fontSize: '12px' }}
                    color="warning"
                    label={`${alarmLabel} (${numAlarms})`}
                    variant="contained"
                    size="small"
                    onClick={handleClickAlarm}
                  />
                </Tooltip>
              );
            })}
          </Box>
        );
      }
    },
    {
      field: 'freeDevices',
      headerName: intl.formatMessage({ id: 'app.datagrid.freeDevices' }),
      width: 100,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const numberOfFreeDevices = cellValues.row.freeDevices;
        let customerId = cellValues.row.customerId;

        const handleClick = () => {
          handleOpenCustomerAttributesDialog(FARMER_CUSTOMER_ATTRIBUTE_ENUM.NUM_FREE_DEVICES, customerId.id);
        };

        const cursor = isAdminOrSupport ? 'pointer' : 'default';
        return (
          <>
            {isDefined(numberOfFreeDevices) ? (
              <Typography
                onClick={isAdminOrSupport ? handleClick : null}
                sx={{
                  '&:hover': {
                    cursor: cursor
                  }
                }}
              >
                {numberOfFreeDevices}
              </Typography>
            ) : (
              isAdminOrSupport && (
                <IconButton onClick={isAdminOrSupport ? handleClick : null} size="small">
                  <EditOutlinedIcon sx={{ width: '20px', height: '20px' }} />
                </IconButton>
              )
            )}
          </>
        );
      }
    },
    {
      field: 'subscriptions',
      headerName: intl.formatMessage({ id: 'app.datagrid.subscriptions' }),
      width: 200,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let subscriptions = cellValues.row.subscriptions;
        const subscriptionsArray = subscriptions?.split(',');

        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {subscriptionsArray?.map((item) => {
              const chargeBeeLink = `${chargeBeeUrl}d/subscriptions/${item}`;

              return (
                <Typography key={item} variant="body2">
                  <Link href={chargeBeeLink} target="_blank">
                    {item}
                  </Link>
                </Typography>
              );
            })}
          </Box>
        );
      }
    },
    {
      field: 'phone',
      headerName: intl.formatMessage({ id: 'app.datagrid.phone' }),
      width: 100
    },
    {
      field: 'country',
      headerName: intl.formatMessage({ id: 'app.datagrid.country' }),
      width: 100
    }
  ];
};

const resolveCustomersDataGrid = (
  items: Array<TbCustomerDataItem>,
  chargeBeeCustomers: Array<ChargeBeeModel>,
  chargeBeeSubscriptions: Array<ChargeBeeModel>,
  selectedLocale: string
) => {
  const rows = [];

  items?.map((item, index) => {
    let customerDetails = item.customerDetails;
    let usersWithTelemetries = item.usersWithTelemetries;

    let numberOfSpears = item.numberOfSpears;
    let numberOfBuildings = item.numberOfBuildings;
    let attributes = item.attributes;

    let numFreeDevices;
    let subscriptions;
    let country;
    if (isNotEmptyList(attributes)) {
      let numFreeDevicesAttribute = attributes.find(
        (attribute: TelemetryAttribute) => attribute.key === FARMER_CUSTOMER_ATTRIBUTE_ENUM.NUM_FREE_DEVICES
      );
      let subscriptionsAttribute = attributes.find(
        (attribute: TelemetryAttribute) => attribute.key === FARMER_CUSTOMER_ATTRIBUTE_ENUM.SUBSCRIPTIONS
      );
      if (isDefined(numFreeDevicesAttribute)) {
        numFreeDevices = numFreeDevicesAttribute.value;
      }

      if (isDefined(subscriptionsAttribute)) {
        subscriptions = subscriptionsAttribute.value;

        const matchedChargeBeeSubscriptions = chargeBeeSubscriptions?.filter((chargeBeeSubscription) =>
          subscriptions.includes(chargeBeeSubscription.data.id)
        );

        const customerIds = matchedChargeBeeSubscriptions?.map((sub) => sub.data.customer_id);
        const matchedChargeBeeCustomer = chargeBeeCustomers?.find((chargeBeeCustomer) =>
          customerIds?.includes(chargeBeeCustomer.data.id)
        );

        if (isDefined(matchedChargeBeeCustomer)) {
          const countryCode = matchedChargeBeeCustomer.data?.billing_address?.country;

          if (isNotEmpty(countryCode)) {
            country = resolveCountryFromCode(countryCode, selectedLocale);
          }
        }
        if (!isArray(subscriptions)) {
          subscriptions = subscriptions.replace('[', '');
          subscriptions = subscriptions.replace(']', '');
          subscriptions = subscriptions.replaceAll('"', '');
          subscriptions = subscriptions.replaceAll(' ', '');
        } else {
          subscriptions = subscriptions.join(',');
        }
      }
    }

    const row = {
      id: index + 1,
      customerId: customerDetails.id,
      name: customerDetails.name,
      email: customerDetails.email,
      phone: customerDetails.phone,
      country: isNotEmpty(customerDetails.country) ? customerDetails.country : country,
      users: usersWithTelemetries,
      spears: numberOfSpears,
      grainAnalyzers: item.numberOfGrainAnalyzers,
      buildings: numberOfBuildings,
      alarms: item.numberOfAlarms,
      freeDevices: numFreeDevices,
      subscriptions: subscriptions
    };

    rows.push(row);
  });

  return rows;
};

const resolveDevicesToFetch = (dialogToOpen: string) => {
  let devicesToFetch = [];
  if (dialogToOpen === DATA_GRID_DIALOG_TYPE.DEVICE_DIALOG_SPEARS) {
    devicesToFetch = [TB_FARMER_DEVICE_TYPE_VALUE_ENUM.SENSOR_SPEAR];
  } else if (dialogToOpen === DATA_GRID_DIALOG_TYPE.DEVICE_DIALOG_GRAIN_ANALYZERS) {
    devicesToFetch = GRAIN_ANALYZERS;
  }

  return devicesToFetch;
};

const resolveDevicesTabSelected = (dialogToOpen: string) => {
  let tabSelected;
  if (dialogToOpen === DATA_GRID_DIALOG_TYPE.DEVICE_DIALOG_SPEARS) {
    tabSelected = FARMER_DEVICE_TAB_NAVIGATION_ENUM.SENSOR_SPEAR;
  } else if (dialogToOpen === DATA_GRID_DIALOG_TYPE.DEVICE_DIALOG_GRAIN_ANALYZERS) {
    tabSelected = FARMER_DEVICE_TAB_NAVIGATION_ENUM.GRAIN_ANALYZERS;
  }

  return tabSelected;
};

type Props = {
  items: Array<TbCustomerDataItem>,
  fetchCustomers: Function,
  chargeBeeCustomers: Array<ChargeBeeModel>,
  chargeBeeSubscriptions: Array<ChargeBeeModel>
};

const CustomersDataGrid = ({
  items,
  fetchCustomers,
  chargeBeeCustomers,
  chargeBeeSubscriptions
}: Props): React$Node => {
  const intl = useIntl();
  const theme = useTheme();
  const isAdminOrSupport = useSelector(isAdministratorOrSupport);
  const chargeBeeUrl = useSelector(getChargeBeeUrlSetting);
  const selectedLocale = useSelector(getSelectedLocale);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [dialogToOpen, setDialogToOpen] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [dataGridItems, setDataGridItems] = useState([]);
  const [locationItems, setLocationItems] = useState([]);
  const [openWorldMapDialog, setOpenWorldMapDialog] = useState(false);
  const [attributeToUpdate, setAttributeToUpdate] = useState(null);

  const [visibleColumns, setVisibleColumns] = useState(
    getDataGridColumnVisibility(CUSTOMERS_DATAGRID_COLUMN_VISIBILITY)
  );
  const [visibleColumnsDrawerOpen, setVisibleColumnsDrawerOpen] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});

  const closeVisibleColumnsDrawer = () => {
    setVisibleColumnsDrawerOpen(false);
  };

  useEffect(() => {
    let filteredItems = items?.filter(
      (item: TbCustomerDataItem) =>
        item.customerDetails?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.customerDetails?.email?.toLowerCase().includes(searchText.toLowerCase())
    );
    setDataGridItems(filteredItems);
  }, [searchText, items]);

  const handleDialogOpen = (customerId: EntityId, dialogType: string) => {
    let selectedCustomer = dataGridItems.find((item) => item.customerDetails?.id?.id === customerId?.id);
    setSelectedCustomer(selectedCustomer);
    setDialogToOpen(dialogType);
  };

  const handleCloseDialog = () => {
    setSelectedCustomer(null);
    setDialogToOpen(null);
  };

  const handleCloseWorldMapDialog = () => {
    setLocationItems([]);
    setOpenWorldMapDialog(false);
  };

  const handleCloseCustomerAttributesDialog = () => {
    setSelectedCustomer(null);
    setAttributeToUpdate(null);
  };

  const handleOpenCustomerAttributesDialog = (attribute: string, customerId: string) => {
    const customer = items?.find((item) => item.customerDetails.id.id === customerId);
    setSelectedCustomer(customer);
    setAttributeToUpdate(attribute);
  };

  const handleSearchChange = (event: Event) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText('');
  };

  useEffect(() => {
    const updatedVisibilityModel = {
      users: visibleColumns[CUSTOMERS_DATAGRID_GROUP_COLUMNS.USERS][CUSTOMERS_DATAGRID_COLUMNS.USERS],
      spears: visibleColumns[CUSTOMERS_DATAGRID_GROUP_COLUMNS.DEVICES][CUSTOMERS_DATAGRID_COLUMNS.SPEARS],
      grainAnalyzers:
        visibleColumns[CUSTOMERS_DATAGRID_GROUP_COLUMNS.DEVICES][CUSTOMERS_DATAGRID_COLUMNS.GRAIN_ANALYZERS],
      buildings: visibleColumns[CUSTOMERS_DATAGRID_GROUP_COLUMNS.ASSETS][CUSTOMERS_DATAGRID_COLUMNS.BUILDINGS],
      alarms: visibleColumns[CUSTOMERS_DATAGRID_GROUP_COLUMNS.ALARMS][CUSTOMERS_DATAGRID_COLUMNS.ALARMS],
      freeDevices: visibleColumns[CUSTOMERS_DATAGRID_GROUP_COLUMNS.DEVICES][CUSTOMERS_DATAGRID_COLUMNS.FREE_DEVICES],
      subscriptions: visibleColumns[CUSTOMERS_DATAGRID_GROUP_COLUMNS.DETAILS][CUSTOMERS_DATAGRID_COLUMNS.SUBSCRIPTIONS],
      phone: visibleColumns[CUSTOMERS_DATAGRID_GROUP_COLUMNS.DETAILS][CUSTOMERS_DATAGRID_COLUMNS.PHONE],
      country: visibleColumns[CUSTOMERS_DATAGRID_GROUP_COLUMNS.DETAILS][CUSTOMERS_DATAGRID_COLUMNS.COUNTRY]
    };

    setColumnVisibilityModel(updatedVisibilityModel);
    storeDataGridColumnVisibility(CUSTOMERS_DATAGRID_COLUMN_VISIBILITY, visibleColumns);
  }, [visibleColumns]);

  const rows = resolveCustomersDataGrid(dataGridItems, chargeBeeCustomers, chargeBeeSubscriptions, selectedLocale);
  let devicesToFetch = resolveDevicesToFetch(dialogToOpen);
  const tabSelected = resolveDevicesTabSelected(dialogToOpen);
  const columns = createColumns(
    intl,
    handleDialogOpen,
    handleOpenCustomerAttributesDialog,
    isAdminOrSupport,
    chargeBeeUrl
  );

  const resolveLocationItems = () => {
    const usersLocationItems = [];
    rows?.forEach((item) => {
      let usersWithTelemetries = item.users;

      usersWithTelemetries?.forEach((userWithTelemetries: TbUserWithTelemetries) => {
        let user = userWithTelemetries?.user;
        let attributes = userWithTelemetries?.attributes;
        let longitudeAttribute = attributes.find(
          (attribute: TelemetryAttribute) => attribute.key === USER_ATTRIBUTE_ENUM.LONGITUDE
        );
        let latitudeAttribute = attributes.find(
          (attribute: TelemetryAttribute) => attribute.key === USER_ATTRIBUTE_ENUM.LATITUDE
        );

        if (isDefined(longitudeAttribute) && isDefined(latitudeAttribute)) {
          let newLocationItem = {
            name: user?.name,
            type: WORLD_MAP_ELEMENTS.USER,
            longitude: longitudeAttribute.value,
            latitude: latitudeAttribute.value
          };
          usersLocationItems.push(newLocationItem);
        }
      });
    });
    setLocationItems(usersLocationItems);
    setOpenWorldMapDialog(true);
  };

  let showAttributeUpdateDialog = isAdminOrSupport && isDefined(attributeToUpdate) && isDefined(selectedCustomer);
  return (
    <Box sx={{ height: '75vh', width: '100%' }}>
      {showAttributeUpdateDialog && (
        <CustomerAttributesDialog
          open
          handleClose={handleCloseCustomerAttributesDialog}
          customer={selectedCustomer}
          attributeToUpdate={attributeToUpdate}
          fetchCustomers={fetchCustomers}
        />
      )}
      {openWorldMapDialog && (
        <WorldMapDialog open handleClose={handleCloseWorldMapDialog} locationItems={locationItems} />
      )}
      {dialogToOpen === DATA_GRID_DIALOG_TYPE.USERS_DIALOG && isDefined(selectedCustomer) && (
        <CustomerUsersDialog open handleClose={handleCloseDialog} customer={selectedCustomer} />
      )}
      {(dialogToOpen === DATA_GRID_DIALOG_TYPE.DEVICE_DIALOG_SPEARS ||
        dialogToOpen === DATA_GRID_DIALOG_TYPE.DEVICE_DIALOG_GRAIN_ANALYZERS) &&
        isDefined(selectedCustomer) && (
          <DevicesDialog
            open
            handleClose={handleCloseDialog}
            customer={selectedCustomer}
            devicesToFetch={devicesToFetch}
            tabSelected={tabSelected}
          />
        )}
      {dialogToOpen === DATA_GRID_DIALOG_TYPE.BUILDINGS_DIALOG && isDefined(selectedCustomer) && (
        <BuildingsDialog open handleClose={handleCloseDialog} customer={selectedCustomer} />
      )}
      {dialogToOpen === DATA_GRID_DIALOG_TYPE.ALARMS_DIALOG && isDefined(selectedCustomer) && (
        <AlarmsDialog open handleClose={handleCloseDialog} customer={selectedCustomer} />
      )}
      <DataGridSettingsDrawer
        dataGridType={DATA_GRID_TYPE.CUSTOMERS_DATA_GRID}
        open={visibleColumnsDrawerOpen}
        closeDrawer={closeVisibleColumnsDrawer}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <TextField
          variant="outlined"
          size="small"
          margin="normal"
          fullWidth
          label={intl.formatMessage({ id: 'app.common.search' })}
          type={'text'}
          sx={{
            width: theme.spacing(29)
          }}
          InputProps={{
            style: {
              borderRadius: '8px'
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon color="primary" />
              </InputAdornment>
            ),
            endAdornment: isNotEmpty(searchText) ? (
              <InputAdornment position="end">
                <IconButton aria-label="search bar" edge="end" onClick={handleClearSearch}>
                  <CloseOutlinedIcon color="primary" />
                </IconButton>
              </InputAdornment>
            ) : null
          }}
          value={searchText}
          onChange={handleSearchChange}
        />
        <Box>
          <Tooltip title={intl.formatMessage({ id: 'app.datagrid.location.customers.tooltip' })}>
            <IconButton onClick={resolveLocationItems}>
              <LocationOnOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={intl.formatMessage({ id: 'app.datagrid.drawer.visible.columns' })}>
            <IconButton onClick={() => setVisibleColumnsDrawerOpen(true)}>
              <SettingsOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <DataGrid
        getRowHeight={() => 'auto'}
        rows={rows}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100
            }
          }
        }}
        pageSizeOptions={[100]}
        slots={{
          toolbar: GridToolbar
        }}
        density={'compact'}
        disableColumnSelector
        disableColumnFilter
      />
    </Box>
  );
};

export default CustomersDataGrid;

// @flow

import { DialogActions, DialogContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';
import type { TbTenantMaster } from 'types/Thingsboard.types';
import { useEffect, useRef, useState } from 'react';
import { getTelemetriesForEntity } from 'api/service/internal/ThingsboardManagerTelemetryService';
import { useDispatch } from 'react-redux';
import { MANAGER_TENANT_TELEMETRY_ENUM } from 'constants/internal/TenantConstants';
import { DATA_GRID_DIALOG_TYPE } from 'constants/GlobalConstants';
import { createAlarmChartConfiguration } from 'components/internal/charts/ChartsFactory';
import ReactApexChart from 'react-apexcharts';
import { DateTime } from 'luxon';

type Props = {
  dialogType: string,
  open: boolean,
  handleClose: Function,
  tenant: TbTenantMaster
};

const AlarmsChartDialog = ({ dialogType, open, handleClose, tenant }: Props): React$Node => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const chartRef = useRef();

  const [telemetries, setTelemetries] = useState([]);
  const [chartLabel, setChartLabel] = useState(null);

  const id = tenant?.infoAssetId?.id;
  const entityType = tenant?.infoAssetId?.entityType;
  let keys = [];
  if (dialogType === DATA_GRID_DIALOG_TYPE.ACTIVE_ALARM_CHART_DIALOG) {
    keys = [MANAGER_TENANT_TELEMETRY_ENUM.TOTAL_ACTIVE_ALARMS_COUNT];
  } else if (dialogType === DATA_GRID_DIALOG_TYPE.STALE_ALARM_CHART_DIALOG) {
    keys = [MANAGER_TENANT_TELEMETRY_ENUM.TOTAL_STALE_ALARMS_COUNT];
  }

  useEffect(() => {
    const endTs = DateTime.now().toMillis();
    const startTs = DateTime.now().minus({ days: 30 }).toMillis();

    getTelemetriesForEntity(dispatch, entityType, id, startTs, endTs, keys).then((response) => {
      if (dialogType === DATA_GRID_DIALOG_TYPE.ACTIVE_ALARM_CHART_DIALOG) {
        setTelemetries(response?.[MANAGER_TENANT_TELEMETRY_ENUM.TOTAL_ACTIVE_ALARMS_COUNT]);
        setChartLabel(intl.formatMessage({ id: 'app.datagrid.alarms.active' }));
      } else if (dialogType === DATA_GRID_DIALOG_TYPE.STALE_ALARM_CHART_DIALOG) {
        setTelemetries(response?.[MANAGER_TENANT_TELEMETRY_ENUM.TOTAL_STALE_ALARMS_COUNT]);
        setChartLabel(intl.formatMessage({ id: 'app.datagrid.alarms.stale' }));
      }
    });
  }, []);

  const chartData = createAlarmChartConfiguration(telemetries, chartLabel);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          minWidth: '70vw'
        }
      }}
    >
      <DialogContent>
        <ReactApexChart
          ref={chartRef}
          id="alarms-chart"
          type="line"
          options={chartData?.options}
          series={chartData?.series}
          width="100%"
          height={350}
        />
      </DialogContent>
      <DialogActions sx={{ pr: 1, pb: 0.5, pt: 1, borderTop: `1px solid ${grey[300]}` }}>
        <Button
          onClick={handleClose}
          variant="contained"
          size="small"
          sx={{
            backgroundColor: grey[500],
            color: '#fff',
            ':hover': {
              backgroundColor: grey[700]
            }
          }}
        >
          {intl.formatMessage({ id: 'app.common.close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlarmsChartDialog;

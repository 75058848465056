// @flow

import Dialog from '@mui/material/Dialog';
import { grey } from '@mui/material/colors';
import { Autocomplete, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import type { TbAssetTelemetriesItem, TelemetryAttribute } from 'types/Thingsboard.types';
import { v4 as uuid } from 'uuid';
import { addAlert } from 'store/slice/ApplicationSlice';
import { useDispatch } from 'react-redux';
import { updateTelemetryAttribute } from 'api/service/internal/ThingsboardManagerTelemetryService';
import TextField from '@mui/material/TextField';
import { isDefined } from 'util/ObjectUtils';
import { MANAGER_TENANT_ATTRIBUTE_ENUM } from 'constants/internal/TenantConstants';
import type { ChargeBeeModel } from 'types/ChargeBee.types';
import Typography from '@mui/material/Typography';

type Props = {
  open: boolean,
  handleClose: Function,
  cbSubscriptions: Array<ChargeBeeModel>,
  cbCustomers: Array<ChargeBeeModel>,
  tenantInfo: TbAssetTelemetriesItem,
  fetchTenantsWithTelemetries: Function
};

const ChargebeeSubscriptionLinkDialog = ({
  open,
  handleClose,
  cbSubscriptions = [],
  cbCustomers = [],
  tenantInfo,
  fetchTenantsWithTelemetries
}: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [selectedChargebeeSubscriptions, setSelectedChargebeeSubscriptions] = useState([]);

  useEffect(() => {
    const attributes = tenantInfo?.attributes;
    let cbSubscriptionIds = attributes.find(
      (attribute: TelemetryAttribute) => attribute.key === MANAGER_TENANT_ATTRIBUTE_ENUM.CHARGEBEE_SUBSCRIPTIONS
    )?.value;
    if (isDefined(cbSubscriptionIds)) {
      const subscriptions = cbSubscriptions?.filter((subscription) => cbSubscriptionIds.includes(subscription.data.id));
      setSelectedChargebeeSubscriptions(subscriptions);
    }
  }, []);

  const handleChange = (values: Array<ChargeBeeModel>) => {
    setSelectedChargebeeSubscriptions(values);
  };

  const handleCloseDialog = () => {
    setSelectedChargebeeSubscriptions(null);
    handleClose();
  };

  const handleSave = () => {
    const selectedSubIds = selectedChargebeeSubscriptions?.map((sub) => sub.data.id);
    const body = JSON.stringify({
      [MANAGER_TENANT_ATTRIBUTE_ENUM.CHARGEBEE_SUBSCRIPTIONS]: selectedSubIds ?? []
    });
    const newUpdateObject = {
      entityId: tenantInfo?.assetDto?.id?.id,
      entityType: tenantInfo?.assetDto?.id?.entityType,
      body: body
    };
    updateTelemetryAttribute(dispatch, newUpdateObject)
      .then(() => {
        let alert = {
          id: uuid(),
          severity: 'success',
          title: intl.formatMessage({ id: 'app.common.success' }),
          message: intl.formatMessage({ id: 'app.datagrid.subscriptions.cbSubscription.dialog.success' })
        };
        dispatch(addAlert(alert));
      })
      .then(fetchTenantsWithTelemetries)
      .then(handleCloseDialog);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        sx: {
          width: '500px',
          p: 1
        }
      }}
    >
      <DialogTitle variant="h6" sx={{ fontWeight: 400, borderBottom: `1px solid ${grey[300]}`, p: 1 }}>
        {intl.formatMessage({ id: 'app.datagrid.subscriptions.cbSubscription.dialog.title' })}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', pt: 2 }}>
          <Autocomplete
            id="chargebeeSubscriptions"
            options={cbSubscriptions}
            multiple
            value={selectedChargebeeSubscriptions ?? []}
            getOptionLabel={(option) => option?.data?.id ?? ''}
            renderOption={(props, option, index) => {
              const key = `subscription-${index}-${option?.data?.id}`;
              let customer = cbCustomers?.find((customer) => customer.data.id === option.data.customer_id);

              let customerLabel = '-';
              if (isDefined(customer?.data?.company)) {
                customerLabel = customer?.data?.company;
              } else if (isDefined(customer?.data?.first_name) && isDefined(customer?.data?.last_name)) {
                customerLabel = `${customer?.data?.first_name} ${customer?.data?.last_name}`;
              }

              return (
                <li {...props} key={key}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body1">{customerLabel}</Typography>
                    <Typography variant="body2">{option?.data?.id}</Typography>
                  </Box>
                </li>
              );
            }}
            onChange={(event, value) => handleChange(value)}
            sx={{ width: 300, mt: 1 }}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({ id: 'app.datagrid.subscriptions.cbSubscription.dialog.label' })}
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ pr: 1, pb: 0.5, pt: 1, borderTop: `1px solid ${grey[300]}` }}>
        <Button variant="contained" size="small" onClick={handleSave}>
          {intl.formatMessage({ id: 'app.common.save' })}
        </Button>
        <Button
          onClick={handleCloseDialog}
          variant="contained"
          size="small"
          sx={{
            backgroundColor: grey[500],
            color: '#fff',
            ':hover': {
              backgroundColor: grey[700]
            }
          }}
        >
          {intl.formatMessage({ id: 'app.common.close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChargebeeSubscriptionLinkDialog;

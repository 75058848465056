// @flow

import PageContainer from 'pages/common/PageContainer';
import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { getAnalyticsBasicAuthorizationCode } from 'api/service/internal/ApplicationApiService';
import { useDispatch } from 'react-redux';
import { isDefined } from 'util/ObjectUtils';
import { topBarHeight } from 'components/navigation/NavigationBar';

const AGROLOG_ANALYTICS_URL = 'https://analytics.agrolog.io';

const Analytics = (): React$Node => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [authorizationCode, setAuthorizationCode] = useState(null);

  useEffect(() => {
    getAnalyticsBasicAuthorizationCode(dispatch).then((response) => setAuthorizationCode(response?.message));
  }, []);

  return (
    <PageContainer>
      {isDefined(authorizationCode) && (
        <iframe
          src={`${AGROLOG_ANALYTICS_URL}?token=${authorizationCode}`}
          style={{
            width: '100%',
            height: `calc(${window.innerHeight}px - ${theme.spacing(topBarHeight + 2)})`,
            border: 0
          }}
        />
      )}
    </PageContainer>
  );
};

export default Analytics;
